<nav class="primary-nav-bar" id="leftNav">
  <div
    class="ts-logo">
    <a [routerLink]="['']">
    </a>
  </div>
  <div class="text-center dropright mouse-over" (mouseover)="displayGlobalAdd=true" (mouseleave)="displayGlobalAdd=false">
    <a class="primary-icon primary-icon-padding" href="javascript:void(0)" (click) = "createCase()">
      <div class="fz-25" style="font-size: 40px;">
        <i class="fa-plus-filled"></i>
      </div>
    </a>
    <!-- <app-global-add
    (click)="displayGlobalAdd=false"
      *ngIf="authGuard?.session?.user && displayGlobalAdd"
      class="global-add-dropdown"></app-global-add> -->
  </div>
  <ul class="primary-nav mt-0" id="ul_primary_menu">
    <!-- <li class="pt15 separator mb-5">
    </li> -->
    <!-- <li [routerLink]="['/dashboard']" [routerLinkActive]="'active'">
      <a
        [routerLink]="['/dashboard']" [routerLinkActive]="'active'"
        [matTooltip]="'left_nav.dashboard' | translate"
        placement="right">
        <span class="fz-25">
            <i class="fa-dashboard"></i>
        </span>
      </a>
    </li> -->
    <app-test-dev-icon class="d-block"></app-test-dev-icon>
    <li [routerLink]="lockData?.type !== 'FREE' ? ['/workspaces'] : null" 
        [routerLinkActive]="'active'" 
        [class.disabled]="lockData?.type === 'FREE'"
        [matTooltip]="(lockData?.type === 'FREE' ? ('🔒 workspaces' | translate) : ('left_nav.workspace_settings' | translate))">
      <a [routerLink]="lockData?.type !== 'FREE' ? ['/workspaces'] : null" 
         [routerLinkActive]="'active'" 
         placement="right"
         [style.pointer-events]="lockData?.type === 'FREE' ? 'none' : 'auto'">
        <span class="fz-25">
            <i class="fa-suitcase"></i>
        </span>
      </a>
    </li>
    <!-- <li [routerLink]="['/agents']" [routerLinkActive]="'active'"
        placement="right">
      <a
        [routerLink]="['/agents']" [routerLinkActive]="'active'"
         [matTooltip]="'left_nav.agents' | translate" placement="right">
        <span>
            <i class="fa fa-desktop"></i>
        </span>
      </a>
    </li> -->
    <!-- <li
      [routerLinkActive]="'active'" [routerLink]="['/addons']">
      <a
        [routerLinkActive]="'active'" [routerLink]="['/addons']" [matTooltip]="'td_nav.add_ons' | translate"
        placement="right">
        <span>
         <i class="fa-addons"></i>
        </span>
      </a>
    </li> -->
    <li [routerLink]="lockData?.type !== 'FREE' ? ['/settings'] : null" 
        [routerLinkActive]="'active'" 
        [class.disabled]="lockData?.type === 'FREE'"
        [matTooltip]="(lockData?.type === 'FREE' ? ('🔒 settings' | translate)  : ('left_nav.settings' | translate))">
      <a [routerLink]="lockData?.type !== 'FREE' ? ['/settings'] : null" 
         [routerLinkActive]="'active'" 
         placement="right"
         [style.pointer-events]="lockData?.type === 'FREE' ? 'none' : 'auto'">
        <span class="fz-25"><i class="fa-settings-wrench"></i></span>
      </a>
    </li>

    <li *ngIf="!isNoAuth">
      <a target="_blank" [matTooltip]="'Feedback' | translate" href="https://docs.google.com/forms/d/e/1FAIpQLSdS17Kh2bpibVYfrorJmL-gPvpJ78LCEyAZZDQG0byNNZRmAQ/viewform">
        <span class="fz-25"> <i class="fa-comment"></i>  </span>
      </a>
      <!-- <p style="font-size: 9px; margin-left: 15px; color: #bab6b6; margin-top: -12px;">Support</p> -->
    </li>
    <!-- <li *ngIf="!isNoAuth">
      <a  (click)="logout()" [matTooltip]="'logout' | translate" placement="right">
        <span class="fz-25"> <i class="fa-sign-out-alt-solid"></i></span><br>
      </a>
      <p style="font-size: 9px; margin-left: 15px; color: #bab6b6; margin-top: -12px;">Logout</p>
    </li> -->
  </ul>

  <div class="profile-action-section">
    <div
      class="primary-nav mt-0" >
      <!-- <app-help-actions
        [authGuard]="authGuard"
        [isNav]="true"
        class="help-action-container"></app-help-actions> -->
      <!-- <li [class.active]="moreAction" class="help-more-icon">
        <a #moreActionTrigger="cdkOverlayOrigin" (click)="moreActionOptions()"
           [style.pointer-events]="moreAction ?'none': 'all'"
           [class.active]="moreAction" [matTooltip]="'hint.message.common.more_action' | translate" cdkOverlayOrigin
           placement="right">
          <span><i class="fa-ellipsis-h"></i></span>
        </a>
      </li> -->

    <ng-template
      #moreActionRef="cdkConnectedOverlay"
      [cdkConnectedOverlayOffsetX]="50"
      [cdkConnectedOverlayOffsetY]="-0"
      [cdkConnectedOverlayOpen]="moreAction"
      cdkConnectedOverlay
      cdkConnectedOverlayHasBackdrop="false">
      <div [@collapse]="moreAction"
           [@expand]="moreAction"
           class="profile-action-section">
        <div (click)="moreAction=false" class="highlight-child-on-hover">
          <!-- <app-help-actions [authGuard]="authGuard"   (onOpenChat)="openChat()" (onOpenHelpDropDown)="onCloseDropdown()"></app-help-actions> -->
        </div>
      </div>
    </ng-template>
    </div>
    <ul class="primary-nav" *ngIf="!isNoAuth">
      <!-- <li>
        <a target="_blank" href="https://docs.google.com/forms/d/1o2Lx_AYvA1H-_tEpaUNLChDCYPlKvsxcdacU5pLdZ6s/prefill">
          <span> <i class="fa-thin fa-bug" title="Bug Report"></i>  </span>
        </a>
        <p style="font-size: 9px; margin-left: 8px; color: white; margin-top: -12px;">Support</p>
      </li> -->
      <li>
        <a  (click)="logout()" [matTooltip]="'logout' | translate" placement="right">
          <span class="fz-25"> <i class="fa-sign-out-alt-solid"></i></span><br>
        </a>
      </li>
    </ul>
  </div>
</nav>
