import {
    Component,
    OnInit,
    HostBinding,
    ChangeDetectorRef,
    AfterViewInit,
    ViewChild,
    ElementRef
} from "@angular/core";
import { NgModule } from '@angular/core';
import { BaseComponent } from "../../shared/components/base.component";
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { AiPromptsService, ApiResponse, AiPrompt } from "./ai-prompts.service";
import { FormsModule } from '@angular/forms';
import { NotificationsService,  NotificationType} from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ProgressAnimationType } from "ngx-toastr/toastr/toastr-config";
import { ToastrModule } from 'ngx-toastr';
import 'trix';

@Component({
    selector: "app-ai-prompts",
    templateUrl: "./ai-prompt.components.html",
    styleUrls: ["./ai-prompt.components.scss"]
})
export class AiPromptsListComponent extends BaseComponent implements OnInit, AfterViewInit {
    selectedTabIndex = 1;
    showModal = false;
    promptName = '';
    promptDescription = '';
    prompts: AiPrompt[] = [];
    editingPromptId: string | null = null;
    showDeleteConfirmation = false;
    promptToDelete: AiPrompt | null = null;
    editor: any;
    @ViewChild('trixEditor') trixEditor: ElementRef;

    constructor(
        private aiPromptsService: AiPromptsService,
        private notificationService: NotificationsService,
        public translate: TranslateService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.loadPrompts();
    }

    ngAfterViewInit() {
        // Initialize any view-related logic here
    }

    loadPrompts() {
        this.aiPromptsService.getPrompts()
            .subscribe({
                next: (response: ApiResponse) => {
                    // Set isActive based on matching default ID
                    this.prompts = (response?.response?.prompts || []).map(prompt => ({
                        ...prompt,
                        flag: prompt.id === response?.response?.default
                    }));
                    
                    console.log('API Response:', response);
                    console.log('Loaded prompts:', this.prompts);
                },
                error: (error) => {
                    console.error('Error fetching prompts:', error);
                    this.prompts = [];
                }
            });
    }

    editPrompt(prompt: AiPrompt) {
        this.editingPromptId = prompt.id;
        this.promptName = prompt.title;
        this.promptDescription = prompt.prompt;
        this.showModal = true;
    }

    public showNotification(type: NotificationType, message: string) {
        const options = {
            timeOut: 7000,
            positionClass: 'toast-bottom-left',
            progressBar: true,
            progressAnimation: 'increasing' as ProgressAnimationType,
            closeButton: true,
            enableHtml: false,
            toastClass: 'ngx-toastr',
            titleClass: 'toast-title',
            messageClass: 'toast-message',
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning'
            }
        };

        switch (type) {
            case NotificationType.Success:
                this.notificationService.success('Success', message, options);
                break;
            case NotificationType.Error:
                this.notificationService.error('Error', message, options);
                break;
            case NotificationType.Info:
                this.notificationService.info('Info', message, options);
                break;
            default:
                this.notificationService.info('Info', message, options);
        }
    }

    deletePrompt(prompt: AiPrompt) {
        this.promptToDelete = prompt;
        this.showDeleteConfirmation = true;
    }

    confirmDelete() {
        if (!this.promptToDelete) return;

        this.aiPromptsService.deletePrompt(this.promptToDelete.id)
            .subscribe({
                next: () => {
                    this.showNotification(NotificationType.Success, "Prompt was successfully deleted");
                    this.loadPrompts();
                    this.closeDeleteConfirmation();
                },
                error: (error) => {
                    this.showNotification(NotificationType.Error, "Failed to delete prompt");
                    console.error('Error deleting prompt:', error);
                    this.closeDeleteConfirmation();
                }
            });
    }

    closeDeleteConfirmation() {
        this.showDeleteConfirmation = false;
        this.promptToDelete = null;
    }

    savePrompt() {
        if (!this.promptName || !this.promptDescription) {
            return;
        }

        const request = this.editingPromptId 
            ? this.aiPromptsService.updatePrompt(this.editingPromptId, this.promptName, this.promptDescription)
            : this.aiPromptsService.createPrompt(this.promptName, this.promptDescription);

        request.subscribe({
            next: () => {
                if(this.editingPromptId){
                    this.showNotification(NotificationType.Success, "Prompt was successfully updated");
                }else{
                    this.showNotification(NotificationType.Success, "Prompt was successfully created");
                }
                this.loadPrompts();
                this.closeModal();
            },
            error: (error) => {
                if(this.editingPromptId){
                    this.showNotification(NotificationType.Error, "Failed to update prompt");
                }else{
                    this.showNotification(NotificationType.Error, "Failed to create prompt");
                }
                console.error('Error saving prompt:', error);
            }
        });
    }

    openCreateDialog() {
        this.editingPromptId = null;
        this.showModal = true;
        this.promptName = '';
        this.promptDescription = '';
    }

    closeModal() {
        this.showModal = false;
        this.editingPromptId = null;
    }

    togglePromptStatus(prompt: AiPrompt) {
        prompt.flag = !prompt.flag;  // This will toggle between true and false

        const newStatus = prompt.flag;
        console.log('newStatus,', newStatus)
        this.aiPromptsService.updatePromptStatus(prompt.id, newStatus)
            .subscribe({
                next: () => {
                    // Update all other prompts to inactive if this one is being activated
                    if (newStatus) {
                        this.prompts.forEach(p => {
                            if (p.id !== prompt.id) {
                            }
                        });
                    }
                    prompt.flag = newStatus;
                    const message = newStatus ? 
                        "Prompt was successfully set as default" : 
                        "Prompt was successfully deactivated";
                    this.showNotification(NotificationType.Success, message);
                    this.loadPrompts();
                },
                error: (error) => {
                    this.showNotification(NotificationType.Error, "Failed to update prompt status");
                    console.error('Error updating prompt status:', error);
                }
            });
    }

    onTrixChange(event: any) {
        this.promptDescription = event.target.innerHTML;
    }

    setDescription(description: string) {
        if (this.trixEditor) {
            this.trixEditor.nativeElement.editor.loadHTML(description);
        }
    }
}

@NgModule({
    declarations: [AiPromptsListComponent],
    imports: [
        CommonModule,
        ScrollingModule,
        FormsModule,
        ToastrModule.forRoot()
    ],
    exports: [AiPromptsListComponent]
})

export class AiPromptsModule { }