import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-doc-viewer-modal',
    template: `
    <div class="doc-viewer-modal">
      <div class="modal-header">
        <h2>Documentation</h2>
        <button mat-icon-button (click)="closeDialog()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="modal-content">
        <iframe [src]="safeUrl" frameborder="0" width="100%" height="100%"></iframe>
      </div>
    </div>
  `,
    styles: [`
    .doc-viewer-modal {
      display: flex;
      flex-direction: column;
      height: 90vh;
    }
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid #ddd;
    }
    .modal-content {
      overflow: hidden;
      box-shadow: none !important;
          display: flex;
    justify-content: center;
    align-items: center;
    width: 104%;
    height: 41%;
    }
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  `]
})
export class DocViewerModalComponent {
    safeUrl: SafeResourceUrl;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { url: string },
        private dialogRef: MatDialogRef<DocViewerModalComponent>,
        private sanitizer: DomSanitizer
    ) {
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}