<div class="page-header d-flex fz-20 align-items-center">
  <div class="align-items-center d-flex rb-medium text-truncate ts-col-50 filter-action">
    <i class="fas fa-robot"></i>
    <span class="fz-18 text-truncate ml-8" [textContent]="'Knowledge Base'"></span>
  </div>

  <div class="ml-auto">
    <button class="btn btn-primary" (click)="openCreateDialog()" style="border-radius: 5px;"> <span class="mr-3">+</span> Add</button>
  </div>
  <div class="page-content page-virtual-scroll" style="padding: 30px;">
    <div *ngIf="prompts.length > 0" class="list-container scrollable-content">
        <!-- <p>{{prompt.title}}</p> -->
        <a class="list-view sm-pm pointer align-items-center" *ngFor="let prompt of prompts">
        <div class="ts-col-30 d-flex pr-10">
          <a class="d-flex ts-col-90">
            <i class="fas fa-file-alt mr-2" style="font-size: 14px;"></i>
            <span class="text-truncate" style="font-size: 14px;margin-left: 6px;" [textContent]="prompt.title"></span>
          </a>
        </div>
        <div class="ts-col-10 d-flex pr-10">
          <span class="text-truncat" *ngIf="prompt.flag" style="font-size: 14px;">Default Prompt</span>
        </div>
        <div class="action-icons ml-auto" style="font-size: 12px;" (click)="$event.stopPropagation()">
          <i (click)="togglePromptStatus(prompt)" 
             class="fas action-icon"
             [ngClass]="{
               'fa-toggle-on': prompt.flag,
               'fa-toggle-off': !prompt.flag
             }">
          </i>
          <i (click)="editPrompt(prompt)" 
             class="fas fa-edit action-icon">
          </i>
          <i (click)="deletePrompt(prompt)" 
             class="fas fa-trash-alt action-icon">
          </i>
        </div>
      </a>
    </div>
    <div *ngIf="prompts.length === 0" class="empty-full-container ng-star-inserted">
      <div class="empty-full-content">
        <div class="empty-run-sm"></div>
        <div class="empty-text">No Knowledge Base found.</div>
        <div class="d-flex justify-content-center mt-3">
          <button
            mat-stroked-button
            color="primary"
            (click)="openCreateDialog()">
            <i class="fas fa-plus"></i>
            Add Knowledge Base
          </button>
        </div>
      </div>
    </div>
</div>

<div class="modal fade" id="createPromptModal" tabindex="-1" role="dialog" [ngClass]="{'show': showModal}" *ngIf="showModal">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header border-bottom">
        <h5 class="modal-title rb-medium">{{editingPromptId ? 'Edit' : 'Create'}} Knowledge Base</h5>
        <button type="button" class="close" (click)="closeModal()">
        </button>
      </div>
      <hr>
      <div class="modal-body p-4" style="padding: 24px !important;">
        <form class="p-3">
          <div class="floating-form-group mb-4" style="margin-bottom: 20px !important;">
            <input 
              type="text" 
              class="floating-input form-control" 
              id="promptName" 
              [(ngModel)]="promptName" 
              name="promptName"
              placeholder=" ">
            <label class="floating-label" for="promptName">Name <span class="text-danger">*</span></label>
          </div>
          <div class="floating-form-group">
            <textarea 
              class="floating-input form-control" 
              id="promptDescription" 
              rows="6" 
              [(ngModel)]="promptDescription" 
              name="promptDescription"
              placeholder=" "></textarea>
            <label class="floating-label" for="promptDescription">Description <span class="text-danger">*</span></label>
          </div>
        </form>
      </div>
      <div class="modal-footer border-top">
        <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Cancel</button>
        <button type="button" 
                class="btn btn-primary" 
                [disabled]="!promptName?.trim() || !promptDescription?.trim()"
                (click)="savePrompt()">
          {{editingPromptId ? 'Update' : 'Save'}}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show" *ngIf="showModal"></div>

<div *ngIf="showDeleteConfirmation" class="modal-overlay">
    <div class="confirmation-modal">
        <h2>Are you sure you want to delete this prompt?</h2>
        <p>You cannot undo this action!</p>
        <div class="button-container">
            <button class="cancel-button" (click)="closeDeleteConfirmation()">Cancel</button>
            <button class="delete-button" (click)="confirmDelete()">Yes, Delete</button>
        </div>
    </div>
</div>
