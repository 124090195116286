<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="container" *ngIf="!loader">
  <div class="card first-card" style="flex: none; width: 35% !important">
    <div class="toggle-button">
      <div class="action-border">
        <div class="actions">
          {{ Prop.status == "START" ? "STARTING" : Prop.status }}
        </div>
      </div>
      <div class="success-count">
        <img src="/assets/images/checkmark.svg" alt="" />
        <span>{{ successSteps }}</span>
      </div>
      <div class="failed-count">
        <img src="/assets/images/remove_4.png" alt="" />
        <span>{{ failSteps }}</span>
      </div>
      <div class="duration">
        <span>{{ time }}</span>
      </div>
      <div *ngIf="Prop.status == 'RUNNING'" [matTooltip]="'Stop Execution...'" class="stop-execution">
        <mat-icon (click)="stopExecutionevent()">pause</mat-icon>
      </div>
    </div>
    <div class="accordian-steps">
      <mat-accordion multi>
        <mat-expansion-panel hideToggle [expanded]="true">
          <mat-expansion-panel-header class="expansion-panel-custom">
            <mat-panel-title>
              {{ TestCase.name }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description> </mat-panel-description>
          <div [ngClass]="{
              'success-step': steps.executionData?.stepStatus == 'SUCCESS',
              'failed-step': steps.executionData?.stepStatus == 'FAILURE',
              'failed-step-section-main':
                steps.executionData?.stepStatus == 'FAILURE'
            }" *ngFor="let steps of TestSteps; let i = index">
            <mat-expansion-panel hideToggle [expanded]="currentActiveStepIndex === i"
              (closed)="expandedSteps[i] = false" (opened)="getStepDetailData(steps, i)">
              <mat-expansion-panel-header class="expansion-panel-custom">
                <mat-panel-title>
                  <div style="position: absolute; top: 2px; left: 53px"
                    *ngIf="steps.executionData?.stepStatus == 'RUNNING'">
                    <mat-progress-spinner class="search-spinner" mode="indeterminate"
                      diameter="20"></mat-progress-spinner>
                  </div>
                  <div class="steps-more-info">
                    <div class="sucessc-icon-with-text">
                      <mat-icon class="success-step-indicator"
                        *ngIf="steps.executionData?.stepStatus == 'SUCCESS'">check_circle</mat-icon>
                      <mat-icon class="failed" *ngIf="steps.executionData?.stepStatus == 'FAILURE'">cancel</mat-icon>
                      <mat-icon class="pending" *ngIf="
                          steps.executionData?.stepStatus != 'SUCCESS' &&
                          steps.executionData?.stepStatus != 'FAILURE' &&
                          steps.executionData?.stepStatus != 'CONVERSATION'
                        ">timer</mat-icon>
                      <mat-icon class="conversation" *ngIf="
                          steps.executionData?.stepStatus === 'CONVERSATION'
                        ">error</mat-icon>
                      <span [innerHtml]="steps.action" class="fz-14" [ngClass]="{
                          'truncate-text':
                            steps.executionData?.stepStatus === 'CONVERSATION',
                          'truncate-text2':
                            steps.executionData?.stepStatus !== 'CONVERSATION'
                        }" [matTooltip]="steps.action | stripHtml" matTooltipClass="action-tooltip"
                        [matTooltipPosition]="'above'" [matTooltipShowDelay]="500"></span>
                    </div>
                  </div>
                </mat-panel-title>
                <mat-panel-description>
                  <div class="ml-auto" *ngIf="steps.executionData?.stepStatus === 'CONVERSATION'">
                    <div class="action-required-indicator" (click)="openLinkedTestPlansDialog(steps)">
                      <mat-icon>priority_high</mat-icon>
                      <!-- <span>Action Required</span> -->
                    </div>
                  </div>
                  <mat-icon *ngIf="steps.teststepResult?.hasImageDifference">visibility</mat-icon>
                  <mat-icon style="color: white">keyboard_arrow_down</mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <!-- Improved Logs Display Section -->
              <div class="logs-container" [ngClass]="{
                'success-logs': steps.executionData?.stepStatus == 'SUCCESS',
                'failed-logs': steps.executionData?.stepStatus == 'FAILURE',
                'pending-logs': steps.executionData?.stepStatus != 'SUCCESS' && steps.executionData?.stepStatus != 'FAILURE'
              }">
                <!-- Log Header with Status and Timestamp -->
                <div class="logs-header">
                  <div class="logs-status">
                    <mat-icon *ngIf="steps.executionData?.stepStatus == 'SUCCESS'"
                      class="status-icon status-success">check_circle</mat-icon>
                    <mat-icon *ngIf="steps.executionData?.stepStatus == 'FAILURE'"
                      class="status-icon status-failure">error</mat-icon>
                    <mat-icon
                      *ngIf="steps.executionData?.stepStatus != 'SUCCESS' && steps.executionData?.stepStatus != 'FAILURE'"
                      class="status-icon pending">hourglass_empty</mat-icon>
                    <span class="status-text">{{ steps.executionData?.stepStatus || 'PENDING' }}</span>
                  </div>
                  <div class="logs-actions">
                    <!-- <button mat-icon-button matTooltip="Copy logs" class="action-button" (click)="copyLogs(steps.id)">
                      <mat-icon>content_copy</mat-icon>
                    </button> -->
                    <!-- <button mat-icon-button matTooltip="Expand logs" class="action-button"
                      (click)="expandLogs(steps.id, steps.executionData?.stepStatus)">
                      <mat-icon>fullscreen</mat-icon>
                    </button> -->
                  </div>
                </div>

                <!-- Log Content Area -->
                <div #logContainer class="logs-content">
                  <ng-container *ngIf="displayedLogs[steps.id] && displayedLogs[steps.id].length > 0">
                    <div class="log-entry"
                      *ngFor="let log of displayedLogs[steps.id]; let logIndex = index; let last = last" [ngClass]="{
                        'typing': !log.complete,
                        'highlighted-log': last && Conversation && Conversation.id === steps.id && steps.executionData?.stepStatus === 'CONVERSATION'
                      }">
                      <!-- <div class="log-timestamp">{{ log.timestamp | date:'HH:mm:ss' }}</div> -->
                      <div class="log-message" [class.complete]="log.complete">
                        <ng-container
                          *ngIf="last && Conversation && Conversation.id === steps.id && steps.executionData?.stepStatus === 'CONVERSATION'">
                          <div class="highlight-marker">
                            <mat-icon>priority_high</mat-icon>
                          </div>
                        </ng-container>
                        {{ log.text }}
                        <span *ngIf="!log.complete" class="typing-cursor">|</span>
                      </div>
                    </div>
                  </ng-container>

                  <!-- Empty State -->
                  <div *ngIf="!displayedLogs[steps.id] || displayedLogs[steps.id].length === 0"
                    class="logs-empty-state">
                    <mat-icon class="empty-icon">info</mat-icon>
                    <span class="empty-text">No logs available for this step</span>
                  </div>

                  <!-- User Action Section - Integrated within the step logs -->
                  <div class="user-action-inline" *ngIf="Conversation && Conversation.id === steps.id && steps.executionData?.stepStatus === 'CONVERSATION'
                           && Prop.stepFrom != 'DEBUGGE_WITH_AI'">
                    <!-- Initial Buttons -->
                    <div class="user-action-prompt" *ngIf="!showUserInputField">
                      <div class="prompt-header">
                        <mat-icon class="prompt-icon">help_outline</mat-icon>
                        <span>User input required to proceed</span>
                      </div>
                      <div class="prompt-actions">
                        <button class="action-btn primary" (click)="showUserInputField = true">
                          <mat-icon>chat</mat-icon>
                          Enter more details
                        </button>
                        <button class="action-btn secondary" (click)="stop_agent()">
                          <mat-icon>stop</mat-icon>
                          Stop
                        </button>
                      </div>
                    </div>

                    <!-- User Input Field -->
                    <div class="user-input-section" *ngIf="showUserInputField">
                      <div class="input-container">
                        <textarea class="input-field" placeholder="Type your response..." [(ngModel)]="message"
                          (keyup.enter)="sentevent()" rows="2" autofocus></textarea>
                        <button class="send-button" [disabled]="!message" (click)="sentevent()">
                          <mat-icon>send</mat-icon>
                        </button>
                      </div>
                      <button class="cancel-button" (click)="showUserInputField = false">
                        <mat-icon>arrow_back</mat-icon>
                        Back
                      </button>
                    </div>
                  </div>

                  <div class="user-action-inline" *ngIf="Conversation && Conversation.id === steps.id && steps.executionData?.stepStatus === 'CONVERSATION'
                         && Prop.stepFrom == 'DEBUGGE_WITH_AI'">
                    <!-- Initial Buttons -->
                    <div class="user-action-prompt" *ngIf="!showUserInputFieldinDebug">
                      <div class="prompt-header">
                        <mat-icon class="prompt-icon">help_outline</mat-icon>
                        <span>User input required to proceed</span>
                      </div>
                      <div class="prompt-actions">
                        <button class="action-btn primary" (click)="showUserInputFieldinDebug = true">
                          <mat-icon>chat</mat-icon>
                          Enter more details
                        </button>
                        <button class="action-btn secondary" (click)="handleStepAction('skip',steps.id)">
                          <mat-icon>skip_next</mat-icon>
                          Skip Step
                        </button>
                        <button class="action-btn secondary" (click)="handleStepAction('skip_delete',steps.id)">
                          <mat-icon>delete_outline</mat-icon>
                          Delete Step
                        </button>

                      </div>
                    </div>

                    <!-- User Input Field -->
                    <div class="user-input-section" *ngIf="showUserInputFieldinDebug">
                      <div class="input-container">
                        <textarea class="input-field" placeholder="Type your response..." [(ngModel)]="message"
                          (keyup.enter)="sentevent()" rows="2" autofocus></textarea>
                        <!-- <button class="send-button" [disabled]="!message" (click)="sentevent()">
                          <mat-icon>send</mat-icon>
                        </button> -->

                      </div>
                      <div class="prompt-actions" *ngIf="showUserInputFieldinDebug">
                        <button class="action-btn primary" [disabled]="!message"
                          (click)="updateStepData('reRun', 'metadata',steps.id)">
                          <mat-icon>replay</mat-icon>
                          Update and Rerun
                        </button>
                        <button class="action-btn primary" [disabled]="!message"
                          (click)="updateStepData('aiAgent', 'metadata',steps.id)">
                          <mat-icon>add_circle</mat-icon>
                          Add New Steps and Rerun
                        </button>
                      </div>
                      <button class="cancel-button" (click)="showUserInputFieldinDebug = false">
                        <mat-icon>arrow_back</mat-icon>
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="!stepGroupSteps.empty && steps.stepGroupId">
                <div style="
                    color: white;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    font-size: 16px;
                    padding-left: 20px;
                  ">
                  StepGroup Steps
                </div>
                <ng-container *ngFor="
                    let groupStep of stepGroupSteps?.content;
                    let i = index
                  ">
                  <mat-expansion-panel-header class="expansion-panel-custom step-group-panel">
                    <mat-panel-title>
                      <div class="steps-more-info">
                        <div class="sucessc-icon-with-text">
                          <img *ngIf="steps.executionData?.stepStatus == 'SUCCESS'" src="/assets/images/checkmark.svg"
                            alt="" />
                          <img *ngIf="steps.executionData?.stepStatus == 'FAILURE'" src="/assets/images/remove_4.png"
                            alt="" />
                          <img *ngIf="
                              steps.executionData?.stepStatus != 'SUCCESS' &&
                              steps.executionData?.stepStatus != 'FAILURE'
                            " src="/assets/images/pending.svg" alt="" />
                          <span [innerHtml]="groupStep.action" class="fz-14"></span>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                </ng-container>
              </ng-container>
            </mat-expansion-panel>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <div class="card first-card">
    <div class="toggle-button-img">
      <div [class.run_result_execution]="Prop.status == 'COMPLETED'" class="video-control"
        (click)="redirectToRunDetail()" [matTooltip]="'Execution may take a moment to complete.'"
        [class.disabled]="Prop.status != 'COMPLETED'"
        [style.pointer-events]="Prop.status != 'COMPLETED' ? 'none' : 'auto'">
        <mat-icon class="img">open_in_new</mat-icon>
      </div>
      <div mat-button (click)="closeDialog()" class="video-control">
        <mat-icon class="img">close</mat-icon>
      </div>
    </div>
    <div>
      <div class="image-container" [style.height]="imageContainerHeight + 'vh'">
        <img [src]="Prop.imageUrl" loading="lazy" width="100%" *ngIf="Prop && Prop.imageUrl" />
      </div>
      <div class="resizer" (mousedown)="startResize($event)"></div>

      <div class="card mt-10" [style.height]="consoleContainerHeight + 'vh'">
        <mat-tab-group class="tab-group-row">
          <mat-tab label="Console">
            <div class="console-wrapper">
              <ng-container *ngIf="Prop?.messageLog">
                <div class="console-line" *ngFor="let log of Prop?.messageLog">
                  <mat-icon class="console-icon" [ngClass]="log.type.toLowerCase()">
                    {{
                    log.type === "ERROR"
                    ? "error"
                    : log.type === "WARNING"
                    ? "warning"
                    : log.type === "INFO"
                    ? "info"
                    : log.type === "DEBUG"
                    ? "bug_report"
                    : "check_circle"
                    }}
                  </mat-icon>
                  <div class="console-content">
                    <span [ngStyle]="{
                        color:
                          log.type === 'ERROR'
                            ? '#ff8080'
                            : log.type === 'WARNING'
                            ? '#ffd700'
                            : log.type === 'INFO'
                            ? '#87ceeb'
                            : log.type === 'DEBUG'
                            ? '#98fb98'
                            : '#ffffff'
                      }">{{ log.text }}</span>
                    <span class="location" [title]="log.location">{{
                      log.location
                      }}</span>
                  </div>
                  <span class="console-timestamp">{{
                    log.timestamp | date : "HH:mm:ss.SSS"
                    }}</span>
                </div>

                <div class="console-line" *ngIf="!Prop?.messageLog.length">
                  <div class="console-content">No console logs found</div>
                </div>
              </ng-container>
            </div>
          </mat-tab>
          <mat-tab label="Network">
            <ng-container>
              <div class="network">
                <table class="network-table">
                  <thead>
                    <tr>
                      <th class="method-col">Method</th>
                      <th class="url-col">URL</th>
                      <th class="data-col">Request Data</th>
                      <th class="status-col">Status</th>
                    </tr>
                  </thead>
                </table>
                <div class="table-body-container">
                  <table class="network-table">
                    <tbody>
                      <tr *ngFor="let network of Prop?.networkLog">
                        <td class="method-col">{{ network.method }}</td>
                        <td class="url-col" [title]="network.url">
                          {{ network.url }}
                        </td>
                        <td class="data-col">{{ network.reqData || "-" }}</td>
                        <td class="status-col">{{ network.status || "-" }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <h5 *ngIf="!Prop?.networkLog">No network log found!</h5>
                </div>
              </div>
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loader" class="execution-start-container">
  <div class="execution-start-content">
    <h1 class="execution-title">Execution Started</h1>
    <div class="animation-container">
      <svg class="animated-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="45" stroke="#e0e0e0" stroke-width="10" fill="none" />
        <path class="animated-circle" d="M5 50a45 45 0 0 1 90 0" stroke="#007bff" stroke-width="10" fill="none" />
      </svg>
    </div>
    <p class="execution-message">
      Please wait while we prepare your test execution.
    </p>
    <button class="close-button" mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>