<div class="page-header d-flex fz-20 align-items-center">
  <div class="align-items-center d-flex rb-medium text-truncate ts-col-50 filter-action">
    <i class="fas fa-brain"></i>
    <span class="fz-18 text-truncate ml-8" [textContent]="'Custom Agents'"></span>
  </div>

  <div class="ml-auto">
    <button class="btn btn-primary" (click)="openCreateDialog()" style="border-radius: 5px;"> <span class="mr-3">+</span> Add</button>
  </div>
  <div class="page-content page-virtual-scroll green-highlight ng-star-inserted" style="padding: 30px;">
    <div *ngIf="custom_agent.length > 0" class="list-container">
        <!-- <p>{{prompt.title}}</p> -->
        <a class="list-view sm-pm pointer align-items-center" *ngFor="let customAgent of custom_agent">
            <div class="ts-col-30 d-flex pr-10">
              <a class="d-flex ts-col-90">
                <i class="fas fa-file-alt mr-2" style="font-size: 14px;"></i>
                <span class="text-truncate" style="font-size: 14px;margin-left: 6px;" [textContent]="customAgent.name"></span>
              </a>
            </div>
            <!-- <div class="ts-col-10 d-flex pr-10">
              <span class="text-truncat" *ngIf="prompt.isActive" style="font-size: 14px;">Default Prompt</span>
            </div> -->
            <div class="action-icons ml-auto" style="font-size: 12px;" (click)="$event.stopPropagation()">
              <!-- <i *ngIf="!prompt.isActive" (click)="togglePromptStatus(prompt)" 
                class="fas action-icon"
                [class.fa-toggle-on]="customAgent.isActive" 
                [class.fa-toggle-off]="!customAgent.isActive">
              </i> -->
              <i (click)="editCustomAgent(customAgent)" 
                class="fas fa-edit action-icon">
              </i>
              <i (click)="deleteCustomAgent(customAgent)" 
                class="fas fa-trash-alt action-icon">
              </i>
            </div>
        </a>
    </div>
    <div *ngIf="custom_agent.length === 0" class="empty-full-container ng-star-inserted">
      <div class="empty-full-content">
        <div class="empty-run-sm"></div>
        <div class="empty-text">No Custom Agents found.</div>
        <div class="d-flex justify-content-center mt-3">
          <button
            mat-stroked-button
            color="primary"
            (click)="openCreateDialog()">
            <i class="fas fa-plus"></i>
            Add Custom Agent
          </button>
        </div>
      </div>
    </div>
</div>

<div class="modal fade" id="createPromptModal" tabindex="-1" role="dialog" [ngClass]="{'show': showModal}" *ngIf="showModal">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header border-bottom">
        <h5 class="modal-title rb-medium">{{editingCustomAgentId ? 'Edit' : 'Create'}} Custom Agent</h5>
        <button type="button" class="close" (click)="closeModal()">
        </button>
      </div>
      <div class="modal-body p-4">
        <form style="padding: 15px;">
          <div class="floating-form-group" style="margin-bottom: 1.2rem;">
            <input 
              type="text" 
              class="floating-input" 
              id="name" 
              [(ngModel)]="name" 
              name="name"
              placeholder=" ">
            <label class="floating-label" for="name">Name <span class="required"></span></label>
          </div>
          <div class="floating-form-group">
            <textarea 
              class="floating-input" 
              id="description" 
              rows="4" 
              [(ngModel)]="description" 
              name="description"
              placeholder=" "></textarea>
            <label class="floating-label" for="description">Description <span class="required"></span></label>
          </div>
        </form>
      </div>
      <div class="modal-footer border-top">
        <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Cancel</button>
        <button type="button" 
                class="btn btn-primary" 
                [disabled]="!name?.trim() || !description?.trim()"
                (click)="savePrompt()">
          {{editingCustomAgentId ? 'Update' : 'Save'}}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show" *ngIf="showModal"></div>

<div *ngIf="showDeleteConfirmation" class="modal-overlay">
    <div class="confirmation-modal">
        <h2>Are you sure you want to delete this Custom Agent?</h2>
        <p>You cannot undo this action!</p>
        <div class="button-container">
            <button class="cancel-button" (click)="closeDeleteConfirmation()">Cancel</button>
            <button class="delete-button" (click)="confirmDelete()">Yes, Delete</button>
        </div>
    </div>
</div>
