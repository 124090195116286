<!-- Welcome Popup -->
<div class="welcome-popup">
  <div class="popup-content">
    <div class="popup-header">
      <img src="../../assets/color_icons/cqa-logo.svg" alt="ContextQA Logo" class="popup-logo">
      <h2>Welcome to Your AI Sandbox! <span class="emoji">🚀</span></h2>
    </div>
    
    <div class="popup-body">
      <div class="message-block">
        <p class="intro">You're now in the perfect sandbox environment to explore, experiment, and apply what you learn throughout this course! This is your space to follow along easily, test concepts in real-time, and get hands-on experience.</p>
      </div>

      <div class="credits-block">
        <div class="credits-badge pulse">
          <div class="credits-icon">
            <span class="emoji">💎</span>
          </div>
          <div class="credits-text">
            <span class="credits-amount">6,000</span>
            <span class="credits-label">FREE CREDITS</span>
          </div>
        </div>
        <p class="credits-info">
          <span class="highlight">3,500 credits</span> needed for course completion, 
          leaving you <span class="highlight">2,500 credits</span> to experiment and explore!
        </p>
      </div>

      <div class="help-block">
        <h3><span class="emoji">🤝</span> Need assistance?</h3>
        <div class="support-options">
          <a href="https://contextqa.onlinecoursehost.com/courses/software-testing-with-ai-copilot-contextqa-beginner-certification" class="support-link" target="_blank">
            <span class="emoji">💬</span> Comment on Course Page
          </a>
          <a href="https://www.linkedin.com/company/contextqa" class="support-link" target="_blank">
            <span class="emoji">👥</span> Join LinkedIn Community
          </a>
        </div>
      </div>

      <div class="message-block">
        <p class="outro">Now, let's jump in and start your journey to becoming an AI-powered Quality Professional! <span class="emoji">🎯</span> Happy learning!</p>
      </div>
    </div>

    <button class="start-button" (click)="closeDialog()">
      Let's Get Started!
      <span class="button-icon">→</span>
    </button>
  </div>
</div>