import { Component, Inject, OnInit } from '@angular/core';
import { TestCaseResult } from "../../models/test-case-result.model";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TestCasePrioritiesService } from "../../services/test-case-priorities.service";
import { TestCaseTypesService } from "../../services/test-case-types.service";
import { TestDataService } from "../../services/test-data.service";


@Component({
  selector: 'app-test-case-result-summary',
  templateUrl: './test-case-result-summary.component.html',
  styles: [`
    .result-summary-container {
      min-width: 600px;
    }
    .chart-section {
      background: #f8f9fa;
      border-radius: 4px;
    }
    .legend-container {
      padding-left: 20px;
    }
    .legend-item {
      font-size: 14px;
      transition: opacity 0.2s;
    }
    .legend-item:hover {
      opacity: 0.8;
    }
    .details-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px;
    }
  `]
})
export class TestCaseResultSummaryComponent implements OnInit {
  public testcaseResult: TestCaseResult;
  testStatuses = [
    { code: 0, label: 'execution.result.SUCCESS', countField: 'passedCount' },
    { code: 1, label: 'execution.result.FAILURE', countField: 'failedCount' },
    { code: 2, label: 'execution.result.ABORTED', countField: 'abortedCount' },
    { code: 3, label: 'execution.result.NOT_EXECUTED', countField: 'notExecutedCount' },
    { code: 5, label: 'execution.result.QUEUED', countField: 'queuedCount' }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public options: { testcaseResult: TestCaseResult },
    private testCasePriorityService: TestCasePrioritiesService,
    private testCaseTypeService: TestCaseTypesService,
    private testDataService: TestDataService) {
    this.testcaseResult = options.testcaseResult;
  }

  ngOnInit() {
    this.testCasePriorityService.show(this.testcaseResult.testCase.priorityId).subscribe(res => {
      this.testcaseResult.testCasePriority = res;
    })
    this.testCaseTypeService.show(this.testcaseResult.testCase.type).subscribe(res => {
      this.testcaseResult.testCaseType = res;
    })
    // this.userService.show(this.testcaseResult.testDeviceResult.testPlanResult.executedById).subscribe(user => this.testcaseResult.testDeviceResult.testPlanResult.executedBy = user);
    if (this.testcaseResult.testDataId)
      this.testDataService.show(this.testcaseResult.testDataId).subscribe(res => {
        this.testcaseResult.testDataProfile = res;
      })
  }

  get showFailedPercentage() {
    return this.testcaseResult.failedPercentage !== 0;
  }
  get showPassedPercentage() {
    return this.testcaseResult.passedPercentage !== 0;
  }
  get showAbortedPercentage() {
    return this.testcaseResult.abortedPercentage !== 0;
  }
  get showNotExecutedPercentage() {
    return this.testcaseResult.notExecutedPercentage !== 0;
  }
  get showQueuedPercentage() {
    return this.testcaseResult.queuedPercentage !== 0;
  }

}
