// src/app/chat-model/chat-model.component.ts
import { Component, ViewChild, ElementRef, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TestCaseResultService } from 'app/services/test-case-result.service';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';

interface ChatMessage {
    text: string;
    addedBy: "USER" | "SYSTEM";
    stepStatus?: string;
    stepId?: string;
    isFailedStep?: boolean;
    newLocator?: string;
    newMetadata?: string;
}

@Component({
    selector: 'app-execution-mode-close',
    templateUrl: './execution-mode-close.component.html',
    styleUrls: ['./execution-mode-close.component.scss'],
})
export class ExecutionCloseModelComponent {


    constructor(
        private dialogRef: MatDialogRef<ExecutionCloseModelComponent>,
        private testCaseResultService: TestCaseResultService,

    ) { }

    cancelClose(): void {
        this.dialogRef.close();
    }

    confirmClose(): void {
        this.dialogRef.close({ data: "you confirmed" });
    }
}   