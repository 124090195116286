<div class="page-header d-flex fz-20 align-items-center">
  <div class="align-items-center d-flex rb-medium text-truncate ts-col-50 filter-action" #filterListBtn>
    <i class="fa menu-bar-icon pointer border-0 fz-20" [matTooltip]="'hint.message.common.switch_view' | translate"
      (click)="openFiltersList()"></i>
    <!-- <span class="pl-8 text-warning" *ngIf="!!query">*</span> -->
    <span class="fz-18 text-truncate" [textContent]="currentFilter?.name"></span>
    <i *ngIf="!!query && !currentFilter?.isDefault" (click)="saveView()" class="fa-tick-thick pointer text-warning"
      [matTooltip]="'hint.message.common.save' | translate"></i>
    <!-- <i
      (click)="saveViewAs()"
      class="fa-save-as pointer px-8 text-warning"
      [matTooltip]="'hint.message.common.save_as' | translate"
      *ngIf="!!this.query"></i> -->
    <i (click)="discard()" class="fa-close-alt px-8 fz-13 pointer text-warning"
      [matTooltip]="'hint.message.common.discard' | translate" *ngIf="!!this.query"></i>
    <span *ngIf="!currentFilter?.isDefault">
      <span (click)="saveOrEditFilter()" class="fa-pencil-on-paper pointer px-10 fz-13"
        [matTooltip]="'hint.message.common.edit' | translate"></span>
      <span (click)="deleteFilter()" class="fa-trash-thin pointer px-10 fz-13"
        [matTooltip]="'hint.message.common.delete' | translate"></span>
    </span>
  </div>

  <div class="align-items-baseline ml-auto filter-show" [class.d-none]="selectedElements.length"
    [class.d-flex]="!selectedElements.length" [class.d-visible]="switch.classList.contains('filtered')">
    <button *ngIf="hasInspectorFeature()" [routerLink]="['/td', 'record', this.versionId]"
      [queryParams]="{ isRecord: true }" class="theme-btn-clear-default ml-14"
      [translate]="'elements.btn.record'"></button>
    <!-- <div *ngIf="version?.workspace?.isWebMobile && chromeRecorderService.isChrome"
         [matTooltip]="( !chromeRecorderService.isInstalled ?'elements.form.chrome_extension.not_have_info':'') | translate">
      <button
        [disabled]="!chromeRecorderService.isInstalled"
        (click)="elementCapture? stopCapture() : startCapture()"
        class="theme-btn-clear-default ml-14"
        [translate]="(!elementCapture)?'elements.btn.record':'elements.btn.stop'"></button>
    </div> -->
    <button (click)="openAddEditElement(null)" class="theme-btn-primary ml-14 my-auto"
      [translate]="'btn.common.create'"></button>
    <app-sort-by-button *ngIf="elements?.totalElements || !!query" [sortByColumns]="sortByColumns" [sortedBy]="sortedBy"
      [direction]="direction" [translatePreFix]="'element.list.sort_by.'"
      (sortAction)="sortBy($event.sortBy, $event.direction)"></app-sort-by-button>
    <button [class.d-none-important]="!elements?.totalElements && !query" (click)="openFilter()"
      [matTooltip]="'hint.message.common.filter' | translate"
      class="btn icon-btn border-rds-2 ml-14 filter-icon-with-reset">
      <i class="filter-icon" #switch [class.filtered]="!!this.query"></i>
    </button>
  </div>
</div>
<div class="page-content page-virtual-scroll">
  <div class="tab-group-row d-flex align-items-center pl-40 pb-15">
    <mat-tab-group class="full-width-tabs" [(selectedIndex)]="selectedTabIndex"
      (selectedIndexChange)="onTabChange($event)">
      <mat-tab label="Screen View" class="fw-bold" (click)="fetchScreenNames()" class="fw-bold">
      </mat-tab>
      <mat-tab label="List View"></mat-tab>
    </mat-tab-group>
  </div>

  <div class="px-40" *ngIf="selectedTabIndex === 1">
    <div *ngIf="elements?.totalElements" [ngSwitch]="selectedElements.length > 0" class="list-header min-height-set">
      <div class="ts-col-30 pr-10 d-flex align-items-center">
        <mat-checkbox (change)="selectAllToggle(selectAll)"
          [checked]="selectedElements.length === elements['cachedItems'].length" [(ngModel)]="selectAll"
          [ngModelOptions]="{ standalone: true }">
        </mat-checkbox>
        <div *ngSwitchCase="true" class="text-nowrap bg-light z-in-3">
          <button (click)="bulkUpdateDialog(selectedElements)" class="theme-btn-clear-default py-6 text-nowrap ml-15">
            <span [translate]="'btn.common.bulk_update'"></span>
          </button>
          <button (click)="openDeleteDialog(null)" [matTooltip]="'hint.message.common.delete_selected' | translate"
            class="btn icon-btn border-rds-2 ml-14">
            <i class="fa-trash-thin"></i>
          </button>
        </div>
        <span class="pl-15">
          <app-inline-sort *ngSwitchCase="false" [ascending]="sortedBy !== 'name' ? undefined : direction === ',asc'"
            (click)="
              sortBy(
                'name',
                sortedBy !== 'name' || direction === ',desc' ? ',asc' : ',desc'
              )
            " [heading]="'elements.element_name'"></app-inline-sort>
        </span>
      </div>
      <div class="ts-col-10 d-flex align-items-center">
        <app-inline-sort [ascending]="
            sortedBy !== 'locatorType' ? undefined : direction === ',asc'
          " (click)="
            sortBy(
              'locatorType',
              sortedBy !== 'locatorType' || direction === ',desc'
                ? ',asc'
                : ',desc'
            )
          " [heading]="'elements.coverage'">
        </app-inline-sort>
      </div>
      <div class="ts-col-10 d-flex align-items-center">
        <app-inline-sort [ascending]="
            sortedBy !== 'locatorType' ? undefined : direction === ',asc'
          " (click)="
            sortBy(
              'locatorType',
              sortedBy !== 'locatorType' || direction === ',desc'
                ? ',asc'
                : ',desc'
            )
          " [heading]="'elements.lables'">
        </app-inline-sort>
      </div>

      <div class="ts-col-25 px-10 d-flex align-items-center">
        <app-inline-sort [ascending]="
            sortedBy !== 'screenNameId' ? undefined : direction === ',asc'
          " (click)="
            sortBy(
              'screenNameId',
              sortedBy !== 'screenNameId' || direction === ',desc'
                ? ',asc'
                : ',desc'
            )
          " [heading]="'elements.screen_name'"></app-inline-sort>
      </div>
      <div class="ts-col-10 d-flex align-items-center">
        <app-inline-sort [ascending]="
            sortedBy !== 'createdDate' ? undefined : direction === ',asc'
          " (click)="
            sortBy(
              'createdDate',
              sortedBy !== 'createdDate' || direction === ',desc'
                ? ',asc'
                : ',desc'
            )
          " [heading]="'elements.created_at'">
        </app-inline-sort>
      </div>
    </div>
  </div>
  <ng-container *ngIf="selectedTabIndex === 1">
    <cdk-virtual-scroll-viewport *ngIf="elements?.totalElements" itemSize="59"
      class="list-container virtual-scroll-viewport">
      <a class="list-view green-highlight sm-pm pointer align-items-center" *cdkVirtualFor="let element of elements">
        <div class="ts-col-30 d-flex pr-10">
          <mat-checkbox class="mat-checkbox" [disableRipple]="true" [(ngModel)]="element.isSelected"
            [ngModelOptions]="{ standalone: true }" (change)="setSelectedList(element.id, element.isSelected)">
          </mat-checkbox>
          <a class="d-flex ts-col-90" (click)="openDetails(element)">
            <span class="pl-15 text-truncate" [textContent]="element.name"></span>
          </a>
        </div>
        <div class="ts-col-10">
          <mat-chip-list class="mat-custom-chip-list">
            <mat-chip style="font-size: 10px; padding: 3px 8px; min-height: 24px">
              <span *ngIf="element.linkedTestCases === 0" [textContent]="element.linkedTestCases"></span>
              <span *ngIf="element.linkedTestCases > 0" style="cursor: pointer" (click)="checkIsTestcaseID(element)"
                [textContent]="element.linkedTestCases"></span>
            </mat-chip>
          </mat-chip-list>
        </div>
        <div class="ts-col-10">
          <app-limited-chip-list [tags]="element?.tags"></app-limited-chip-list>
          <!-- <mat-chip-list class="mat-custom-chip-list">
          <mat-chip style=" font-size: 10px; padding: 3px 8px; min-height: 24px;" *ngFor="let tag of element?.tags">
            <span [textContent]="tag"></span>
          </mat-chip>
        </mat-chip-list> -->
        </div>
        <div class="ts-col-25 plr-10 text-truncate hover-text-underline">
          <span [textContent]="element.screenNameObj?.name" (click)="filterByScreenName(element.screenNameObj?.name)"
            [matTooltip]="'elements.filter.screen_name.tooltip' | translate" [matTooltipPosition]="tooltipPositionLeft">
          </span>
        </div>
        <div class="ts-col-10">
          <span class="ml-n3" [matTooltip]="
              element?.createdDate
                ? (element?.createdDate | date : 'short')
                : ''
            " [textContent]="
              element?.createdDate ? humanizedDate(element?.createdDate) : '-'
            "></span>
        </div>
        <div class="action-icons ml-auto" *ngIf="selectedElements.length <= 0">
          <i class="fa-tags-solid action-icon" [matTooltip]="'elements.filter.form.label.labels' | translate"
            (click)="addLabelsDialog(element.id)"></i>

          <i class="fa-pencil-on-paper action-icon" [matTooltip]="'btn.common.edit' | translate"
            (click)="openAddEditElement(element.id)"></i>
          <i class="fa-trash-thin action-icon" (click)="checkForLinkedTestCases(element)"
            [matTooltip]="'hint.message.common.delete' | translate"></i>
        </div>
      </a>
    </cdk-virtual-scroll-viewport>
  </ng-container>

  <div *ngIf="selectedTabIndex === 0" class="tree-view-wrapper">
    <div class="tree-container">
      <!-- Left Panel -->
      <div class="left-panel" [class.collapsed]="isPanelCollapsed">
        <!-- Toggle button for mobile -->
        <!-- <button class="panel-toggle d-md-none" (click)="togglePanel()">
          <i class="fa" [class.fa-chevron-right]="isPanelCollapsed" [class.fa-chevron-left]="!isPanelCollapsed"></i>
        </button> -->

        <div class="panel-header">
          <div class="d-flex justify-content-between align-items-center w-100">
            <div>
              <span class="header-title">Screens</span>
              <span class="header-count ml-5">{{ screenName?.length || 0 }} screens</span>
            </div>
            <i class="fa-plus pointer" (click)="openAddScreenName('', '')"
              [matTooltip]="'Add New Screen' | translate"></i>
          </div>
        </div>

        <!-- Screen list content remains the same -->
        <div class="screen-list">
          <div *ngFor="let screen of screenName" class="screen-item"
            [class.active]="selectedScreen?.name === screen.name" (click)="selectScreen(screen)">
            <div class="screen-content">
              <mat-icon class="folder-icon">{{
                selectedScreen?.name === screen.name ? "folder_open" : "folder"
                }}</mat-icon>
              <div class="screen-details">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <span class="screen-name text-truncate" [matTooltip]="screen.name">{{ screen.name }}</span>
                  <div class="d-flex align-items-center ml-2">
                    <span class="element-badge flex-shrink-0">{{ screen.elements?.length || 0 }} elements</span>
                    <i class="fa-pencil-on-paper ml-2 flex-shrink-0" (click)="openAddScreenName(screen, 'Edit')"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Panel -->
      <div class="right-panel" [class.expanded]="isPanelCollapsed">
        <div class="panel-header">
          <span class="header-title">{{
            selectedScreen?.name || "Select a screen"
            }}</span>
          <span class="header-count" *ngIf="selectedScreen">{{ selectedScreen?.elements?.length || 0 }} elements</span>
        </div>

        <!-- Add tabs section -->
        <div class="tab-group-row">
          <mat-tab-group *ngIf="selectedScreen" [(selectedIndex)]="screenNameDetailIndex"
            (selectedIndexChange)="onScreenTabChange($event)" [disablePagination]="true" animationDuration="0ms">
            <!-- Details Tab -->
            <mat-tab label="Details">
              <div class="tab-content p-20">
                <div class="activities-container">
                  <div class="test-scenario">
                    <h3 class="scenario-title">
                      Screen Name: {{ selectedScreen.name }}
                    </h3>
                    <p class="scenario-description">
                      Total Elements: {{ selectedScreen.elements?.length || 0 }}
                    </p>
                    <p class="scenario-description">
                      URL: {{ selectedScreen.url || "-" }}
                    </p>
                  </div>
                </div>
              </div>
            </mat-tab>

            <!-- Elements Tab -->
            <mat-tab label="Elements">
              <div class="elements-list" *ngIf="selectedScreen?.elements?.length">
                <div *ngFor="let element of selectedScreen.elements" class="element-item">
                  <div class="element-header" (click)="toggleElement(element)">
                    <div class="element-info">
                      <i class="fa-elements-alt element-icon" style="font-size: 16px"></i>
                      <span class="element-name">{{ element.name }}</span>
                    </div>
                    <div class="element-actions">
                      <span class="test-case-badge" *ngIf="element.linkedTestCases">
                        {{ element.linkedTestCases }} test cases
                      </span>
                      <div class="action-icons ml-auto">
                        <i class="fa-tags-solid action-icon" [matTooltip]="
                            'elements.filter.form.label.labels' | translate
                          " (click)="addLabelsDialog(element.id)"></i>

                        <i class="fa-pencil-on-paper action-icon" [matTooltip]="'btn.common.edit' | translate"
                          (click)="openAddEditElement(element.id)"></i>
                        <i class="fa-trash-thin action-icon" (click)="checkForLinkedTestCases(element)" [matTooltip]="
                            'hint.message.common.delete' | translate
                          "></i>
                      </div>
                      <mat-icon *ngIf="element.linkedTestCases > 0" class="expand-icon">
                        {{ element.expanded ? "expand_more" : "chevron_right" }}
                      </mat-icon>
                    </div>
                  </div>

                  <div *ngIf="element.expanded" class="test-cases-section">
                    <div class="test-cases-list">
                      <cdk-virtual-scroll-viewport *ngIf="element.testcases?.cachedItems?.length"
                        class="test-cases-viewport" itemSize="42">
                        <div *cdkVirtualFor="let testCase of element.testcases" class="test-case-item">
                          <div class="test-case-info">
                            <mat-icon class="test-icon">description</mat-icon>
                            <span class="test-name">{{ testCase.name }}</span>
                          </div>
                          <button class="open-test-btn" (click)="openLinkedEntity(testCase)">
                            <span>Open
                              {{
                              testCase.type ? "Test Case" : "Test Suite"
                              }}</span>
                            <i class="fa-external-link-alt-solid"></i>
                          </button>
                        </div>
                      </cdk-virtual-scroll-viewport>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <!-- Insights Tab -->
            <mat-tab label="Insights"
              [disabled]="!lockData?.featureSupport?.activity && !lockData?.featureSupport?.insight_errors && !lockData?.featureSupport?.gap && !lockData?.featureSupport?.heatmap && !lockData?.featureSupport?.usersegment && !lockData?.featureSupport?.insights_journey_chart">
              <ng-template mat-tab-label>
                <span>Insights</span>
                <mat-icon
                  *ngIf="!lockData?.featureSupport?.activity && !lockData?.featureSupport?.insight_errors &&  !lockData?.featureSupport?.gap && !lockData?.featureSupport?.heatmap && !lockData?.featureSupport?.usersegment && !lockData?.featureSupport?.insights_journey_chart"
                  class="ml-5">lock</mat-icon>
              </ng-template>
              <div class="insights-container">
                <mat-tab-group (selectedIndexChange)="onInsightsSubTabChange($event)"
                  [(selectedIndex)]="selectedInsightsTab" class="insights-tabs" [disablePagination]="true"
                  animationDuration="0ms">
                  <mat-tab label="Activities" [disabled]="!lockData?.featureSupport?.activity">
                    <div class="p-20">
                      <div *ngIf="!lockData?.featureSupport?.activity" class="lock-message">
                        <mat-icon>lock</mat-icon>
                        <span>This feature is not available in your current plan</span>
                      </div>
                      <div *ngIf="lockData?.featureSupport?.activity" class="activities-container">
                        <div *ngIf="isLoadingActivities" class="d-flex justify-content-center my-5">
                          <mat-spinner diameter="40"></mat-spinner>
                        </div>
                        <div class="d-flex justify-content-end mb-3">
                          <mat-form-field class="mat-date-custom mr-8" appearance="fill">
                            <mat-label>Select Date Range</mat-label>
                            <mat-date-range-input [rangePicker]="createdDateRangePicker" [formGroup]="createdDateRange"
                              [min]="minDate" [max]="maxDate" [matDatepickerFilter]="dateFilter">
                              <input matStartDate formControlName="start" placeholder="Start date"
                                (dateChange)="onStartDateChange($event)" required />
                              <input matEndDate formControlName="end" placeholder="End date" required />
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="createdDateRangePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #createdDateRangePicker></mat-date-range-picker>
                          </mat-form-field>

                          <button class="theme-btn-primary" (click)="refreshGActiviteData()"
                            [disabled]="isLoadingActivities">
                            <i class="fas fa-sync" [class.fa-spin]="isLoadingActivities"></i>
                            Refresh Data
                          </button>
                        </div>
                        <div class="test-scenario" *ngFor="let activity of activityData">
                          <h3 class="scenario-title">{{ activity.name }}</h3>
                          <p class="scenario-description" style="white-space: pre-line">
                            {{ activity.description }}
                          </p>
                          <div class="scenario-details">
                            <div class="initial-conditions">
                              <strong>Pre-condition:</strong>
                              {{ activity.preconditions }}
                            </div>
                            <div class="initial-conditions">
                              <strong>Type:</strong>
                              {{ activity.type }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-tab>

                  <mat-tab label="Gap" [disabled]="!lockData?.featureSupport?.gap || !selectedScreen.url">
                    <div class="p-20">
                      <div *ngIf="!lockData?.featureSupport?.gap" class="lock-message">
                        <mat-icon>lock</mat-icon>
                        <span>This feature is not available in your current plan</span>
                      </div>
                      <div *ngIf="lockData?.featureSupport?.gap">
                        <div class="p-20" *ngIf="testcaseDetails?.testCases || isLoadingGaps">
                          <div class="activities-container">
                            <div class="d-flex justify-content-end mb-3">
                              <mat-form-field class="mat-date-custom mr-8" appearance="fill">
                                <mat-label>Select Date Range</mat-label>
                                <mat-date-range-input [rangePicker]="createdDateRangePicker"
                                  [formGroup]="createdDateRange" [min]="minDate" [max]="maxDate"
                                  [matDatepickerFilter]="dateFilter">
                                  <input matStartDate formControlName="start" placeholder="Start date"
                                    (dateChange)="onStartDateChange($event)" required />
                                  <input matEndDate formControlName="end" placeholder="End date" required />
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="createdDateRangePicker"></mat-datepicker-toggle>
                                <mat-date-range-picker #createdDateRangePicker></mat-date-range-picker>
                              </mat-form-field>
                              <button class="theme-btn-primary" (click)="refreshGapsData()" [disabled]="isLoadingGaps">
                                <i class="fas fa-sync" [class.fa-spin]="isLoadingGaps"></i>
                                Refresh Data
                              </button>
                            </div>

                            <div *ngIf="isLoadingGaps" class="d-flex justify-content-center my-5">
                              <mat-spinner diameter="40"></mat-spinner>
                            </div>
                            <app-ai-test-case-display *ngIf="!isLoadingGaps" [testcaseDetails]="testcaseDetails"
                              [isTestPlanButton]="true" [version]="version">
                            </app-ai-test-case-display>
                          </div>
                        </div>
                        <div *ngIf="!testcaseDetails?.testCases && !isLoadingGaps">
                          <p style="text-align: center;">No Data Found</p>
                        </div>
                      </div>
                    </div>
                  </mat-tab>

                  <mat-tab label="Errors" [disabled]="!lockData?.featureSupport?.insight_errors">
                    <ng-template mat-tab-label>
                      <mat-icon *ngIf="!lockData?.featureSupport?.insight_errors" class="ml-5">lock</mat-icon>
                      <span>Errors</span>
                    </ng-template>
                    <div class="p-20">
                      <div *ngIf="lockData?.featureSupport?.insight_errors" class="activities-container">
                        <div *ngIf="isLoadingErrors" class="d-flex justify-content-center my-5">
                          <mat-spinner diameter="40"></mat-spinner>
                        </div>
                        <div class="d-flex justify-content-end mb-3">
                          <mat-form-field class="mat-date-custom mr-8" appearance="fill">
                            <mat-label>Select Date Range</mat-label>
                            <mat-date-range-input [rangePicker]="errorDateRangePicker" [formGroup]="createdDateRange"
                              [min]="minDate" [max]="maxDate" [matDatepickerFilter]="dateFilter">
                              <input matStartDate formControlName="start" placeholder="Start date"
                                (dateChange)="onStartDateChange($event)" required />
                              <input matEndDate formControlName="end" placeholder="End date" required />
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="errorDateRangePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #errorDateRangePicker></mat-date-range-picker>
                          </mat-form-field>

                          <button class="theme-btn-primary" (click)="refreshErrorsData()" [disabled]="isLoadingErrors">
                            <i class="fas fa-sync" [class.fa-spin]="isLoadingErrors"></i>
                            Refresh Data
                          </button>
                        </div>
                        <div class="test-scenario" *ngFor="let error of errorData">
                          <h3 class="scenario-title">{{ error.name }}</h3>
                          <p class="scenario-description" style="white-space: pre-line">
                            {{ error.description }}
                          </p>
                          <div class="scenario-details">
                            <div class="initial-conditions">
                              <strong>Content:</strong>
                              {{ error.content }}
                            </div>
                            <div class="initial-conditions" *ngIf="error.preconditions">
                              <strong>Preconditions:</strong>
                              {{ error.preconditions }}
                            </div>
                            <div class="initial-conditions" *ngIf="error.url">
                              <strong>URL:</strong>
                              {{ error.url }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-tab>

                  <mat-tab label="Heatmap (Beta)" [disabled]="!lockData?.featureSupport?.heatmap">
                    <div class="p-20">
                      <div *ngIf="!lockData?.featureSupport?.heatmap" class="lock-message">
                        <mat-icon>lock</mat-icon>
                        <span>This feature is not available in your current plan</span>
                      </div>
                      <div *ngIf="lockData?.featureSupport?.heatmap" class="heatmap-container">
                        <div *ngIf="isLoadingHeatmap" class="d-flex justify-content-center my-5">
                          <mat-spinner diameter="40"></mat-spinner>
                        </div>
                        <img *ngIf="!isLoadingHeatmap && heatmap?.activity_url" [src]="heatmap?.activity_url"
                          alt="Element Usage Heatmap" class="heatmap-image" />
                      </div>

                      <div *ngIf="lockData?.featureSupport?.heatmap && !isLoadingHeatmap && !heatmap?.activity_url"
                        class="green-highlight" style="text-align: center">
                        <div class="empty-full-container ng-star-inserted" *ngIf="!checkExtensionInstall">
                          <div class="empty-full-content">
                            <div class="empty-run-sm"></div>
                            <div class="empty-text">
                              No heatmap found. Please install our Chrome
                              Extension to view and analyze heatmaps.
                            </div>
                            <div class="d-flex justify-content-end ml-10 pb-10 ng-star-inserted">
                              <a [routerLink]="['/create-heatmap']">
                                <button mat-stroked-button color="primary" (click)="openSidePanelAndHeatmapPage()">
                                  <mat-icon>add</mat-icon>
                                  Open Extension
                                </button>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="extension-warning" *ngIf="checkExtensionInstall">
                          <h2 class="warning-title" style="text-align: center">
                            Contextqa Recorder not detected
                          </h2>
                          <hr />
                          <p>To start recording, install the Contextqa Recorder Chrome extension</p>
                          <div class="steps-container">
                            <div class="step-item">
                              <div class="step-description">
                                <span style="font-size: 14px">1. Install the Contextqa Recorder Chrome extension to
                                  easily
                                  record scenarios</span>
                                <a [href]="
                                      'https://chromewebstore.google.com/detail/contextqa-test-automation/' +
                                      getExtensionId
                                    " target="_blank" class="install-button">
                                  <img width="48" height="48" src="https://img.icons8.com/color/48/chrome--v1.png"
                                    alt="chrome--v1" class="chrome-icon" />
                                  Install Chrome extension
                                </a>
                              </div>
                            </div>

                            <div class="divider"></div>

                            <div class="step-item">
                              <div class="step-description">
                                <span style="font-size: 14px">2. Enable the Chrome extension in incognito mode as
                                  Contextqa
                                  Recorder requires a clean state to record your scenario</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-tab>

                  <mat-tab label="User Segments" [disabled]="!lockData?.featureSupport?.usersegment">
                    <div class="p-20">
                      <div *ngIf="!lockData?.featureSupport?.usersegment" class="lock-message">
                        <mat-icon>lock</mat-icon>
                        <span>This feature is not available in your current plan</span>
                      </div>
                      <div *ngIf="lockData?.featureSupport?.usersegment" class="segments-container">
                        <div class="d-flex justify-content-end mb-3">
                          <button class="theme-btn-primary" (click)="refreshUserSegments()"
                            [disabled]="isLoadingSegments">
                            <i class="fas fa-sync" [class.fa-spin]="isLoadingSegments"></i>
                            Refresh Data
                          </button>
                        </div>

                        <div *ngIf="isLoadingSegments" class="d-flex justify-content-center my-5">
                          <mat-spinner diameter="40"></mat-spinner>
                        </div>

                        <div *ngIf="userSegments && !isLoadingSegments" class="segments-grid">
                          <div class="segment-card">
                            <h3 class="segment-title">Device Distribution</h3>
                            <div class="segment-items">
                              <div *ngFor="let item of userSegments.device" class="segment-item">
                                <span class="item-label">{{ item.key }}</span>
                                <div class="progress-bar">
                                  <div class="progress" [style.width]="item.value"></div>
                                </div>
                                <span class="item-value">{{ item.value }}</span>
                              </div>
                            </div>
                          </div>

                          <div class="segment-card">
                            <h3 class="segment-title">Browser Distribution</h3>
                            <div class="segment-items">
                              <div *ngFor="let item of userSegments.browser" class="segment-item">
                                <span class="item-label">{{ item.key }}</span>
                                <div class="progress-bar">
                                  <div class="progress" [style.width]="item.value"></div>
                                </div>
                                <span class="item-value">{{ item.value }}</span>
                              </div>
                            </div>
                          </div>

                          <div class="segment-card">
                            <h3 class="segment-title">Operating System</h3>
                            <div class="segment-items">
                              <div *ngFor="let item of userSegments.os" class="segment-item">
                                <span class="item-label">{{ item.key }}</span>
                                <div class="progress-bar">
                                  <div class="progress" [style.width]="item.value"></div>
                                </div>
                                <span class="item-value">{{ item.value }}</span>
                              </div>
                            </div>
                          </div>

                          <div class="segment-card">
                            <h3 class="segment-title">Country Distribution</h3>
                            <div class="segment-items">
                              <div *ngFor="let item of userSegments.country" class="segment-item">
                                <span class="item-label">{{ item.key }}</span>
                                <div class="progress-bar">
                                  <div class="progress" [style.width]="item.value"></div>
                                </div>
                                <span class="item-value">{{ item.value }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-tab>

                  <mat-tab label="Graph" [disabled]="!lockData?.featureSupport?.insights_journey_chart">
                    <div class="p-20">
                      <div *ngIf="!lockData?.featureSupport?.insights_journey_chart" class="lock-message">
                        <mat-icon>lock</mat-icon>
                        <span>This feature is not available in your current plan</span>
                      </div>
                      <div *ngIf="lockData?.featureSupport?.insights_journey_chart">
                        <app-insight-graph [projectId]="projectId" [urlStart]="selectedScreen?.url">
                        </app-insight-graph>
                      </div>
                    </div>
                  </mat-tab>

                </mat-tab-group>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class="empty-state" *ngIf="!selectedScreen">
          <mat-icon>folder_open</mat-icon>
          <span>Select a screen from the left to view its details</span>
        </div>
      </div>
    </div>
  </div>

  <app-placeholder-loader *ngIf="elements?.isFetching"></app-placeholder-loader>
  <div *ngIf="!elements?.totalElements && !elements?.isFetching" class="h-100">
    <div class="empty-full-container">
      <div class="empty-run-md"></div>
      <div [translate]="
          filterId === 1 && !query
            ? 'elements.not_created'
            : 'message.common.search.not_found'
        " class="empty-text"></div>
      <div class="d-flex" *ngIf="filterId === 1 && !query">
        <button *ngIf="hasInspectorFeature()" [routerLink]="['/agents', 'record', this.versionId]"
          [queryParams]="{ isRecord: true }" class="theme-btn-clear-default ml-14"
          [translate]="'elements.btn.record'"></button>
        <button (click)="openAddEditElement(null)" class="theme-btn-primary ml-14 my-auto"
          [translate]="'btn.common.create'"></button>
      </div>
    </div>
  </div>
</div>