import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpHeadersService} from './http-headers.service';
import {UrlConstantsService} from './url.constants.service';
import {Pageable} from "../../shared/models/pageable";
import {Page} from "../../shared/models/page";
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {LicenceData} from '../../models/licence-data.model';

@Injectable({
  providedIn: 'root'
})

export class LicenceSevices {

  private dayFilter = new BehaviorSubject('');
  private lockDataSubject = new BehaviorSubject<any>(null);
  
  dayFilter$ = this.dayFilter.asObservable();
  lockData$ = this.lockDataSubject.asObservable();

  constructor(
    private http: HttpClient,
    private httpHeaders: HttpHeadersService,
    private URLConstants: UrlConstantsService) {
  }

  setDayFilter(data: any) {
    this.dayFilter.next(data);
  }

  setLockData(data: any) {
    this.lockDataSubject.next(data);
  }

  public getAll(filter?: string, sortBy?: string, pageable?: Pageable | undefined): Observable<Page<LicenceData>> {
    return this.http.get<Page<LicenceData>>(this.URLConstants.licenceUrl, {
      headers: this.httpHeaders.contentTypeApplication,
      params: this.httpHeaders.serializeParams(filter, sortBy, pageable)
    }).pipe(
      map(data => data),
      // map(data => new Page<LicenceData>().deserialize(data, LicenceData)),
      catchError(() => throwError('Problem while fetching Mobile Inspection Results'))
    )
  }

  public getFreePricing() {
    return this.http.get(this.URLConstants.FreePricing, {
      headers: this.httpHeaders.contentTypeApplication,
      // params: this.httpHeaders.serializeParams(filter, sortBy, pageable)
    }).pipe(
      map(data => data),
      // map(data => new Page<LicenceData>().deserialize(data, LicenceData)),
      catchError(() => throwError('Problem while fetching Mobile Inspection Results'))
    )
  }

  public getBasicPricing() {
    return this.http.get(this.URLConstants.BaciPricing, {
      headers: this.httpHeaders.contentTypeApplication,
      // params: this.httpHeaders.serializeParams(filter, sortBy, pageable)
    }).pipe(
      map(data => data),
      // map(data => new Page<LicenceData>().deserialize(data, LicenceData)),
      catchError(() => throwError('Problem while fetching Mobile Inspection Results'))
    )
  }

  public getPremiumPricing() {
    return this.http.get(this.URLConstants.PremiumPricing, {
      headers: this.httpHeaders.contentTypeApplication,
      // params: this.httpHeaders.serializeParams(filter, sortBy, pageable)
    }).pipe(
      map(data => data),
      // map(data => new Page<LicenceData>().deserialize(data, LicenceData)),
      catchError(() => throwError('Problem while fetching Mobile Inspection Results'))
    )
  }

  public getElitePricing() {
    return this.http.get(this.URLConstants.FreePricing, {
      headers: this.httpHeaders.contentTypeApplication,
      // params: this.httpHeaders.serializeParams(filter, sortBy, pageable)
    }).pipe(
      map(data => data),
      // map(data => new Page<LicenceData>().deserialize(data, LicenceData)),
      catchError(() => throwError('Problem while fetching Mobile Inspection Results'))
    )
  }
}
