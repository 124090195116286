import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { WorkspaceVersion } from "../../models/workspace-version.model";
import { TestPlanResultService } from "../../services/test-plan-result.service";
import { InfiniteScrollableDataSource } from "../../data-sources/infinite-scrollable-data-source";
import { FilterTimePeriod } from "../../enums/filter-time-period.enum";
import * as moment from 'moment';
import { Page } from "../../shared/models/page";
import { TestDeviceResult } from "../../models/test-device-result.model";
import { TestPlanResult } from "../../models/test-plan-result.model";
import { TestDeviceResultService } from "../../shared/services/test-device-result.service";
import { Router } from '@angular/router';
import { TestCaseResultService } from "../../services/test-case-result.service";
import { TestCaseResult } from "../../models/test-case-result.model";
import { OnBoardingSharedService } from "../../services/on-boarding-shared.service";
import { UserPreferenceService } from "../../services/user-preference.service";
import { LicenceSevices } from "../../shared/services/license.service";
import { MatSidenav } from '@angular/material/sidenav';
import { ChatbotUiStateService } from '../../services/chatbot-ui-state.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { NotificationsService } from 'angular2-notifications';

interface TestCaseSuggestion {
  data: any;
  selected?: boolean;
}

@Component({
  selector: 'app-latest-runs',
  templateUrl: './latest-runs.component.html',
  styleUrls: ['./latest-runs.component.scss']
})
export class LatestRunsComponent implements OnInit {
  @Input() version: WorkspaceVersion;
  @Input() lastRun: any;
  @Input() lastRunId: any;
  public period: FilterTimePeriod = FilterTimePeriod.LAST_90_DAYS;
  public testPlanResults: InfiniteScrollableDataSource;
  public dayResults: InfiniteScrollableDataSource;
  public weekResults: InfiniteScrollableDataSource;
  public monthResults: InfiniteScrollableDataSource;
  public quarterResults: InfiniteScrollableDataSource;
  public filterTimePeriod = FilterTimePeriod;
  public environmentResults: Page<TestDeviceResult>;
  public activeExecutionResult: TestPlanResult;
  items = Array.from({ length: 100000 }).map((_, i) => `Item #${i}`);
  filterObj: any;
  public aiTestCaseSuggestions: any[] = [];
  @ViewChild('aiSuggestionSidenav') aiSuggestionSidenav: MatSidenav;
  public selectedSuggestion: any = null;
  isDescriptionExpanded = false;
  public isAssistantRoute: boolean = false;
  public isChatbotOpened: boolean = false;
  selectAll: boolean = false;

  constructor(
    public router: Router,
    private environmentResultService: TestDeviceResultService,
    private testPlanResultService: TestPlanResultService,
    private testCaseResultService: TestCaseResultService,
    private userPreferenceService: UserPreferenceService,
    public LicenceSevices: LicenceSevices,
    private onBoardingSharedService: OnBoardingSharedService,
    public chatbotUiState: ChatbotUiStateService,
    private notificationsService: NotificationsService) {
    this.chatbotUiState.isOpened$.subscribe(
      isOpened => this.isChatbotOpened = isOpened
    );
    
    // Add this to configure the icons
    this.notificationsService.icons = {
      success: '<i class="material-icons">check_circle</i>',
      error: '<i class="material-icons">error</i>',
      alert: '<i class="material-icons">warning</i>',
      info: '<i class="material-icons">info</i>',
      warn: '<i class="material-icons">warning</i>'
    };
  }

  async ngOnInit(): Promise<any> {
    this.loadAiTestSuggestion();
    this.testPlanResults = this.dayResults = await this.fetchTestPlanResults(this.period);
    this.LicenceSevices.dayFilter$.subscribe((data: any) => {
      this.filterObj = data;
      if (data?.customDays) {
        this.period = data?.customDays;
        this.testPlanResults = this.dayResults = this.fetchTestPlanResults(this.period);
      }
      this.setFirstActiveResult();
    });
    await this.setFirstActiveResult();
  }

  fetchTestPlanResults(period: any) {
    let query;
    //let query = "entityType:TEST_PLAN,workspaceVersionId:" + this.version.id;
    if (period != FilterTimePeriod.All_TIME && period != 'CUSTOM') query += ",startTime>" + this.getFormattedDate(period);
    // if (this.filterObj?.createdDateRange?.valid) {
    //   query += ",startTime>" + moment.utc(this.filterObj?.createdDateRange.getRawValue().start).local().format("YYYY-MM-DD")
    //   query += ",startTime<" + moment.utc(this.filterObj?.createdDateRange.getRawValue().end).local().format("YYYY-MM-DD")
    // } else {
    //   if (period != FilterTimePeriod.All_TIME) query += ",startTime>" + this.getFormattedDate(period);
    // }
    // if (this.filterObj?.tags?.length)
    //   query += ",tagId@" + this.filterObj.tags.join("#")
    // query += ",startTime>" + this.getFormattedDate(period);
//    return new InfiniteScrollableDataSource(this.testPlanResultService, query, "id,desc");
    return new InfiniteScrollableDataSource(this.testCaseResultService, query, "id,desc");

  }

  filter(period: FilterTimePeriod) {
    this.period = period;
    const date = this.getFormattedDate(period);
    this.userPreferenceService.setDaysFilter({ period: this.period, date: date })
    switch (this.period) {
      case FilterTimePeriod.TODAY:
        this.testPlanResults = this.dayResults;
        break;
      case FilterTimePeriod.LAST_SEVEN_DAYS:
        this.testPlanResults = this.weekResults;
        break;
      case FilterTimePeriod.LAST_30_DAYS:
        this.testPlanResults = this.monthResults;
        break;
      case FilterTimePeriod.LAST_90_DAYS:
        this.testPlanResults = this.quarterResults;
        break;
    }
    this.setFirstActiveResult();
  }

  private getFormattedDate(key: FilterTimePeriod): String {
    switch (key) {
      case FilterTimePeriod.TODAY:
        return moment().format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_SEVEN_DAYS:
        return moment().subtract(7, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_30_DAYS:
        return moment().subtract(30, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_90_DAYS:
        return moment().subtract(90, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_180_DAYS:
        return moment().subtract(180, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.All_TIME:
        return null;
    }
  }

  private setFirstActiveResult(): void {
    if (this.testPlanResults.isFetching)
      setTimeout(() => this.setFirstActiveResult(), 500);
    else
      if (this.testPlanResults.isEmpty) this.environmentResults = null;
    this.activeExecutionResult = <TestPlanResult>this.testPlanResults['cachedItems'][0];
    // if (this.activeExecutionResult)
      // this.fetchEnvironmentResults();
  }

  private fetchEnvironmentResults(): void {
    this.environmentResults = null;
    this.environmentResultService.findAll("testPlanResultId:" + this.activeExecutionResult.id).subscribe(res => {
      this.environmentResults = res;
    });
    this.testCaseResultService.findAll(`iteration:null,testPlanResultId:${this.activeExecutionResult.id}`).subscribe((res: Page<TestCaseResult>) => {
      this.onBoardingSharedService.setLastRunId(res.content[0].id);
    });

  }

  public loadAiTestSuggestion(){
    console.log("loadAiTestSuggestion")
    this.testCaseResultService.testCaseAiSuggestions().subscribe({
      next: (data) => {
        console.log('Received data:', data.content);
        this.aiTestCaseSuggestions = data.content
        // Handle your data here
      },
      error: (error) => {
        console.error('Error in component:', error);
        // Handle error here
      }
    })
  }


  public setActiveResult(result: TestPlanResult) {
    this.activeExecutionResult = result;
    this.fetchEnvironmentResults();
    this.testCaseResultService.findAll(`iteration:null,testPlanResultId:${this.activeExecutionResult.id}`).subscribe((res: Page<TestCaseResult>) => {
      this.onBoardingSharedService.setLastRunId(res.content[0].id);
    });
    // this.router.navigate(['/td','runs',result.id])
  }

  /** Test case result redirect to the new page */
  onClickTestResultData(data:any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/run-history-view'], {
        queryParams: {
          testCaseId: data.testCase.id,
          runResultId: data.id,
          isManualExecution: data.isManualExecution
        }
      })
    );
    window.open(url, '_blank');
    // this.router.navigate(['run-history-view?testCaseId=', data.testCase.id, '&runResultId=', data.id,'&isManualExecution=false']);
  }

  previewTestCaseAISuggestion(suggestionData: any) {
    this.chatbotUiState.setTestCaseSuggestion(suggestionData);
    this.chatbotUiState.setOpened(true);
    // Set a small delay to ensure chatbot is opened before switching tab
    setTimeout(() => {
      this.chatbotUiState.setActiveTab('testcase');
    }, 100);
  }

  onToggleChatbot(open: boolean) {
    if (!this.isAssistantRoute) {
      this.chatbotUiState.setOpened(open);
    }
  }

  onSuggestionSelect(): void {
    // Update selectAll checkbox state based on individual selections
    this.selectAll = this.aiTestCaseSuggestions?.every(suggestion => suggestion.selected) ?? false;
  }

  approveSelected(): void {
    const selectedSuggestions = this.aiTestCaseSuggestions
      ?.filter(suggestion => suggestion.selected)
      ?.map(suggestion => suggestion.id);
    
    if (selectedSuggestions?.length) {
      this.testCaseResultService.approveTestCaseSuggestions(selectedSuggestions).subscribe({
        next: (res: any) => {
          this.notificationsService.success('Success', 'Selected suggestions approved successfully', {
            timeOut: 3000,         // Auto hide after 3 seconds
            showProgressBar: true,  // Show progress bar
            pauseOnHover: true,    // Pause timer on hover
            clickToClose: true,    // Click to close notification
            animate: 'fromRight'    // Animation style
          });
          this.loadAiTestSuggestion();
        },
        error: (error) => {
          this.notificationsService.error('Error', 'Failed to approve suggestions', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true,
            animate: 'fromRight'
          });
          console.error('Error approving suggestions:', error);
        }
      });
    }
  }

  get hasSelectedSuggestions(): boolean {
    return this.aiTestCaseSuggestions?.some(suggestion => suggestion.selected);
  }

  toggleSelectAll(event: any): void {
    const checked = event.checked;
    this.aiTestCaseSuggestions?.forEach(suggestion => {
      suggestion.selected = checked;
    });
  }
}
