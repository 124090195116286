import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';

declare const Plotly: any;

@Component({
  selector: 'app-insight-graph',
  template: `
    <div class="graph-container">
      <div *ngIf="!projectId || !urlStart" class="alert alert-info text-center my-3">
        We are not gettig a projectId or a screen to view the insights.
      </div>

      <div *ngIf="projectId && urlStart">
        <div class="d-flex justify-content-end mb-3">
          <button class="theme-btn-primary" (click)="refreshData()" [disabled]="isLoading">
            <i class="fas fa-sync" [class.fa-spin]="isLoading"></i>
            Refresh Data
          </button>
        </div>

        <div *ngIf="isLoading" class="d-flex justify-content-center my-5">
          <mat-spinner diameter="40"></mat-spinner>
        </div>

        <div *ngIf="errorMessage" class="alert alert-warning text-center my-3">
          {{ errorMessage }}
        </div>

        <div class="charts-container" *ngIf="!errorMessage">
          <div *ngFor="let chart of sankeyCharts; let i = index" class="chart-wrapper mb-4">
            <h3 class="chart-title">Path {{i + 1}}</h3>
            <div [id]="'sankeyPlot' + i" class="sankey-plot"></div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .graph-container {
      padding: 20px;
    }
    .chart-wrapper {
      margin-bottom: 2rem;
    }
    .chart-title {
      font-size: 1.2rem;
      margin-bottom: 1rem;
      color: #333;
    }
    .sankey-plot {
      width: 100%;
      height: 500px;
      display: block;
    }
    .alert {
      padding: 1rem;
      border-radius: 4px;
    }
    .alert-warning {
      background-color: #fff3cd;
      border: 1px solid #ffeeba;
      color: #856404;
    }
    .alert-info {
      background-color: #cce5ff;
      border: 1px solid #b8daff;
      color: #004085;
    }
  `]
})
export class InsightGraphComponent implements OnInit, OnChanges {
  @Input() projectId: string = '';
  @Input() urlStart: string = '';

  isLoading = false;
  sankeyCharts: any[] = [];
  errorMessage: string = '';

  config = {
    displayModeBar: true,
    responsive: true
  };

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.loadGraphData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['projectId'] && !changes['projectId'].firstChange) ||
      (changes['urlStart'] && !changes['urlStart'].firstChange)) {
      this.loadGraphData();
    }
  }

  refreshData() {
    this.loadGraphData();
  }

  public getChartTitle(chart: any): string {
    try {
      return chart.layout.title.text || 'User Flow';
    } catch (e) {
      return 'User Flow';
    }
  }

  private renderCharts() {
    this.sankeyCharts.forEach((chart, index) => {
      const layout = {
        ...chart.layout,
        font: { size: 10 },
        height: 500,
        margin: { t: 25, l: 50, r: 50, b: 25 },
        autosize: true
      };

      Plotly.newPlot(
        `sankeyPlot${index}`,
        chart.data,
        layout,
        this.config
      );
    });
  }

  private loadGraphData() {
    this.isLoading = true;
    this.errorMessage = '';
    this.http.get('https://web-finder.contextqa.com/api/posthog/cqa-sankey', {
      params: {
        projectId: this.projectId,
        urlStart: encodeURI(this.urlStart)
      }
    }).subscribe({
      next: (response: any) => {
        if (response && response.sankey_charts_json) {
          try {
            this.sankeyCharts = response.sankey_charts_json.map(json => JSON.parse(json));
            setTimeout(() => this.renderCharts(), 0);
          } catch (error) {
            this.errorMessage = 'Error parsing chart data. Please try again later.';
            console.error('Error parsing chart data:', error);
          }
        }
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        if (error.status === 404) {
          this.errorMessage = 'No critical paths found for the selected criteria.';
        } else {
          this.errorMessage = 'Error loading graph data. Please try again later.';
        }
        console.error('Error loading graph data:', error);
      }
    });
  }
}
