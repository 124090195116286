import { Component } from '@angular/core';
import { AuthenticationGuard } from "./shared/guards/authentication.guard";
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserPreference } from "./models/user-preference.model";
import { UserPreferenceService } from "./services/user-preference.service";
import { UrlConstantsService } from './shared/services/url.constants.service';
import { MixpanelService } from './mixpanel.service';  // Import the Mixpanel service
import { ChatbotUiStateService } from './services/chatbot-ui-state.service';
import { LicenceSevices } from './shared/services/license.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'cqa-portal';
  public userPreference: UserPreference;
  previousUrl: string = null;
  currentUrl: string = null;
  isChatbotOpened = false;
  isAssistantRoute: boolean = false;
  public lockData: any;

  constructor(
    public authGuard: AuthenticationGuard,
    public titleService: Title,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    public userPreferenceService: UserPreferenceService,
    public UrlConstantsService: UrlConstantsService,
    private mixpanelService: MixpanelService,
    private chatbotUiState: ChatbotUiStateService,
    public LicenceSevices: LicenceSevices
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isAssistantRoute = event.url.includes('/assistant');

      if (event.url !== '/assistant' && this.isAssistantRoute) {
        this.isChatbotOpened = true;
      }
    });
  }

  get isOnboardingRoute(): boolean {
    return this.router.url.includes('/onboarding') ||
      this.router.url.includes('/login') ||
      this.router.url.includes('showTelemetryNotification') ||
      this.router.url.includes('run-history-view');
  }

  ngOnInit() {
    // const url = window.location.host;
    // const parts = url.split(".");
    // const extractedString = parts ? parts[0] : null;
    // const orgListForAWS = ['clari', 'p1818','vendidit']
    // if(orgListForAWS.includes(extractedString)){
    //   this.UrlConstantsService.isGCP = true;
    // }
    // this.UrlConstantsService.apiBase1 = this.UrlConstantsService.isGCP ?  this.UrlConstantsService.CQA_LAB_SERVERurl :
    //                                                                       this.UrlConstantsService.CQA_LAB_SERVERurl_1 ;
    // this.UrlConstantsService.reasignVariable();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Track the page view
        this.mixpanelService.track('Page View', {
          page: event.urlAfterRedirects,
        });
      }
    });

    // if (window.Produktly) {
    //   const email = localStorage.getItem('useremail') || '';
    //   window.Produktly.identifyUser(email, {
    //     org: "startup"
    //   });
    // }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.getChild(this.activatedRoute).data.subscribe(data => {
        if (data.title) {
          this.translate.get(data.title).subscribe(pageName => {
            this.translate.get("page_title", { pageTitle: pageName }).subscribe(res => {
              let title: string = res;
              this.titleService.setTitle(title);
            });
          });
        }
      });
    });
    this.fetchUserPreference()

    // Subscribe to chatbot state changes
    this.chatbotUiState.isOpened$.subscribe(
      isOpened => this.isChatbotOpened = isOpened
    );
    this.FetchLicence();
  }

  FetchLicence() {
    this.LicenceSevices.getAll().subscribe(data => {
      this.lockData = data;
      this.LicenceSevices.setLockData(data);
    })
  }

  fetchUserPreference() {
    if (this.authGuard?.session?.user?.id) {
      this.userPreferenceService.show().subscribe(res => {
        this.userPreference = res;
      })
    } else {
      setTimeout(() => {
        this.fetchUserPreference()
      }, 200)
    }
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute && activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
  onToggleChatbot(open: boolean) {
    if (!this.isAssistantRoute) {
      this.chatbotUiState.setOpened(open);
    }
  }
}
