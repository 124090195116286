import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export interface AiPrompt {
    id: string;
    prompt: string;
    title: string;
    updated_at: string;
    flag: boolean;
}

export interface ApiResponse {
    response: {
        default: string;  // ID of the default/active prompt
        prompts: Array<{
            id: string;
            prompt: string;
            title: string;
            updated_at: string;
        }>;
    };
    success: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AiPromptsService {
    private apiUrl = 'https://web-finder.contextqa.com/agent/custom_prompt';


    constructor(private http: HttpClient) { }

    analyzeVideo(videoUrl: string): Observable<any> {
        console.log('Analyzing video:', videoUrl);
        return this.http.post(this.apiUrl, { url: videoUrl }).pipe(
            catchError(this.handleError)
        );
    }

    getPrompts(): Observable<ApiResponse> {
        const headers = new HttpHeaders({
            'origin': 'https://gmdemo.contextqa.com'
        });

        return this.http.get<ApiResponse>(this.apiUrl, { headers }).pipe(
            tap(response => console.log('Raw API Response:', response)),
            catchError(this.handleError)
        );
    }

    createPrompt(title: string, prompt: string) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            // 'origin': 'https://gmdemo.contextqa.com'
        });

        const body = {
            prompt: prompt,
            title: title
        };

        return this.http.post(this.apiUrl, body, { headers });
    }

    deletePrompt(id: string) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            // 'origin': 'https://gmdemo.contextqa.com'
        });

        const body = { id };

        return this.http.delete(this.apiUrl, { 
            headers, 
            body  // For DELETE requests, we need to pass the body this way
        });
    }

    updatePrompt(id: string, title: string, prompt: string) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            // 'origin': 'https://gmdemo.contextqa.com'
        });

        const body = {
            id,
            prompt,
            title
        };

        return this.http.post(this.apiUrl, body, { headers });
    }

    updatePromptStatus(promptId: string, flag: boolean): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'origin': 'https://vims.contextqa.com'
        });

        const body = {
            id: promptId,
            flag: flag
        };

        // If activating, call the default endpoint
        if (flag) {
            return this.http.post(`${this.apiUrl}/default`, body, { headers });
        }
        
        // If deactivating, call the regular update endpoint
        return this.http.post(`${this.apiUrl}/default`, { id: promptId, flag }, { headers });
    }

    private handleError(error: any) {
        console.error('API Error:', error);
        if (error.error instanceof ErrorEvent) {
            console.error('Client-side error:', error.error.message);
        } else {
            console.error(`Server-side error: ${error.status} ${error.statusText}`);
            console.error('Error body:', error.error);
        }
        return throwError(() => new Error('Something went wrong; please try again later.'));
    }
}