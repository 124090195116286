import { Component, Input, OnInit, Inject, AfterViewInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConversionService } from "./conversion.service";
import { NotificationType } from 'angular2-notifications';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr';

interface Step {
    step: string;
}

@Component({
    selector: "app-conversion",
    template: `
    <div [class]="customClass">
        <div class="dialog-content" [class.loading]="isLoading">
            <div *ngIf="isLoading" class="loading-overlay">
                <div class="loading-spinner"></div>
                <p class="loading-text">Analyzing task...</p>
            </div>
            <form #conversionForm="ngForm" [class.blur]="isLoading">
                <h2 class="dialog-title">Testability Assessment</h2>
                <div class="form-sections">
                    <div class="form-card">
                        <div class="form-group">
                            <label for="refinedTask">
                                <span class="label-text">Refined Task</span>
                            </label>
                            <textarea 
                                class="form-control"
                                name="refinedTask" 
                                id="refinedTask" 
                                [(ngModel)]="refinedTask"
                                placeholder="Enter refined task">
                            </textarea>
                        </div>
                    </div>

                    <div class="form-card">
                        <div class="form-group">
                            <label for="acceptanceCriteria">
                                <span class="label-text">Acceptance Criteria</span>
                            </label>
                            <textarea 
                                class="form-control"
                                name="acceptanceCriteria" 
                                id="acceptanceCriteria" 
                                [(ngModel)]="acceptanceCriteria"
                                placeholder="Enter acceptance criteria">
                            </textarea>
                        </div>
                    </div>

                    <div class="form-card">
                        <div class="form-group">
                            <label for="prerequisites">
                                <span class="label-text">Prerequisites</span>                            </label>
                            <textarea 
                                class="form-control"
                                name="prerequisites" 
                                id="prerequisites" 
                                [(ngModel)]="prerequisites"
                                placeholder="Enter prerequisites">
                            </textarea>
                        </div>
                    </div>

                    <div class="form-card">
                        <div class="form-group">
                            <label for="missingInformation">
                                <span style="color: red;" class="label-text">Missing Information</span>
                            </label>
                            <textarea 
                                style="border-color: red;"
                                class="form-control"
                                name="missingInformation" 
                                id="missingInformation" 
                                [(ngModel)]="missingInformation"
                                placeholder="Enter missing information">
                            </textarea>
                        </div>
                    </div>

                    <div class="form-card steps-section">
                        <div class="form-group">
                            <label for="steps">
                                <span class="label-text">Steps</span>
                            </label>
                            <div class="steps-container">
                                <div class="steps-header">
                                    <h3 class="steps-title">Implementation Steps</h3>
                                    <span class="steps-counter">{{steps?.length || 0}} step(s)</span>
                                </div>
                                <ul class="steps-list">
                                    <li *ngFor="let step of steps || []; let i = index" class="step-item">
                                        <div class="step-input-group">
                                            <div class="step-number">{{i + 1}}</div>
                                            <div class="step-input-wrapper">
                                                <input 
                                                    class="form-control step-input"
                                                    type="text" 
                                                    [(ngModel)]="steps[i].step" 
                                                    [name]="'step' + i"
                                                    placeholder="Enter step description">
                                                <button type="button" 
                                                    class="remove-step" 
                                                    (click)="removeStep(i)" 
                                                    *ngIf="steps?.length > 1">×</button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <button type="button" class="btn-add-step" (click)="addStep()">
                                    <mat-icon>add</mat-icon>
                                    Add Step
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="form-card steps-section" style="margin-bottom: 30%;">
                        <div class="form-group">
                            <label for="testData">
                                <span class="label-text">Test Data</span>
                            </label>
                            <div class="test-data-container">
                                <div class="test-data-header">
                                    <h3 class="test-data-title">Test Data Parameters</h3>
                                    <span class="test-data-counter">{{getTestDataLength()}} parameter(s)</span>
                                </div>
                                <div class="test-data-list">
                                    <div *ngFor="let key of getTestDataKeys(); let i = index" class="test-data-item">
                                        <div class="test-data-input-group">
                                            <input 
                                                class="form-control test-data-input"
                                                type="text" 
                                                [value]="key"
                                                [name]="'testDataKey' + i"
                                                (input)="updateTestDataKey($event, key)"
                                                placeholder="Key">
                                            <input 
                                                class="form-control test-data-input"
                                                type="text" 
                                                [(ngModel)]="testData[key]" 
                                                [name]="'testDataValue' + i"
                                                placeholder="Value">
                                            <button type="button" 
                                                class="remove-test-data" 
                                                (click)="removeTestData(key)" 
                                                *ngIf="getTestDataLength() > 1">×</button>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" class="btn-add-test-data" (click)="addTestData()">
                                    <mat-icon>add</mat-icon>
                                    Add Test Data
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-actions">
                    <button type="button" class="btn-cancel" (click)="closeDialog()">
                        Cancel
                    </button>
                    <button type="button" class="btn-submit" (click)="onSubmit()">
                        Save Changes
                    </button>
                </div>
            </form>
        </div>
    </div>
    `,
    styles: [`
        :host {
            display: block;
            padding: 24px;
            background: linear-gradient(to bottom right, #f8f9fa, #ffffff);
        }
        .dialog-content {
            max-width: 800px;
            margin: 0 auto;
            background: #fff;
            border-radius: 12px;
            box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
            padding: 32px;
            position: relative;
            transition: all 0.3s ease;
        }
        .dialog-title {
            margin: 0 0 32px;
            color: #6366f1;
            font-size: 28px;
            font-weight: 600;
            text-align: center;
            position: relative;
            animation: slideDown 0.5s ease;
        }
        .dialog-title::after {
            content: '';
            display: block;
            width: 60px;
            height: 3px;
            background: linear-gradient(to right, #6366f1,rgb(73, 76, 235));
            margin: 12px auto 0;
            border-radius: 2px;
            animation: expandWidth 0.5s ease;
        }
        .form-sections {
            display: grid;
            gap: 24px;
            max-height: calc(100vh - 200px);
            overflow-y: auto;
            padding-right: 16px;
            margin-right: -16px;
        }
        .form-card {
            background: #ffffff;
            border-radius: 16px;
            padding: 24px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
            transition: all 0.3s ease;
            border: 1px solid rgba(0, 0, 0, 0.05);
            animation: fadeInUp 0.5s ease;
            animation-fill-mode: both;
        }
        .form-card:nth-child(1) { animation-delay: 0.1s; }
        .form-card:nth-child(2) { animation-delay: 0.2s; }
        .form-card:nth-child(3) { animation-delay: 0.3s; }
        .form-card:nth-child(4) { animation-delay: 0.4s; }
        .form-card:nth-child(5) { animation-delay: 0.5s; }
        .form-card:hover {
            transform: translateY(-2px);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
        }
        .form-group {
            margin: 0;
            transition: transform 0.2s ease;
        }
        .form-group:hover {
            transform: translateY(-2px);
        }
        .form-group label {
            display: block;
            margin-bottom: 10px;
            font-weight: 600;
            color: #202124;
            font-size: 15px;
            letter-spacing: 0.3px;
        }
        .form-control {
            width: 100%;
            padding: 14px;
            border: 2px solid #e8eaed;
            border-radius: 10px;
            font-size: 14px;
            transition: all 0.3s ease;
            background: #ffffff;
            color: #202124;
            box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.02);
        }
        .form-control:focus {
            outline: none;
            border-color: #6366f1;
            background: #fff;
            box-shadow: 0 0 0 4px rgba(26, 115, 232, 0.1),
                        inset 0 2px 4px rgba(0, 0, 0, 0.02);
        }
        .form-control::placeholder {
            color: #80868b;
        }
        textarea.form-control {
            min-height: 120px;
            resize: vertical;
            line-height: 1.6;
        }
        .steps-container {
            background: #f8f9fa;
            border-radius: 16px;
            padding: 24px;
            margin-top: 16px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            width: 100%;
            min-height: 200px;
            display: flex;
            flex-direction: column;
        }
        .steps-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
        }
        .steps-title {
            font-size: 18px;
            font-weight: 600;
            color: #6366f1;
            margin: 0;
        }
        .steps-counter {
            background: #e8f0fe;
            color: #6366f1;
            padding: 4px 12px;
            border-radius: 20px;
            font-size: 14px;
            font-weight: 500;
        }
        .steps-list {
            list-style: none;
            padding: 0;
            margin: 0 0 24px 0;
            width: 100%;
            flex: 1;
        }
        .step-item {
            margin-bottom: 0;
            width: 100%;
        }
        .step-input-group {
            display: flex;
            align-items: center;
            gap: 12px;
            background: #ffffff;
            padding: 12px;
            border-radius: 12px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
            margin-bottom: 12px;
            width: 100%;
        }
        .step-number {
            min-width: 32px;
            height: 32px;
            background: linear-gradient(135deg, #6366f1,rgb(73, 75, 238));
            color: white;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 600;
        }
        .step-input-wrapper {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;
        }
        .step-input {
            width: 100%;
            min-height: 40px;
            padding: 8px 12px;
        }
        .step-input:focus {
            outline: none;
        }
        .remove-step {
            background: #fff0f0;
            border-radius: 6px;
            color: #d93025;
            font-size: 18px;
            line-height: 1;
            font-weight: bold;
            border: none;
        }
        .remove-step:hover {
            background: #ffe5e5;
            color: #c5221f;
        }
        .btn-add-step {
            width: 100%;
            background: #f4f8fe;
            border: 2px dashed #6366f1;
            padding: 16px;
            font-size: 15px;
            font-weight: 500;
            border-radius: 12px;
            color: #6366f1;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            cursor: pointer;
            transition: all 0.3s ease;
        }
        .btn-add-step:hover {
            background: #e8f1fe;
            border-color: #1557b0;
        }
        .form-actions {
            margin-top: 40px;
            display: flex;
            gap: 20px;
            justify-content: flex-end;
            padding-top: 20px;
            border-top: 2px solid #f4f8fe;
            animation: slideUp 0.5s ease;
            animation-delay: 0.6s;
            animation-fill-mode: both;
            position: sticky;
            bottom: 0;
            background: #fff;
            z-index: 100;
            padding: 20px 0;
        }
        .btn-submit, .btn-cancel {
            display: inline-flex;
            align-items: center;
            gap: 8px;
            padding: 9px 13px;
            font-size: 13px;
        }
        .btn-submit .material-icons,
        .btn-cancel .material-icons {
            font-size: 18px;
        }
        .btn-submit {
            border-radius: 5px;
            background: linear-gradient(135deg, #6366f1,rgb(67, 69, 237));
            color: white;
            box-shadow: 0 4px 12px rgba(26, 115, 232, 0.2);
        }
        .btn-submit:hover {
            background: linear-gradient(135deg, #6366f1,rgb(63, 66, 240));
            transform: translateY(-2px);
            box-shadow: 0 6px 16px rgba(26, 115, 232, 0.3);
        }
        .btn-cancel {
            border-radius: 5px;
            background: #f8f9fa;
            color: #202124;
            border: 2px solid #e8eaed;
        }
        .btn-cancel:hover {
            background: #e8eaed;
            transform: translateY(-2px);
        }
        .material-icons {
            font-size: 20px;
            line-height: 1;
        }
        @keyframes fadeIn {
            from {
                opacity: 0;
                transform: translateY(10px);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
        .loading-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(255, 255, 255, 0.9);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 10;
            border-radius: 12px;
            backdrop-filter: blur(4px);
        }
        .loading-spinner {
            width: 48px;
            height: 48px;
            border: 4px solid #e8f0fe;
            border-top: 4px solid #6366f1;
            border-radius: 50%;
            animation: spin 1s linear infinite, scaleUp 0.3s ease;
        }
        .loading-text {
            margin-top: 16px;
            color: #6366f1;
            font-weight: 500;
            font-size: 16px;
            animation: pulse 1.5s ease-in-out infinite;
        }
        .blur {
            filter: blur(2px);
        }
        .form-control:disabled {
            background: #f8f9fa;
            cursor: not-allowed;
            opacity: 0.7;
        }
        .form-group:focus-within label {
            color: #6366f1;
            transform: translateY(-2px);
            transition: all 0.2s ease;
        }
        .form-control:not(:placeholder-shown) {
            background: #fff;
            border-color: #6366f1;
        }
        .form-sections::after {
            content: '';
            position: absolute;
            bottom: -20px;
            left: 0;
            right: 0;
            height: 40px;
            background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.8));
            pointer-events: none;
        }
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
        @keyframes pulse {
            0%, 100% { opacity: 1; }
            50% { opacity: 0.5; }
        }
        .label-text {
            display: inline-flex;
            align-items: center;
            gap: 8px;
        }
        .label-icon {
            color: #6366f1;
            font-size: 20px;
            transition: all 0.3s ease;
        }
        .form-sections::-webkit-scrollbar {
            width: 8px;
        }
        .form-sections::-webkit-scrollbar-track {
            background: #f1f3f4;
            border-radius: 4px;
        }
        .form-sections::-webkit-scrollbar-thumb {
            background: #dadce0;
            border-radius: 4px;
        }
        .form-sections::-webkit-scrollbar-thumb:hover {
            background: #bdc1c6;
        }
        @media (max-width: 768px) {
            :host {
                padding: 16px;
            }
            .dialog-content {
                padding: 24px;
            }
            .form-card {
                padding: 20px;
            }
            .steps-container {
                padding: 16px;
            }
            .step-number {
                width: 28px;
                height: 28px;
                font-size: 12px;
            }
        }
        @keyframes slideDown {
            from {
                transform: translateY(-20px);
                opacity: 0;
            }
            to {
                transform: translateY(0);
                opacity: 1;
            }
        }
        @keyframes expandWidth {
            from {
                width: 0;
                opacity: 0;
            }
            to {
                width: 60px;
                opacity: 1;
            }
        }
        @keyframes fadeInUp {
            from {
                transform: translateY(20px);
                opacity: 0;
            }
            to {
                transform: translateY(0);
                opacity: 1;
            }
        }
        @keyframes scaleUp {
            from {
                transform: scale(0.8) rotate(0deg);
                opacity: 0;
            }
            to {
                transform: scale(1) rotate(360deg);
                opacity: 1;
            }
        }
        @keyframes slideUp {
            from {
                transform: translateY(20px);
                opacity: 0;
            }
            to {
                transform: translateY(0);
                opacity: 1;
            }
        }
        .form-group:focus-within .label-icon {
            transform: scale(1.1);
            color: #1557b0;
        }
        .test-data-container {
            background: #f8f9fa;
            border-radius: 16px;
            padding: 24px;
            margin-top: 16px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            width: 100%;
        }

        .test-data-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
        }

        .test-data-title {
            font-size: 18px;
            font-weight: 600;
            color: #6366f1;
            margin: 0;
        }

        .test-data-counter {
            background: #e8f0fe;
            color: #6366f1;
            padding: 4px 12px;
            border-radius: 20px;
            font-size: 14px;
            font-weight: 500;
        }

        .test-data-item {
            margin-bottom: 12px;
        }

        .test-data-input-group {
            display: flex;
            gap: 12px;
            align-items: center;
        }

        .test-data-input {
            flex: 1;
            min-height: 40px;
        }

        .remove-test-data {
            background: #fff0f0;
            border-radius: 6px;
            color: #d93025;
            font-size: 18px;
            line-height: 1;
            font-weight: bold;
            border: none;
            padding: 8px 12px;
            cursor: pointer;
        }

        .btn-add-test-data {
            width: 100%;
            background: #f4f8fe;
            border: 2px dashed #6366f1;
            padding: 16px;
            font-size: 15px;
            font-weight: 500;
            border-radius: 12px;
            color: #6366f1;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            cursor: pointer;
            margin-top: 16px;
            transition: all 0.3s ease;
        }

        .btn-add-test-data:hover {
            background: #e8f1fe;
            border-color: #1557b0;
        }
    `]
})
export class ConversionComponent implements OnInit, AfterViewInit {
    @Input() label: string = "";
    @Input() customClass: string = "";
    description: string = "";
    enhancedDescription: string = "";
    isLoading: boolean = false;
    acceptanceCriteria: string = "";
    missingInformation: string = "";
    testData: { [key: string]: string } = {};
    prerequisites: string = "";
    refinedTask: string = "";
    steps: Step[] = [{ step: '' }];

    constructor(
        private dialogRef: MatDialogRef<ConversionComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private conversionService: ConversionService,
        private toastrService: ToastrService,
    ) {
        this.label = data?.label || "";
        this.customClass = data?.customClass || "";
    }

    ngOnInit() {
        // Initialize component
        this.description = this.data?.description || "";
        
        // Initialize test data with predefined key-value pairs
        this.testData = {
            "invalid_password": "wrongpassword",
            "invalid_username": "wronguser",
            "valid_password": "password123",
            "valid_username": "testuser"
        };
        
        this.getData();
        console.log("isLoading ::", this.isLoading)
        if (!this.steps || !this.steps.length) {
            this.steps = [{ step: '' }];
        }
    }

    getData() {
        this.isLoading = true;
        console.log('Loading state set to:', this.isLoading);
        
        this.conversionService.enhanceDescription(this.description).subscribe({
            next: (response) => {
                console.log("response ::", response.enhanced_task);
                // Update all form fields with response data
                this.acceptanceCriteria = response.enhanced_task.acceptance_criteria || '';
                this.missingInformation = response.enhanced_task.missing_information || '';
                this.prerequisites = response.enhanced_task.prerequisites || '';
                this.refinedTask = response.enhanced_task.refined_task || '';
                
                // Transform the steps array to match the required format
                if (Array.isArray(response.enhanced_task.steps)) {
                    this.steps = response.enhanced_task.steps.map(stepObj => {
                        // Each stepObj is like { "1": "step description" }
                        const stepNumber = Object.keys(stepObj)[0];
                        return { step: stepObj[stepNumber] };
                    });
                } else {
                    // Fallback if no steps provided
                    this.steps = [{ step: '' }];
                }

                this.enhancedDescription = response.enhanced_task;
                this.testData = response.enhanced_task.test_data;
                this.isLoading = false;
            },
            error: (error) => {
                console.error('Error enhancing description:', error);
                this.isLoading = false;
                console.log('Loading error. isLoading:', this.isLoading);
            }
        });
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    addStep() {
        this.steps.push({ step: '' });
    }

    removeStep(index: number) {
        this.steps.splice(index, 1);
    }
  
    onSubmit() {
        const formData = {
            acceptanceCriteria: this.acceptanceCriteria,
            missingInformation: this.missingInformation,
            prerequisites: this.prerequisites,
            refinedTask: this.refinedTask,
            steps: this.steps,
            testData: this.testData,
            enhancedDescription: this.enhancedDescription
        };
        
        try {
            this.dialogRef.close(formData);
        } catch (error) {
            console.error('Error closing dialog:', error);
            this.showNotification(
                NotificationType.Error,
                'Error saving changes. Please try again.'
            );
        }
    }

    ngAfterViewInit() {
        // Add any after view init logic here if needed
    }

    moveStep(from: number, to: number) {
        if (from === to) return;
        const step = this.steps[from];
        this.steps.splice(from, 1);
        this.steps.splice(to, 0, step);
    }

    showNotification(type: NotificationType, message: string, clickToClose?: boolean) {
        const temp: Partial<IndividualConfig> = {
            toastClass: type,
            messageClass: message,
            titleClass: status,
            timeOut: 7000,
            positionClass: 'toast-bottom-left',
            progressBar: true,
            progressAnimation: 'increasing' as const,
            enableHtml: true
        };
        if (this.toastrService) {
            this.toastrService.show(message, status, temp);
        }
    }

    showAPIError(error: HttpErrorResponse, message: string) {
        this.showNotification(NotificationType.Error, message);
        console.error('API Error:', error);
    }

    getTestDataKeys(): string[] {
        return Object.keys(this.testData);
    }

    getTestDataLength(): number {
        return Object.keys(this.testData).length;
    }

    addTestData() {
        const newKey = `key${this.getTestDataLength() + 1}`;
        this.testData[newKey] = '';
    }

    removeTestData(key: string) {
        delete this.testData[key];
    }

    updateTestDataKey(event: any, oldKey: string) {
        const newKey = event.target.value;
        if (newKey && newKey !== oldKey) {
            const value = this.testData[oldKey];
            delete this.testData[oldKey];
            this.testData[newKey] = value;
        }
    }
} 