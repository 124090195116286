import {Component, OnInit} from '@angular/core';
import {Page} from "../../shared/models/page";
import {Pageable} from "../../shared/models/pageable";
import {AuthenticationGuard} from "../../shared/guards/authentication.guard";
import {BaseComponent} from "../../shared/components/base.component";
import {NotificationsService} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TestPlanService} from "../../services/test-plan.service";
import {TestPlan} from "../../models/test-plan.model";
import {TestDeviceService} from "../../services/test-device.service";
import {TestPlanType} from "../../enums/execution-type.enum";
import {ToastrService} from "ngx-toastr";
import {FilterFormComponent} from "../plans/filter-form.component";
import {MatDialog} from "@angular/material/dialog";
import {WorkspaceVersionService} from "../../shared/services/workspace-version.service";
import {WorkspaceVersion} from "../../models/workspace-version.model";
import { LicenceSevices } from 'app/shared/services/license.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  host: {'class': 'page-content-container'},
  styles: []
})
export class TestPlanListComponent extends BaseComponent implements OnInit {
  public testPlans: Page<TestPlan>;
  public currentPage: Pageable = new Pageable();
  public versionId: number;
  public searchQuery = "";
  public isFiltered: boolean;
  public fetchingCompleted: Boolean = false;
  public isSearchEnable: boolean;
  public sortByColumns = ["name", "isManual", "createdDate", "updatedDate","lastRun"];
  public sortedBy: string = 'name';
  public direction: string = ",asc";
  public query: string;
  scheduler: any;

  constructor(
    public authGuard: AuthenticationGuard,
    public notificationsService: NotificationsService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    public route: ActivatedRoute,
    private testPlanService: TestPlanService,
    private router: Router,
    public matModal: MatDialog,
    private testDeviceService: TestDeviceService,
    public LicenceSevices: LicenceSevices
  ) {
    super(authGuard, notificationsService, translate, toastrService);
  }

  ngOnInit(): void {
    this.LicenceSevices.lockData$.subscribe(data => {
      if (data) {
        this.scheduler = data.featureSupport.scheduler;
      }
    });
    this.route.parent.parent.params.subscribe((params: Params) => {
      this.versionId = params.versionId;
      this.route.params.subscribe((params) => {
        const allParams = {...params, ...{versionId: this.versionId}};
        this.pushToParent(this.route, allParams);
        this.refreshListView(this.route.snapshot.queryParamMap?.['params']?.['q']);
        this.fetchTestPlans(this.route.snapshot.queryParamMap?.['params']?.['q']);
      });
      this.route.queryParams.subscribe((params)=>{
        this.refreshListView(params?.['q']);
        this.fetchTestPlans(params?.['q']);
      });
    });
  }
  refreshListView(query) {
    this.query = query;
  }

  getCrossBrowser(){
    return TestPlanType.CROSS_BROWSER
  }

  fetchTestPlans(search?: string, pageable?: Pageable) {
    this.fetchingCompleted = false;
    let query = "workspaceVersionId:" + this.versionId + (search ? search : '');
    this.testPlanService.findAll(query, this.sortedBy + this.direction, pageable || this.currentPage)
      .subscribe(res => {
        this.testPlans = res;
        this.currentPage = res.pageable;
        if(this.testPlans?.content?.length)
          this.fetchExecutionEnvironments();
        else
          this.fetchingCompleted = true;
      });
  }

  sortBy(value, direction) {
    if (!(this.sortedBy != value || this.direction != direction))
      return;
    this.direction = direction;
    this.sortedBy = value;
    this.fetchTestPlans(this.searchQuery);
  }


  fetchExecutionEnvironments() {
    let pageable = new Pageable();
    pageable.pageSize = 200;
    let query = "testPlanId@" + this.testPlans.content.map((exe) => exe.id).join("#");
    this.testDeviceService.findAll(query, undefined, pageable).subscribe((environments) => {
      this.testPlans.content.forEach((exe) => {
        let filteredEnvs = environments.content.filter((exeEnv) => exeEnv.testPlanId === exe.id);
        if (filteredEnvs)
          exe.testDevices = filteredEnvs;
      });
      this.fetchingCompleted = true;
    })
  }


  search(term: string) {
    if (term) {
      this.isFiltered = true;
      this.searchQuery = ",name:*" + term + "*";
    } else {
      this.isFiltered = false;
      this.searchQuery = "";
    }
    this.fetchTestPlans(this.searchQuery)
  }
  openFilter() {

    let filterDialogRef = this.matModal.open(FilterFormComponent, {
      width: '25%',
      height: '100vh',
      position: {top: '0', right: '0', bottom: '0'},
      panelClass: ['mat-overlay'],
      data: {query: this.query}
    });
    filterDialogRef.componentInstance.filterEvent.subscribe(query => {
      if (query) {
        this.query = query;
        this.router.navigate(['/td', this.versionId, 'plans'], {queryParams: {q: this.query}});
        this.fetchTestPlans(this.query);
      } else
        this.discard();
    });
  }

  discard() {
    this.query = undefined;
    this.router.navigate(['/td', this.versionId, 'plans']);
    this.fetchTestPlans(this.query);
  }
}
