import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { InfiniteScrollableDataSource } from "../../data-sources/infinite-scrollable-data-source";
import { AuthenticationGuard } from "../../shared/guards/authentication.guard";
import { NotificationsService, NotificationType } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { BaseComponent } from "../../shared/components/base.component";
import { ElementService } from "../../shared/services/element.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { TooltipPosition } from "@angular/material/tooltip";
import { WorkspaceVersionService } from "../../shared/services/workspace-version.service";
import { WorkspaceVersion } from "../../models/workspace-version.model";
import { ElementFormComponent } from "../webcomponents/element-form.component";
import { ElementFilterService } from "../../services/element-filter.service";
import { Page } from "../../shared/models/page";
import { ElementFilter } from "../../models/element-filter.model";
import { FilterableInfiniteDataSource } from "../../data-sources/filterable-infinite-data-source";
import { ConfirmationModalComponent } from "../../shared/components/webcomponents/confirmation-modal.component";
import { AgentService } from "../../agents/services/agent.service";

import { Element } from "../../models/element.model";
import { AgentInfo } from "../../agents/models/agent-info.model";
import { ElementFiltersListComponent } from "../webcomponents/element-filters-list.component";
import { ElementFiltersComponent } from "../webcomponents/element-filters.component";
import { ElementFilterFormComponent } from "../webcomponents/element-filter-form.component";
import { ElementAddTagComponent } from "../webcomponents/element-add-tag.component";
import { ElementLocatorType } from "../../enums/element-locator-type.enum";
import { DetailsHeaderComponent } from "./details-header.component";
import { TestCaseService } from "../../services/test-case.service";
import { LinkedEntitiesModalComponent } from "../../shared/components/webcomponents/linked-entities-modal.component";
// import {LinkedEntitieModalComponent} from "../../shared/components/webcomponents/linked-entitie-modal.component";
import { ElementBulkUpdateComponent } from "../webcomponents/element-bulk-update.component";
import { MobileRecordingComponent } from "../../agents/components/webcomponents/mobile-recording.component";
import { MirroringData } from "../../agents/models/mirroring-data.model";
import * as moment from "moment";
import { ChromeRecorderService } from "../../services/chrome-recoder.service";
import { ElementStatus } from "../../enums/element-status.enum";
import { ToastrService } from "ngx-toastr";
import { ElementScreenNameService } from "app/shared/services/element-screen-name.service";
import { ElementScreenName } from "app/models/element-screen-name.model";
import { Observable, of } from "rxjs";
import { TestPlan } from "app/models/test-plan.model";
import { TestSuite } from "app/models/test-suite.model";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ChromeExtensionService } from '../../services/chrome.service';
import { HttpClient } from "@angular/common/http";
import { IntegrationsService } from "app/shared/services/integrations.service";
import { Integrations } from "app/shared/models/integrations.model";
import { LicenceSevices } from '../../shared/services/license.service';

declare const Plotly: any;

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  host: { class: "page-content-container" },
  styleUrls: ["./list.component.scss"],
})
export class ElementsListComponent extends BaseComponent implements OnInit {
  @ViewChild("filterListBtn") public filterListBtn: ElementRef;
  public elements: InfiniteScrollableDataSource;
  public versionId: number;
  selectedTabIndex = 0;
  public version: WorkspaceVersion;
  public filters: Page<ElementFilter>;
  public filterId: number;
  public currentFilter: ElementFilter;
  public elementCapture: Boolean;
  isExtensionInstalled: boolean;
  private extensionId = 'pgadpooodaipbhbchnojijmlkhpamadh';
  checkExtensionInstall: boolean = true;
  public agentInstalled: Boolean = false;
  public filterDialogRef: MatDialogRef<ElementFiltersComponent, any>;
  public filterListDialogRef: MatDialogRef<ElementFiltersListComponent, any>;
  public filterFormDialogRef: MatDialogRef<
    ElementFilterFormComponent,
    ElementFilter
  >;
  public query: string;
  public selectAll: Boolean;
  public selectedElements = [];
  public sortByColumns = ["name", "createdDate", "updatedDate"];
  public direction = ",desc";
  public sortedBy = "createdDate";
  public tooltipPositionLeft: TooltipPosition = "left";
  public screenNames: Set<ElementScreenName>;
  public screenNameOptions: Observable<Set<ElementScreenName>>;
  public originName: any;

  screenName: any;
  testcase: any;
  selectedScreen: any = null;
  selectedScreenTab = 0;
  screenNameDetailIndex = 0;
  activities: any[] = [];
  gaps: any[] = [];
  errors: any[] = [];
  criticalPaths: any[] = [];
  selectedInsightsTab = 0;
  isLoadingActivities: boolean = false;
  activityData: any = null;
  isLoadingErrors: boolean = false;
  errorData: any = null;

  public testcaseDetails: any;

  isLoadingGaps: boolean = false;
  currentUrl: string = ''; // Replace with actual URL

  userSegments: any = null;
  isLoadingSegments: boolean = false;
  heatmap: any
  isLoadingHeatmap: boolean = false;
  maxDate: Date = new Date();
  createdDateRange: FormGroup;
  minDate: Date | null = null
  isPanelCollapsed = false;
  plugins: Integrations[] = [];
  pluginData: any;
  projectId: any;
  lockData: any;


  constructor(
    public authGuard: AuthenticationGuard,
    public notificationsService: NotificationsService,
    public elementService: ElementService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    public route: ActivatedRoute,
    private matDialog: MatDialog,
    private workspaceVersionService: WorkspaceVersionService,
    private elementFilterService: ElementFilterService,
    private router: Router,
    public agentService: AgentService,
    public testCaseService: TestCaseService,
    private ChromeExtensionService: ChromeExtensionService,
    public chromeRecorderService: ChromeRecorderService,
    private elementScreenNameService: ElementScreenNameService,
    private fb: FormBuilder,
    private http: HttpClient,
    private externalApplicationConfig: IntegrationsService,
    private LicenceService: LicenceSevices
  ) {
    super(authGuard, notificationsService, translate, toastrService);
    this.checkExtension();
    this.fetchPlugins();
    this.createdDateRange = this.fb.group({
      start: null,
      end: null
    });
    this.originName = window.location.hostname.split(".")[0];
  }
  ;
  fetchPlugins() {
    this.externalApplicationConfig.findAll().subscribe(data => {
      this.plugins = data;
      this.pluginData = this.plugins.find(plug => plug.isContextqaLab);
      if (this.pluginData && this.pluginData.username) {
        this.projectId = this.pluginData.username;
      }
      // console.log(this.pluginData, this.plugins)
    }, error => console.log(error));
  }

  get locatorType() {
    return Object.keys(ElementLocatorType).filter((type) => {
      return (
        type == ElementLocatorType.accessibility_id ||
        type == ElementLocatorType.class_name ||
        type == ElementLocatorType.xpath ||
        type == ElementLocatorType.id_value ||
        type == ElementLocatorType.name
      );
    });
  }

  get submitReviewDisabled(): boolean {
    return Boolean(
      this.elements.cachedItems
        .filter((element) => element["isSelected"])
        .find(
          (element) =>
            element["status"] == ElementStatus.IN_REVIEW ||
            element["createdById"] != this.authGuard?.user?.id
        )
    );
  }

  get reviewDisabled(): boolean {
    return Boolean(
      this.elements.cachedItems
        .filter((element) => element["isSelected"])
        .find(
          (element) =>
            element["status"] != ElementStatus.IN_REVIEW ||
            element["reviewedById"] != this.authGuard?.user?.id
        )
    );
  }

  ngOnInit() {
    this.LicenceService.lockData$.subscribe(data => {
      if (data) {
        this.lockData = data;
      }
    });
    this.pushToParent(
      this.route,
      this.route.parent.parent.parent.snapshot.params
    );
    let queryKey = Object.keys(this.route.queryParams["value"]);
    let query = queryKey ? queryKey[0] : null;
    this.versionId = this.route.parent.parent.parent.snapshot.params.versionId;
    this.fetchVersion();
    this.route.params.subscribe((res) => {
      this.filterId = res.filterId;
      this.query = query ? query : res.q;
      if (query) this.navigateToQueryBasedUrl();
      this.fetchFilters();
    });
    setTimeout(() => this.chromeRecorderService.pingRecorder(), 200);
    this.fetchScreenNames()

    const today = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(today.getDate() - 7);

    this.createdDateRange.patchValue({
      start: oneWeekAgo,
      end: today
    });
    this.minDate = this.getThirtyDaysAgo(today);
  }
  async checkExtension() {
    this.isExtensionInstalled =
      await this.ChromeExtensionService.isExtensionInstalled(this.extensionId);
    if (this.isExtensionInstalled) {
      this.checkExtensionInstall = false;
    } else {
      this.checkExtensionInstall = true;
    }
  }
  // Add public getter
  public get getExtensionId(): string {
    return this.extensionId;
  }

  openDetails(element) {
    this.selectAllToggle(false);
    const dialogRef = this.matDialog.open(DetailsHeaderComponent, {
      width: "60%",
      height: "100vh",
      data: {
        versionId: this.versionId,
        elementId: element.id,
      },
      position: { top: "0", right: "0", bottom: "0" },
      panelClass: ["mat-dialog", "rds-none"],
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.fetchElements();
    });
  }
  fetchVersion() {
    this.workspaceVersionService.show(this.versionId).subscribe((res) => {
      this.version = res;
      if (this.version.workspace.isWebMobile) {
        this.chromeRecorderService.isChromeBrowser();
        //this.fetchChromeExtensionDetails();
        this.chromeRecorderService.pingRecorder();
      } else if (this.version.workspace.isMobileNative) {
        this.pingAgent();
      }
    });
  }

  hasInspectorFeature() {
    return (
      (this.version && this.version.workspace.isAndroidNative) ||
      (this.version && this.version.workspace.isIosNative)
    );
  }

  pingAgent() {
    this.agentService
      .ping()
      .subscribe((res: AgentInfo) => (this.agentInstalled = res.isRegistered));
  }

  fetchElements() {
    let sortBy = this.sortedBy + this.direction;
    let query = "";
    if (this.query) {
      this.query += this.currentFilter.normalizedQuery
        ? this.currentFilter.queryString
        : "";
      query = this.byPassSpecialCharacters(this.query);
      this.query = query;
      if (!query.includes("workspaceVersionId:")) {
        query += ",workspaceVersionId:" + this.versionId;
      }
    }
    this.elements = new FilterableInfiniteDataSource(
      this.elementService,
      this.query,
      sortBy,
      50,
      this.filterId,
      this.versionId
    );
    this.selectAllToggle(false);
  }

  openAddEditElement(elementId) {
    let isNewUI =
      this.version.workspace.isWeb || this.version.workspace.isMobileWeb;
    const dialogRef = this.matDialog.open(ElementFormComponent, {
      height: "100vh",
      width: isNewUI ? "540px" : "60%",
      position: { top: "0px", right: "0px" },
      data: {
        isNewUI: isNewUI,
        versionId: this.versionId,
        elementId: elementId,
      },
      panelClass: ["mat-dialog", "rds-none"],
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res instanceof MirroringData) {
        this.matDialog.openDialogs
          ?.find(
            (dialog) =>
              dialog.componentInstance instanceof MobileRecordingComponent
          )
          ?.afterClosed()
          .subscribe((r) => this.fetchElements());
      } else if (res) this.fetchElements();
    });
  }

  openAddScreenName(screen: any, type: any) {
    let isNewUI =
      this.version.workspace.isWeb || this.version.workspace.isMobileWeb;
    const dialogRef = this.matDialog.open(ElementFormComponent, {
      height: "100vh",
      width: "540px",
      position: { top: "0px", right: "0px" },
      data: {
        isOnlyScreenNameAndURL: true,
        versionId: this.versionId,
        editScreenNameAndUrl: type,
        data: screen,
        isNewUI: isNewUI,
      },
      panelClass: ["mat-dialog", "rds-none"],
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.fetchScreenNames();
    });
  }

  destroyElement(id) {
    this.elementService.destroy(id).subscribe(
      () => {
        this.translate
          .get("element.notification.delete.success")
          .subscribe((res) => {
            this.showNotification(NotificationType.Success, res);
          });
        this.fetchElements();
        this.selectedElements = [];
      },
      (err) => {
        this.translate
          .get("element.notification.delete.failure")
          .subscribe((res) => {
            this.showNotification(NotificationType.Success, res);
          });
      }
    );
  }

  checkForLinkedTestCases(element?) {
    let testCases: InfiniteScrollableDataSource;
    let query =
      "workspaceVersionId:" +
      this.versionId +
      ",element:" +
      encodeURI(element.name);
    query = this.byPassSpecialCharacters(query);
    testCases = new InfiniteScrollableDataSource(this.testCaseService, query);

    waitTillRequestResponds();
    let _this = this;

    function waitTillRequestResponds() {
      if (testCases.isFetching)
        setTimeout(() => waitTillRequestResponds(), 100);
      else {
        if (testCases.isEmpty) _this.openDeleteDialog(element);
        else _this.openLinkedTestCasesDialog(testCases);
      }
    }
  }

  private openLinkedTestCasesDialog_1(list) {
    this.translate.get("elements_linked_with_cases").subscribe((res) => {
      this.matDialog.open(LinkedEntitiesModalComponent, {
        width: "568px",
        height: "auto",
        data: {
          description: res,
          linkedEntityList: list,
          isCoverage: "isCoverage",
        },
        panelClass: ["mat-dialog", "rds-none"],
      });
    });
  }
  checkIsTestcaseID(element?) {
    let testCases: InfiniteScrollableDataSource;
    let query =
      "workspaceVersionId:" +
      this.versionId +
      ",element:" +
      encodeURI(element.name);
    query = this.byPassSpecialCharacters(query);
    testCases = new InfiniteScrollableDataSource(this.testCaseService, query);
    this.openLinkedTestCasesDialog_1(testCases);
  }
  isLoading: boolean = false;

  checkIsTestcaseIDForTreeView(element?) {
    const query =
      "workspaceVersionId:" +
      this.versionId +
      ",element:" +
      encodeURI(element.name);

    const sanitizedQuery = this.byPassSpecialCharacters(query);
    element.testcases = new InfiniteScrollableDataSource(
      this.testCaseService,
      sanitizedQuery
    );
  }

  openDeleteDialog(element?) {
    let message = element?.id
      ? "element.delete.confirmation.message"
      : "element.bulk_delete.confirmation.message";
    this.translate
      .get(message, { selectedElements: this.selectedElements.length })
      .subscribe((res) => {
        const dialogRef = this.matDialog.open(ConfirmationModalComponent, {
          width: "450px",
          data: {
            description: res,
          },
          panelClass: ["matDialog", "delete-confirm"],
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            if (element?.id) this.destroyElement(element?.id);
            else this.multipleDelete();
          }
        });
      });
  }
  startCapture() {
    this.elementCapture = true;
    this.chromeRecorderService.recorderVersion = this.version;
    this.registerListener();
    this.chromeRecorderService.postGetElementMessage(true);
  }

  stopCapture() {
    this.chromeRecorderService.stopSpying();
    this.elementCapture = false;
    this.fetchElements();
  }

  registerListener() {
    this.chromeRecorderService.multiElementCallBack = this.saveMultipleElements;
    this.chromeRecorderService.multiElementCallBackContext = this;
  }

  saveMultipleUiIdentifiers(elements: Element[]) {
    if (elements?.length) {
      elements.forEach(
        (element) => (element.workspaceVersionId = this.versionId)
      );
      this.elementService.bulkCreate(elements).subscribe(
        (res) => {
          this.translate
            .get("elements.multiple_save.success.message")
            .subscribe((msg) =>
              this.sendNotificationResponseToPlugin(msg, "SUCCESS")
            );
          this.fetchElements();
        },
        (error) => {
          this.translate
            .get("elements.multiple_save.failure.message")
            .subscribe((msg) => this.sendNotificationResponseToPlugin(msg));
        }
      );
    } else {
      this.elementCapture = false;
      this.fetchElements();
    }
  }

  sendNotificationResponseToPlugin(msg, status?: string) {
    this.translate.get(msg).subscribe((message) => {
      let result = {};
      result["isListupdate"] = true;
      result["message"] = message;
      result["status"] = status;
      this.chromeRecorderService.sendResponseElement(result);
    });
  }

  saveMultipleElements() {
    this.elementCapture = false;
    this.fetchElements();
  }

  discard() {
    this.query = undefined;
    this.router.navigate([
      "/td",
      this.version.id,
      "elements",
      "filter",
      this.currentFilter.id,
    ]);
    this.fetchElements();
  }

  saveViewAs() {
    this.openFilterForm(undefined);
  }

  saveView() {
    this.openFilterForm(this.currentFilter);
  }

  saveOrEditFilter() {
    this.openFilterForm(this.currentFilter);
  }

  openFiltersList() {
    this.filterListDialogRef = this.matDialog.open(
      ElementFiltersListComponent,
      {
        backdropClass: "cdk-overlay-transparent-backdrop",
        height: "100%",
        width: "364px",
        panelClass: ["mat-overlay", "rds-none"],
        data: {
          list: this.filters,
          currentFilter: this.currentFilter,
          version: this.version,
        },
      }
    );

    const matDialogConfig = new MatDialogConfig();
    const rect: DOMRect =
      this.filterListBtn.nativeElement.getBoundingClientRect();
    matDialogConfig.position = {
      left: `${rect.left - 42}px`,
      top: `${rect.top}px`,
    };
    this.filterListDialogRef.updatePosition(matDialogConfig.position);
  }

  openFilter() {
    this.filterDialogRef = this.matDialog.open(ElementFiltersComponent, {
      width: "25%",
      height: "100vh",
      position: { top: "0", right: "0", bottom: "0" },
      panelClass: ["mat-overlay"],
      data: {
        version: this.version,
        filter: this.currentFilter,
        query: this.query,
      },
    });
    this.filterDialogRef.componentInstance.filterAction.subscribe((query) => {
      if (query) {
        this.query = query;
        this.navigateToQueryBasedUrl();
        this.fetchElements();
      } else this.discard();
    });
  }

  navigateToQueryBasedUrl() {
    let routerUrl: String = this.route.snapshot["_routerState"].url;
    routerUrl = routerUrl.includes("?")
      ? routerUrl.slice(0, routerUrl.indexOf("?"))
      : routerUrl;
    this.router.navigateByUrl(routerUrl + "?" + this.query);
  }

  openFilterForm(filter: ElementFilter) {
    this.filterFormDialogRef = this.matDialog.open(ElementFilterFormComponent, {
      width: "40%",
      panelClass: ["mat-dialog", "rds-none"],
      data: { query: this.query, version: this.version, filter: filter },
    });

    this.filterFormDialogRef
      .afterClosed()
      .subscribe((result: ElementFilter) => {
        if (result) {
          this.filterId = result.id;
          this.query = undefined;
          this.currentFilter = result;
          this.router.navigate([
            "/td",
            this.version.id,
            "elements",
            "filter",
            result.id,
          ]);
          this.fetchFilters();
        }
      });
  }

  deleteFilter() {
    this.elementFilterService.destroy(this.currentFilter.id).subscribe(() => {
      this.translate
        .get("filter.delete.success", { name: this.currentFilter.name })
        .subscribe((res) =>
          this.showNotification(NotificationType.Success, res)
        );
      this.filters.content = this.filters.content.filter(
        (filter) => filter.id != this.currentFilter.id
      );
      this.currentFilter = this.filters.content[0];
      this.filterId = this.currentFilter.id;
      this.router.navigate([
        "/td",
        this.version.id,
        "elements",
        "filter",
        this.currentFilter.id,
      ]);
      this.fetchFilters();
    });
  }

  selectAllToggle(select) {
    let elements = this.elements["cachedItems"];
    if (!select) {
      this.selectedElements = [];
      this.selectAll = false;
    }
    for (let i = 0; i < elements.length; i++) {
      if (select && this.selectedElements.indexOf(elements[i]["id"]) == -1) {
        this.selectedElements.push(elements[i]["id"]);
        this.elements["cachedItems"][i]["isSelected"] = true;
      } else if (!select) {
        this.elements["cachedItems"][i]["isSelected"] = false;
      }
    }
  }

  setSelectedList(id, isSelected: Boolean) {
    if (isSelected) this.selectedElements.push(id);
    else this.selectedElements.splice(this.selectedElements.indexOf(id), 1);
  }

  multipleDelete() {
    this.elementService
      .bulkDestroy(this.selectedElements, this.versionId)
      .subscribe(
        () => {
          this.translate
            .get("element.notification.bulk_delete.success")
            .subscribe((res) =>
              this.showNotification(NotificationType.Success, res)
            );
          this.fetchElements();
          this.selectAllToggle(false);
        },
        (err) => {
          this.translate
            .get("element.notification.bulk_delete.failure")
            .subscribe((res) =>
              this.showAPIError(err, res, "Elements", "Test Case")
            );
          this.fetchElements();
          this.selectAllToggle(false);
        }
      );
  }

  addLabelsDialog(id) {
    const dialogRef = this.matDialog.open(ElementAddTagComponent, {
      width: "450px",
      panelClass: ["mat-dialog", "rds-none"],
      data: {
        elementId: id,
        versionId: this.version,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fetchElements();
      } else {
        console.log(
          "No refresh needed - dialog was cancelled or no changes made"
        );
      }
    });
  }

  bulkUpdateDialog(ids) {
    let bulkUpdateComponent = this.matDialog.open(ElementBulkUpdateComponent, {
      width: "450px",
      panelClass: ["mat-dialog", "rds-none"],
      data: {
        elementIds: ids,
        versionId: this.version,
      },
    });
    bulkUpdateComponent.afterClosed().subscribe((res) => {
      if (res) {
        this.fetchElements();
        this.selectAllToggle(false);
      }
    });
  }

  sortBy(value, direction) {
    if (!(this.sortedBy != value || this.direction != direction)) return;
    this.direction = direction;
    this.sortedBy = value;
    this.fetchElements();
  }

  private fetchFilters() {
    this.elementFilterService.findAll(this.versionId).subscribe((res) => {
      this.filters = res;
      this.currentFilter = this.filters.content.find(
        (filter) => filter.id == this.filterId
      );
      this.fetchElements();
    });
  }

  private openLinkedTestCasesDialog(list) {
    this.translate.get("elements_linked_with_cases").subscribe((res) => {
      this.matDialog.open(LinkedEntitiesModalComponent, {
        width: "568px",
        height: "auto",
        data: {
          description: res,
          linkedEntityList: list,
        },
        panelClass: ["mat-dialog", "rds-none"],
      });
    });
  }

  filterByScreenName(screenName: string) {
    let sortBy = this.sortedBy + this.direction;
    let encodedScreenName = encodeURIComponent(screenName);
    this.query =
      "workspaceVersionId:" +
      this.version.id +
      ",screenName:" +
      encodedScreenName;
    this.navigateToQueryBasedUrl();
    let encodedQuery =
      ",workspaceVersionId:" +
      this.version.id +
      ",screenName:" +
      encodedScreenName;
    if (this.query === undefined)
      this.query =
        ",workspaceVersionId:" + this.version.id + ",screenName:" + screenName;
    else if (this.query.indexOf("screenName") == -1)
      this.query +=
        ",workspaceVersionId:" + this.version.id + ",screenName:" + screenName;
    else if (this.router.url.indexOf(encodedScreenName) !== -1)
      this.translate
        .get("element.name.already.filtered")
        .subscribe((res: string) => {
          this.showNotification(NotificationType.Error, res);
        });
    this.elements = new FilterableInfiniteDataSource(
      this.elementService,
      encodedQuery,
      sortBy,
      50,
      1,
      this.versionId
    );
  }

  humanizedDate(date) {
    return moment.duration(moment().diff(date)).humanize() + " ago";
  }

  onTabChange(index: number): void {
    if (index === 1) {
      this.fetchScreenNames(); // Trigger when "Tree View" tab is active
    }
  }
  fetchScreenNames() {
    this.elementScreenNameService
      .findAll("workspaceVersionId:" + this.versionId, "createdDate,desc")
      .subscribe((res) => {
        const screenNameSet = new Set<ElementScreenName>(res.content);
        this.screenName = res.content;
        this.elements.cachedItems.forEach((cachedItem: any) => {
          this.screenName.forEach((screenNameObj: any) => {
            if (screenNameObj.name === cachedItem.screenNameObj.name) {
              if (!screenNameObj.elements) {
                screenNameObj.elements = [];
              }
              screenNameObj.elements.push(cachedItem);
            }
          });
        });

        // Set up polling for auto-refresh every 30 seconds
        setTimeout(() => {
          this.fetchScreenNames();
        }, 30000);

        return this.screenName;
      });
  }
  toggleNode(node: any): void {
    node.expanded = !node.expanded;
  }
  toggleElement(element: any): void {
    if (element.linkedTestCases > 0) {
      element.expanded = !element.expanded;
      if (element.expanded && !element.testcases) {
        this.checkIsTestcaseIDForTreeView(element);
      }
    }
  }
  openLinkedEntity(linkedEntity) {
    let entityUrl;
    if (linkedEntity instanceof TestPlan)
      entityUrl = "/td/plans/" + linkedEntity.id + "/details";
    else if (linkedEntity instanceof TestSuite)
      entityUrl = "/td/suites/" + linkedEntity.id + "/cases";
    else if (linkedEntity instanceof TestSuite)
      entityUrl = "/td/cases/" + linkedEntity.id + "/steps";
    else entityUrl = "/td/cases/" + linkedEntity.id + "/steps";
    window.open(window.location.origin + entityUrl, "_blank");
  }

  selectScreen(screen: any) {
    console.log(screen)
    this.selectedScreen = screen;
    this.screenNameDetailIndex = 0;
    this.selectedInsightsTab = 0;
    this.activityData = null;
    this.testcaseDetails = [];
  }

  onScreenTabChange(index: number) {
    this.screenNameDetailIndex = index;
    if (index === 3) {
      // Insights tab
      // Initialize date range for the last 7 days if not already set
      const today = new Date();
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);

      if (!this.createdDateRange.value.start) {
        this.createdDateRange.patchValue({
          start: sevenDaysAgo,
          end: today
        });
      }

      // Load activity data by default
      this.getActivityData();
    }
  }

  onInsightsSubTabChange(index: number) {
    this.selectedInsightsTab = index;
    switch (index) {
      case 0:
        this.getActivityData();
        break;
      case 1:
        this.getGAPSData();
        break;
      case 2:
        this.getErrorsData();
        break;
      case 3:
        this.getheatmapData();
        break;
      case 4:
        this.getUserSegmentsData();
        break;
    }
  }
  getGAPSData() {
    this.isLoadingGaps = true;
    this.currentUrl = this.selectedScreen.url;
    if (!this.currentUrl) {
      this.isLoadingGaps = false;
      this.showNotification(NotificationType.Error, 'Not found a Screen URL');
      return;
    }
    this.elementScreenNameService.getGapsByUrl(this.currentUrl).subscribe({
      next: (response: any) => {
        const prepareData = {
          name: response.url,
          testCases: response.criticalScenario['cases']
        }
        this.testcaseDetails = prepareData;
        this.isLoadingGaps = false;
      },
      error: (error) => {
        this.isLoadingGaps = false;
        this.showNotification(NotificationType.Error, 'Failed to fetch GAPS data');
      }
    });
  }

  refreshGapsData() {
    this.currentUrl = this.selectedScreen.url;

    if (!this.currentUrl) {
      this.isLoadingGaps = false;
      this.showNotification(NotificationType.Error, 'Not found a Screen URL');
      return;
    }
    this.isLoadingGaps = true;
    const { start, end } = this.createdDateRange.value;
    const startDate = this.formatDate(start);
    const endDate = this.formatDate(end);
    const dateRange = `${startDate}-${endDate}`;
    this.testcaseDetails = [];
    console.log("testcaseDetails ::", this.testcaseDetails)
    this.elementScreenNameService.refreshGapsData(this.currentUrl, dateRange).subscribe({
      next: (response: any) => {
        const prepareData = {
          name: response.url,
          testCases: response.criticalScenario['cases']
        }
        this.testcaseDetails = prepareData;
        console.log("testcaseDetails ::", this.testcaseDetails)
        this.isLoadingGaps = false;
      },
      error: (error) => {
        this.isLoadingGaps = false;
        this.testcaseDetails = null;
        this.showNotification(NotificationType.Error, 'Failed to refresh GAPS data');
      }
    });
  }

  private formatDate(date: Date | null): string {
    if (!date) {
      throw new Error('Invalid date');
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  }
  refreshGActiviteData() {
    this.currentUrl = this.selectedScreen.url;

    if (!this.currentUrl) {
      this.isLoadingActivities = false;
      this.showNotification(NotificationType.Error, 'Not found a Screen URL');
      return;
    }
    this.isLoadingActivities = true;
    const { start, end } = this.createdDateRange.value;
    const startDate = this.formatDate(start);
    const endDate = this.formatDate(end);
    const dateRange = `${startDate}-${endDate}`;
    this.elementScreenNameService.refreshActiviteData(this.currentUrl, dateRange).subscribe({
      next: (response: any) => {
        this.activityData = response;
        this.isLoadingActivities = false;
      },
      error: (error) => {
        this.isLoadingActivities = false;
        this.testcaseDetails = null;
        this.showNotification(NotificationType.Error, 'Failed to refresh activity data');
      }
    });
  }
  getActivityData() {
    if (!this.selectedScreen?.url) {
      this.showNotification(NotificationType.Error, 'Not found a Screen URL');
      return;
    }

    this.isLoadingActivities = true;
    const endDate = moment(this.createdDateRange.value.end).format('YYYY/MM/DD');
    const startDate = moment(this.createdDateRange.value.start).format('YYYY/MM/DD');
    const dateRange = `${startDate}-${endDate}`;
    console.log(this.selectedScreen.url)

    this.elementScreenNameService.getActivityData(this.selectedScreen.url, dateRange).subscribe({
      next: (response) => {
        this.activityData = response;
        this.isLoadingActivities = false;
      },
      error: (error) => {
        this.isLoadingActivities = false;
        this.showNotification(NotificationType.Error, 'Failed to fetch activity data');
      }
    });
  }

  getErrorsData() {
    if (!this.selectedScreen?.url) {
      this.showNotification(NotificationType.Error, 'Not found a Screen URL');
      return;
    }

    this.isLoadingErrors = true;
    const endDate = moment(this.createdDateRange.value.end).format('YYYY/MM/DD');
    const startDate = moment(this.createdDateRange.value.start).format('YYYY/MM/DD');
    const dateRange = `${startDate}-${endDate}`;

    this.elementScreenNameService.getErrorsData(this.selectedScreen.url, dateRange).subscribe({
      next: (response) => {
        this.errorData = response.cases;
        this.isLoadingErrors = false;
      },
      error: (error) => {
        this.isLoadingErrors = false;
        this.showNotification(NotificationType.Error, 'Failed to fetch error data');
      }
    });
  }

  refreshErrorsData() {
    if (!this.selectedScreen?.url) {
      this.showNotification(NotificationType.Error, 'Not found a Screen URL');
      return;
    }

    this.isLoadingErrors = true;
    const endDate = moment(this.createdDateRange.value.end).format('YYYY/MM/DD');
    const startDate = moment(this.createdDateRange.value.start).format('YYYY/MM/DD');
    const dateRange = `${startDate}-${endDate}`;

    this.elementScreenNameService.refreshErrorsData(this.selectedScreen.url, dateRange).subscribe({
      next: (response) => {
        this.errorData = response.cases;
        this.isLoadingErrors = false;
      },
      error: (error) => {
        this.isLoadingErrors = false;
        this.showNotification(NotificationType.Error, 'Failed to refresh error data');
      }
    });
  }

  getUserSegmentsData() {
    // this.selectedScreen.url = "https://www.honeylove.com/products/crossover-bra"
    if (!this.selectedScreen?.url) {
      this.showNotification(NotificationType.Error, 'Not found a Screen URL');
      return;
    }

    this.isLoadingSegments = true;
    this.elementService.getUserSegments(this.selectedScreen.url).subscribe({
      next: (response) => {
        this.userSegments = response;
        this.isLoadingSegments = false;
      },
      error: (error) => {
        this.isLoadingSegments = false;
        this.showNotification(NotificationType.Error, 'Failed to fetch user segments data');
      }
    });
  }

  refreshUserSegments() {
    if (!this.selectedScreen?.url) {
      this.showNotification(NotificationType.Error, 'Not found a Screen URL');
      return;
    }

    this.userSegments = null;
    this.isLoadingSegments = true;
    this.elementService.refreshUserSegments(this.selectedScreen.url).subscribe({
      next: (response) => {
        this.userSegments = response;
        this.isLoadingSegments = false;
      },
      error: (error) => {
        this.isLoadingSegments = false;
        this.showNotification(NotificationType.Error, 'Failed to fetch user segments data');
      }
    });
  }
  getheatmapData() {
    this.heatmap = null;
    if (!this.selectedScreen?.url) {
      this.showNotification(NotificationType.Error, 'Not found a Screen URL');
      return;
    }

    this.isLoadingHeatmap = true;
    this.elementScreenNameService.getHeatmapData(this.selectedScreen.url).subscribe({
      next: (response) => {
        this.heatmap = response;
        this.isLoadingHeatmap = false;
      },
      error: (error) => {
        this.isLoadingHeatmap = false;
        // this.showNotification(NotificationType.Error, 'Failed to fetch  heatmap data');
      }
    });
  }

  onStartDateChange(event: any): void {
    const startDate = event.value;
    if (startDate) {
      this.minDate = this.getThirtyDaysAgo(startDate);
      this.createdDateRange.patchValue({
        end: null
      });
    }
  }


  private getThirtyDaysAgo(date: Date): Date {
    const result = new Date(date);
    result.setDate(result.getDate() - 30);
    return result;
  }

  dateFilter = (date: Date | null): boolean => {
    if (!date) return false;

    // Allow dates only within the last 30 days and no future dates
    const startDate = this.createdDateRange.get('start')?.value;
    if (startDate && this.minDate) {
      return date >= this.minDate && date <= this.maxDate;
    }

    return date <= this.maxDate;
  };

  openSidePanelAndHeatmapPage() {
    if (chrome && chrome.runtime) {
      let data = {
        action: "openSidePanelFromPortalHeatmap",
        origin: window.location.hostname.split(".")[0],
        jwt: localStorage.getItem("_t"),
        userEmail: localStorage.getItem('useremail')
      };
      chrome.runtime.sendMessage(
        "pgadpooodaipbhbchnojijmlkhpamadh",
        { message: "openSidePanelFromPortalHeatmap", data: data },
        (data: any) => {

        }
      );
      window.open(this.selectedScreen.url, "_blank")
    }
  }

  togglePanel() {
    this.isPanelCollapsed = !this.isPanelCollapsed;
  }

}
