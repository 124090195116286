import { Component, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewChecked, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { WorkspaceType } from 'app/enums/workspace-type.enum';
import { WorkspaceVersion } from 'app/models/workspace-version.model';
import { TestCaseService } from 'app/services/test-case.service';
import { WorkspaceService } from 'app/services/workspace.service';
import { BaseComponent } from 'app/shared/components/base.component';
import { AuthenticationGuard } from 'app/shared/guards/authentication.guard';
import { ToastrService } from 'ngx-toastr';
import { ChatbotUiStateService } from 'app/services/chatbot-ui-state.service';
// <div class="dropdown-container">
//           <button class="dropdown-toggle" (click)="toggleDropdown()">
//             <img src="../../../assets/images/small_ai.png" alt="AI" class="ai-icon">
//           </button>
//           <div *ngIf="isDropdownOpen" class="dropdown">
//             <ul>
//               <li *ngFor="let item of optionList" (click)="selectOption(item?.type)">
//                 <div class="option-content">
//                   <span>{{ item?.label }}</span>
//                   <mat-icon *ngIf="selectedOption === item?.type">check</mat-icon>
//                 </div>
//               </li>
//             </ul>
//           </div>
//         </div>
@Component({
  selector: 'app-chatbot-test-case-panel',
  template: `
    <div class="chatbot-test-case-panel" *ngIf="isVisible">
      <!-- Content area with scroll -->
      <div class="panel-content" #panelContent>
        <div class="instructions">
          <p>Provide a description of your feature, and our AI will generate test cases for you.</p>
        </div>

        <div *ngIf="warningMessage" class="warning-message">
          <mat-icon>warning</mat-icon>
          {{ warningMessage }}
        </div>

        <div class="chat-messages">
          <div *ngFor="let message of chatMessages"
               [ngClass]="{'message': true, 'user-message': message.role === 'user', 'ai-message': message.role === 'system'}">
            <div class="message-content">
            <div *ngIf="message.role === 'system'" class="text-message">
                {{ message.content.content }}
              </div>
              <div *ngIf="message.role === 'system' && message.content.testCase" class="test-case-message">
                <app-ai-test-case-display
                  [testcaseDetails]="message.content.testCase"
                  [version]="version"
                  [isFromAiChat]="true"
                  [isTestPlanButton]="false">
                </app-ai-test-case-display>
              </div>

              <div *ngIf="message.role === 'user'" class="text-message">
                {{ message.content }}
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="isLoading" class="thinking-message">
          <div class="thinking-dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>

      <!-- Fixed input section at bottom -->
      <div class="input-section" *ngIf="!chatMessages[chatMessages.length - 1]?.isAIASuggestion">

        <div class="input-area">
          <textarea
            placeholder="Describe your feature here..."
            [(ngModel)]="featureDescription"
            [disabled]="isLoading"
            (keyup.enter)="generateTestCases()"
          ></textarea>
        </div>

        <button
          (click)="generateTestCases()"
          class="generate-btn"
          [disabled]="!featureDescription.trim() || isLoading"
        >
          <mat-icon>auto_awesome</mat-icon>
          Generate Test Cases
        </button>
      </div>
    </div>
  `,
  styles: [`
    .chatbot-test-case-panel {
      display: flex;
      flex-direction: column;
      height: 100%;
      background: #F4F5FF;
      position: relative;
      width: 100%;
    }

    .panel-content {
      flex: 1;
      padding: 20px;
      padding-bottom: 250px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .instructions {
      p {
        margin: 0;
        font-size: 14px;
        color: #666;
        line-height: 1.5;
      }
    }

    .warning-message {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 12px 16px;
      background-color: #fff3cd;
      border: 1px solid #ffeeba;
      border-radius: 8px;
      color: #856404;
      font-size: 14px;

      mat-icon {
        color: #856404;
      }
    }

    .chat-messages {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .message {
      max-width: 100%;
      padding: 12px 16px;
      border-radius: 12px;
      font-size: 14px;
      line-height: 1.5;
    }

    .user-message {
      align-self: flex-end;
      background: #6366f1;
      color: white;
    }

    .ai-message {
      align-self: flex-start;
      background: white;
      color: #333;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    }

    .thinking-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      padding: 16px;
      background: white;
      border-radius: 12px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
      align-self: flex-start;
      max-width: 80%;

      .thinking-dots {
        display: flex;
        gap: 4px;

        span {
          width: 8px;
          height: 8px;
          background: #6366f1;
          border-radius: 50%;
          animation: bounce 1.4s infinite ease-in-out;

          &:nth-child(1) { animation-delay: -0.32s; }
          &:nth-child(2) { animation-delay: -0.16s; }
        }
      }

      p {
        margin: 0;
        color: #666;
        font-size: 14px;
      }
    }

    @keyframes bounce {
      0%, 80%, 100% { transform: scale(0); }
      40% { transform: scale(1); }
    }

    .input-section {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
      background: #F4F5FF;
      border-top: 1px solid rgba(0, 0, 0, 0.08);
      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
    }

    .dropdown-container {
      position: relative;
      align-self: flex-start;
    }

    .dropdown-toggle {
      background: white;
      border: none;
      border-radius: 20px;
      padding: 8px;
      cursor: pointer;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
      transition: all 0.2s ease;

      &:hover {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }

      .ai-icon {
        width: 20px;
        height: 20px;
      }
    }

    .dropdown {
      position: absolute;
      top: -105px;
      left: 0;
      background: white;
      border-radius: 8px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
      margin-top: 8px;
      min-width: 200px;
      z-index: 1000;

      ul {
        list-style: none;
        margin: 0;
        padding: 8px 0;

        li {
          padding: 8px 16px;
          cursor: pointer;
          transition: background-color 0.2s ease;

          &:hover {
            background-color: #f5f5ff;
          }

          .option-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;

            span {
              font-size: 14px;
              color: #333;
            }

            mat-icon {
              color: #4caf50;
              font-size: 18px;
            }
          }
        }
      }
    }

    .input-area {
      textarea {
        width: 100%;
        min-height: 80px;
        padding: 12px;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        resize: vertical;
        font-size: 14px;
        line-height: 1.5;
      }
    }

    .generate-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 12px 24px;
      background: #6366f1;
      color: white;
      border: none;
      border-radius: 8px;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover:not(:disabled) {
        background: #4f46e5;
      }

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }

      mat-icon {
        font-size: 18px;
      }
    }

    /* Responsive styles */
    @media screen and (max-width: 768px) {
      .panel-content {
        padding: 16px;
        padding-bottom: 200px;
      }

      .message {
        max-width: 90%;
      }

      .input-section {
        padding: 12px;
      }

      .input-area {
        textarea {
          min-height: 60px;
          font-size: 13px;
        }
      }

      .generate-btn {
        padding: 10px 20px;
        font-size: 13px;
      }
    }

    @media screen and (max-width: 480px) {
      .panel-content {
        padding: 12px;
        padding-bottom: 180px;
      }

      .message {
        max-width: 95%;
        padding: 10px 12px;
        font-size: 13px;
      }

      .instructions {
        p {
          font-size: 13px;
        }
      }

      .warning-message {
        padding: 10px 12px;
        font-size: 13px;
      }

      .input-section {
        padding: 10px;
      }

      .input-area {
        textarea {
          min-height: 50px;
          padding: 10px;
          font-size: 13px;
        }
      }

      .generate-btn {
        padding: 8px 16px;
        font-size: 13px;
      }
    }
  `]
})
export class ChatbotTestCasePanelComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() isVisible: boolean = false;
  @Output() closePanel = new EventEmitter<void>();
  @ViewChild('panelContent') private panelContent: ElementRef;
  private shouldScrollToBottom = false;
  private isInitialized = false;
  version: any;
  selectedOption;
  optionList = [
    { label: 'Document', type: 'documentation' },
    { label: 'Knowledge Graph', type: 'default' }
  ];
  featureDescription: string = '';
  isLoading: boolean = false;
  isDropdownOpen = false;
  warningMessage: string = '';
  private warningTimeout: any;
  chatMessages: any[] = [];
  testcaseDetails: any;

  constructor(
    private testCaseService: TestCaseService,
    private workspaceService: WorkspaceService,
    public notificationsService: NotificationsService,
    public authGuard: AuthenticationGuard,
    public toastrService: ToastrService,
    public translate: TranslateService,
    private chatbotUiState: ChatbotUiStateService
  ) {
    super(authGuard, notificationsService, translate, toastrService);
    this.chatbotUiState.testCaseDetails$.subscribe(details => {
      if (details) {
        const message = {
          role: 'system',
          content: {
            testCase: {
              ...details.data,
              isAIASuggestion: true,
              id: details.id
            }
          },
          isAIASuggestion: true
        };
        console.log("message ::", message)
        this.chatMessages = [];
        this.chatMessages.push(message);
      }
    });
  }

  ngOnInit() {
    if (!this.isInitialized) {
      this.workspaceService.findAll("isDemo:true,workspaceType:" + WorkspaceType.WebApplication).subscribe(res => {
        this.version = res.content[0];
        this.isInitialized = true;
      });
    }
  }

  close() {
    if (this.warningTimeout) {
      clearTimeout(this.warningTimeout);
    }
    this.closePanel.emit();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectOption(item) {
    if (this.selectedOption === item) {
      this.selectedOption = null;
      return;
    }
    this.selectedOption = item;
    this.isDropdownOpen = false;
  }

  ngAfterViewChecked() {
    if (this.shouldScrollToBottom) {
      this.scrollToBottom();
      this.shouldScrollToBottom = false;
    }
  }

  private scrollToBottom(): void {
    try {
      this.panelContent.nativeElement.scrollTop = this.panelContent.nativeElement.scrollHeight;
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }

  generateTestCases() {
    if (this.featureDescription.trim()) {
      this.isLoading = true;
      this.warningMessage = '';
      this.shouldScrollToBottom = true;

      // Add user message to chat
      this.chatMessages.push({
        role: 'user',
        content: this.featureDescription
      });

      const payload = {
        query: this.featureDescription,
        default: this.selectedOption,
        previous_conversation: this.chatMessages.length > 1 ? this.chatMessages.slice(0, -1) : undefined,
      };

      this.testCaseService.suggestTestcaseSingle(payload).subscribe(
        (result: any) => {
          if (result.success === true) {
            if (result.data.testCase) {
              result.data.testCase.steps = result.data.testCase.steps.map(step => {
                if (step.name && step.name.includes("${test-data}")) {
                  const value = step.data[0];
                  step.name = step.name.replace("${test-data}", value);
                }
                if (step.label && step.label.includes("${test-data}")) {
                  const value = step.data[0];
                  step.name = step.label.replace("${test-data}", value);
                }
                return step;
              });
            }

            // Add AI response to chat
            this.chatMessages.push({
              role: 'system',
              content: result.data
            });
            this.shouldScrollToBottom = true;

            this.featureDescription = '';
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.setWarningMessage(result.message);
          }
        },
        error => {
          this.isLoading = false;
          if (error?.error) {
            this.setWarningMessage(error.error.message);
          } else {
            this.setWarningMessage('An error occurred while generating test cases. Please try again.');
          }
        }
      );
    }
  }

  setWarningMessage(message: string) {
    if (this.warningTimeout) {
      clearTimeout(this.warningTimeout);
    }

    this.warningMessage = message;

    this.warningTimeout = setTimeout(() => {
      this.warningMessage = '';
    }, 9000);
  }

  ngOnDestroy() {
    if (this.warningTimeout) {
      clearTimeout(this.warningTimeout);
    }
  }

  onEnterPress(event: KeyboardEvent) {
    // Prevent default to avoid new line in textarea
    event.preventDefault();

    // Only trigger if there's text and not loading
    if (this.featureDescription.trim() && !this.isLoading) {
      this.generateTestCases();
    }
  }
}
