import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Message } from '../components/chatbot/chatbot.config';

@Injectable({
    providedIn: 'root'
})
export class ChatbotStateService {
    private messagesSubject = new BehaviorSubject<Message[]>([]);
    private messageHistorySubject = new BehaviorSubject<string[]>(['main_menu']);
    private currentMessageIdSubject = new BehaviorSubject<string>('main_menu');
    private runTestCaseSubject = new Subject<void>();
    private runHistorySubject = new Subject<void>();
    runTestCase$ = this.runTestCaseSubject.asObservable();
    runHistory$ = this.runHistorySubject.asObservable();

    triggerRunTestCase() {
        this.runTestCaseSubject.next();
    }
    triggerRunHistory() {
        this.runHistorySubject.next();
    }
    messages$ = this.messagesSubject.asObservable();
    messageHistory$ = this.messageHistorySubject.asObservable();
    currentMessageId$ = this.currentMessageIdSubject.asObservable();

    updateState(messages: Message[], messageHistory: string[], currentMessageId: string) {
        this.messagesSubject.next(messages);
        this.messageHistorySubject.next(messageHistory);
        this.currentMessageIdSubject.next(currentMessageId);
        this.saveToLocalStorage();
    }

    resetState() {
        this.messagesSubject.next([]);
        this.messageHistorySubject.next(['main_menu']);
        this.currentMessageIdSubject.next('main_menu');
        this.saveToLocalStorage();
    }

    getCurrentState() {
        return {
            messages: this.messagesSubject.value,
            messageHistory: this.messageHistorySubject.value,
            currentMessageId: this.currentMessageIdSubject.value
        };
    }

    private saveToLocalStorage() {
        const state = {
            messages: this.messagesSubject.value,
            messageHistory: this.messageHistorySubject.value,
            currentMessageId: this.currentMessageIdSubject.value
        };
        localStorage.setItem('chatbotState', JSON.stringify(state));
    }

    private loadFromLocalStorage() {
        const savedState = localStorage.getItem('chatbotState');
        if (savedState) {
            const state = JSON.parse(savedState);
            this.updateState(state.messages, state.messageHistory, state.currentMessageId);
        }
    }
} 