<div class="dialog-wrapper">
  <button mat-icon-button *ngIf="selectedOption" class="back-button" (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <div class="dialog-header">
    <button mat-icon-button class="close-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <!-- Initial options view -->
  <div class="dialog-content" *ngIf="!selectedOption">
    <div class="header-section">
      <h2>Choose Your Test Creation Method</h2>
      <p>
        Select how you'd like to create your test cases: by training AI or
        letting AI assist you directly
      </p>
    </div>
    <ul class="options-list">
      <li [class.option--selected]="selectedOption === 'AITestcase'">
        <mat-icon>bolt</mat-icon>
        <div class="option__content">
          <h3>Let AI Help You Create Test Cases</h3>
          <p>
            Provide details, and let ContextQA generate optimized test cases for
            you instantly.
          </p>
          <button mat-flat-button color="primary" (click)="selectOption('AITestcase')">
            Start with AI Assistance
          </button>
        </div>
      </li>
      <li *ngIf="lockData?.featureSupport?.UploadExcel" [class.option--selected]="selectedOption === 'uploadDocument'">
        <mat-icon>description</mat-icon>
        <div class="option__content">
          <h3>Let Create Test Cases by uploding a document</h3>
          <p>
            Upload xlsx document and let ContextQA generate optimized test cases for
            you instantly.
          </p>
          <!-- <input type="file" /> -->
          <button mat-flat-button color="primary" (click)="selectOption('uploadDocument')">
            Upload Document
          </button>
        </div>
      </li>
      <li *ngIf="lockData?.type !== 'FREE'" [class.option--selected]="selectedOption === 'prebuilt'">
        <mat-icon>stacks</mat-icon>
        <div class="option__content">
          <h3>Record Your Actions to Train AI</h3>
          <p>
            Perform tasks in your app while ContextQA observes and learns to
            create smarter, automated test cases.
          </p>
          <button mat-flat-button color="primary" (click)="selectOption('prebuilt')">
            Start Recording
          </button>
        </div>
      </li>
    </ul>
  </div>

  <!-- URL form view for prebuilt option -->
  <div class="dialog-content" *ngIf="selectedOption === 'prebuilt'">
    <!-- Show when extension is NOT installed -->
    <div class="extension-warning" *ngIf="checkExtensionInstall">
      <h2 class="warning-title" style="text-align: center">
        Contextqa Recorder not detected
      </h2>
      <hr />
      <p>To start recording, install the Contextqa Recorder Chrome extension</p>
      <div class="steps-container">
        <div class="step-item">
          <div class="step-description">
            <span style="font-size: 12px">1. Install the Contextqa Recorder Chrome extension to easily
              record scenarios</span>
            <a [href]="
                'https://chromewebstore.google.com/detail/contextqa-test-automation/' +
                getExtensionId
              " target="_blank" class="install-button">
              <img width="48" height="48" src="https://img.icons8.com/color/48/chrome--v1.png" alt="chrome--v1"
                class="chrome-icon" />
              <!-- <img src="assets/images/chrome-icon.png" alt="Chrome" class="chrome-icon"> -->
              Install Chrome extension
            </a>
          </div>
        </div>

        <div class="divider"></div>

        <div class="step-item">
          <div class="step-description">
            <span style="font-size: 12px">2. Enable the Chrome extension in incognito mode as Contextqa
              Recorder requires a clean state to record your scenario</span>
          </div>
        </div>
      </div>
      <p style="
          text-align: center;
          color: red;
          padding-top: 20px;
          font-size: 12px;
        ">
        Note: After installing the extension, you can close this popup and
        reopen popup.
      </p>
    </div>

    <!-- Show when extension is installed -->
    <div class="extension-installed" *ngIf="!checkExtensionInstall">
      <h2 style="text-align: center">Create Test Case</h2>
      <p class="subtitle">
        Select or open a new tab to start capturing steps automatically
      </p>
      <form [formGroup]="urlForm" (ngSubmit)="onSubmit()">
        <div class="form-group" (click)="$event.stopPropagation()">
          <label class="control-label">Prerequisite</label>
          <app-auto-complete class="pb-2 d-block" placeholder="Select a prerequisite test case" [formGroup]="urlForm"
            [formCtrlName]="urlForm.controls['preRequisite']"
            [value]="getCurrentItem(testCaseList, testCase?.preRequisite)" [items]="testCaseList" [hasNone]="true"
            (onSearch)="fetchTestCases($event)" (onValueChange)="setPreRequisite($event)">
          </app-auto-complete>
        </div>
        <div class="url-input" [class.mat-focused]="isInputFocused" #urlInputContainer>
          <!-- <span class="url-prefix">https://</span> -->
          <input matInput [matAutocomplete]="auto" formControlName="url" placeholder="enter any public url"
            (focus)="isInputFocused = true" (blur)="isInputFocused = false" class="form-control" />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
            (optionSelected)="onOptionSelected($event)" class="minimal-autocomplete"
            [panelWidth]="urlInputContainer.offsetWidth">
            <mat-option *ngFor="let option of filteredUrls | async" [value]="option" class="minimal-option">
              <span class="option-text">{{ option.url }}</span>
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="btn-container">
          <button type="submit" class="create-test" [disabled]="!urlForm.valid || isLoading">
            <span *ngIf="!isLoading">Create Test Case</span>
            <div *ngIf="isLoading" class="loader"></div>
          </button>
        </div>

        <div class="manually-create-test-step" style="cursor: pointer" (click)="closeDialog()"
          [routerLink]="['/td', versionId, 'cases', 'create']"
          [queryParams]="testSuiteId ? { testSuiteId: testSuiteId } : null">
          <p style="color: #6366f1">Manually Create a Test Case</p>
        </div>
      </form>
    </div>
  </div>

  <!-- AITestcase view -->
  <div class="dialog-content" *ngIf="selectedOption === 'AITestcase'">
    <h2 style="text-align: center; padding-bottom: 35px">Create Test Case</h2>
    <form [formGroup]="aiTestcaseForm" (ngSubmit)="onSubmitAITestcase()">
      <div *ngIf="lockData?.type !== 'FREE'" class="form-group" (click)="$event.stopPropagation()">
        <label class="control-label">Prerequisite</label>
        <app-auto-complete class="pb-2 d-block" placeholder="Select a prerequisite test case"
          [formGroup]="aiTestcaseForm" [formCtrlName]="aiTestcaseForm.controls['preRequisite']"
          [value]="getCurrentItem(testCaseList, testCase?.preRequisite)" [items]="testCaseList" [hasNone]="true"
          (onSearch)="fetchTestCases($event)" (onValueChange)="setPreRequisite($event)">
        </app-auto-complete>
      </div>
      <div class="url-input" [class.mat-focused]="isAIInputFocused" #aiUrlInputContainer>
        <!-- <span class="url-prefix">https://</span> -->
        <input matInput [matAutocomplete]="aiAuto" formControlName="url" placeholder="Enter any public URL"
          (focus)="isAIInputFocused = true" (blur)="onAIUrlBlur($event)" class="form-control" />
        <mat-autocomplete #aiAuto="matAutocomplete" [displayWith]="displayFn"
          (optionSelected)="onAIOptionSelected($event)" class="minimal-autocomplete"
          [panelWidth]="aiUrlInputContainer.offsetWidth">
          <mat-option *ngFor="let option of aiFilteredUrls | async" [value]="option" class="minimal-option">
            <span class="option-text">{{ option.url }}</span>
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="error-message" *ngIf="aiTestcaseForm.get('url')?.errors?.['required'] &&
                            aiTestcaseForm.get('url')?.touched">
        Please enter a URL
      </div>
      <div class="error-message" *ngIf="aiTestcaseForm.get('url')?.errors?.['protocolRequired'] &&
                            aiTestcaseForm.get('url')?.touched">
        URL must start with http:// or https://
      </div>
      <div class="error-message" *ngIf="aiTestcaseForm.get('url')?.errors?.['invalidUrl'] &&
                            aiTestcaseForm.get('url')?.touched">
        Please enter a valid URL
      </div>
      <div class="form-group">
        <div [formGroup]="aiTestcaseForm" class="form-group ts-col-100">
          <div class="textarea-container position-relative">
            <textarea name="description"
              placeholder="Add any additional context or knowledge that should be used when creating a test. Example: 'Login with username fake_username and password fake_password and logout'..."
              id="description" height="150px" formControlName="description" class="form-control description-textarea">
            </textarea>
            <button
              *ngIf="aiTestcaseForm.get('description')?.value && (origin === 'dtest01' || origin === 'hyniva' || origin === 'halight')"
              type="button" class="suggestion-button animate-pulse" matTooltip="Improve User Story"
              (click)="AIConversationalPrompt()">
              <mat-icon class="suggestion-icon animate-sparkle">auto_awesome</mat-icon>
            </button>
          </div>
          <div class="error-message" *ngIf="aiTestcaseForm.get('description')?.errors?.['required'] &&
                                aiTestcaseForm.get('description')?.touched">
            Please provide a test description
          </div>
        </div>
      </div>

      <!-- New Advanced Settings Section -->
      <div class="advanced-settings"
        *ngIf="lockData?.featureSupport?.knowledgeBasePrompt || lockData?.featureSupport?.persona">
        <div class="settings-header" (click)="toggleAdvancedSettings()">
          <span class="settings-text">Advanced Settings <span
              style="font-size: 12px; color: #6366f1;">(Optional)</span></span>
          <mat-icon>{{ showAdvancedSettings ? 'expand_less' : 'expand_more' }}</mat-icon>
        </div>

        <div class="settings-content" *ngIf="showAdvancedSettings">
          <div class="settings-grid">

            <div class="form-group " *ngIf="lockData?.featureSupport?.knowledgeBasePrompt">
              <label class="control-label">Knowledge Base</label>
              <mat-form-field class="mat-select-custom w-100" appearance="fill">
                <mat-select disableOptionCentering formControlName="knowledgeBase" panelClass="single">
                  <mat-option [value]="'none'">None</mat-option>
                  <mat-option *ngFor="let prompt of prompts" [value]="prompt.value">
                    <div class="select-option">
                      <span>{{prompt.title}}</span>
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="form-group " *ngIf="lockData?.featureSupport?.persona">
              <label class="control-label">Persona</label>
              <mat-form-field class="mat-select-custom w-100" appearance="fill">
                <mat-select disableOptionCentering formControlName="persona" panelClass="single">
                  <mat-option [value]="'defalut'">Default</mat-option>
                  <mat-option *ngFor="let persona of customAgents?.response" [value]="persona.id">
                    <div class="select-option">
                      <span>{{persona.name}}</span>
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- <div class="form-group">
              <mat-form-field appearance="outline" class="custom-select" floatLabel="always">
                <mat-label>Knowledge Base</mat-label>
                <mat-select formControlName="knowledgeBase">
                  <mat-option *ngFor="let prompt of prompts" [value]="prompt.value">
                    <div class="select-option">
                      <span>{{prompt.title}}</span>
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
             -->
            <!-- <div class="form-group">
              <mat-form-field appearance="outline" class="custom-select" floatLabel="always">
                <mat-label>Persona</mat-label>
                <mat-select formControlName="persona">
                  <mat-option *ngFor="let persona of customAgents?.response" [value]="persona.id">
                    <div class="select-option">
=                      <span>{{persona.name}}</span>
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
          </div>
        </div>
      </div>

      <div class="btn-container">
        <button type="submit" class="create-test"
          [disabled]="!aiTestcaseForm.valid || isLoading || !aiTestcaseForm.get('url')?.value">
          <span *ngIf="!isLoading">Generate Test Case</span>
          <div *ngIf="isLoading" class="loader"></div>
        </button>
      </div>

      <!-- <div class="ai-info"><p style="font-size: 15px;">ContextQA uses AI to perform actions. AI can make mistakes. <a href="https://contextqa.com/ai-test-automation-mistakes" target="_blank" rel="noopener noreferrer">Click here for more info.</a>
            </p></div> -->
    </form>
    <div class="actions-grid">
      <h3 style="text-align: center; padding: 15px 0 0 0">Try This Scenario</h3>
      <ul>
        <li *ngFor="let action of actions" class="action-card cursor-pointer" (click)="patchValue(action)">
          <div class="option__content">
            <h3>{{ action.title }}</h3>
            <p>{{ action.description }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <!-- URL form view for document upload -->
  <div class="dialog-content" *ngIf="selectedOption === 'uploadDocument'">
    <div class="extension-installed">
      <div class="mode-selector">
        <div class="mode-option" [class.active]="!isRequirementsMode" (click)="selectMode(false)">
          <mat-icon>description</mat-icon>
          <span>Test Case Mode</span>
        </div>
        <div class="mode-option" [class.active]="isRequirementsMode" (click)="selectMode(true)">
          <mat-icon>assignment</mat-icon>
          <span>Requirements Mode</span>
        </div>
      </div>
      <h2 style="text-align: center">Upload {{ isRequirementsMode ? 'Requirements' : 'Test Cases' }}</h2>
      <p class="subtitle">
        {{ isRequirementsMode ? 'Upload requirements document to generate test cases based on requirements.' : 'Upload
        xlsx document and let ContextQA generate optimized test cases for you instantly.' }}
      </p>
      <!-- Add sample file link -->
      <div *ngIf="!isRequirementsMode" class="sample-file-info">
        <p>Please ensure your {{ isRequirementsMode ? 'CSV' : 'Excel' }} file follows our required format.</p>
        <a [href]="isRequirementsMode ? SAMPLE_CSV_URL : SAMPLE_XLSX_URL" target="_blank" class="sample-link">
          <mat-icon>download</mat-icon>
          Download Sample Format
        </a>
      </div>
      <form [formGroup]="documentForm" (ngSubmit)="onDocumentSubmit()">
        <div class="file-upload-container">
          <input type="file" #fileInput (change)="onFileSelected($event)"
            [accept]="isRequirementsMode ? '.csv' : '.xlsx,.xls'" style="display: none" formControlName="document">

          <div class="upload-box" (click)="fileInput.click()" [class.has-file]="selectedFileName">
            <mat-icon>cloud_upload</mat-icon>
            <p *ngIf="!selectedFileName">Drag and drop your {{ isRequirementsMode ? 'CSV' : 'XLSX' }} file here or click
              to browse</p>
            <p *ngIf="selectedFileName">{{ selectedFileName }}</p>
          </div>

          <div class="error-message" *ngIf="documentForm.get('document')?.errors?.['required'] &&
                                          documentForm.get('document')?.touched">
            Please select a document to upload
          </div>
        </div>

        <div class="btn-container">
          <button type="submit" class="create-test" [disabled]="!documentForm.valid || isLoading">
            <span *ngIf="!isLoading">Create Test Cases</span>
            <div *ngIf="isLoading" class="loader"></div>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>