import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-url-warning-dialog',
  template: `
    <h2 mat-dialog-title class="warning-title">{{data.title}}</h2>
    <mat-dialog-content class="warning-content" style="padding: 7px 48px !important;">
      <p class="warning-message">{{data.message}}</p>
      <ul class="url-list">
        <li *ngFor="let url of data.urls" class="url-item">
          <mat-icon class="warning-icon">warning</mat-icon>
          <span class="url-text">{{url}}</span>
        </li>
      </ul>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-raised-button class="cancel-button" [mat-dialog-close]="true">Cancel</button>
    </mat-dialog-actions>
  `,
  styles: [`
    .warning-title {
      color: #6366f1;
      margin-bottom: 16px;
    }
    .warning-content {
      min-width: 300px;
      max-width: 500px;
    }
    .warning-message {
      margin-bottom: 16px;
      color: rgba(0, 0, 0, 0.87);
    }
    .url-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    .url-item {
      display: flex;
      align-items: center;
      padding: 8px;
      margin-bottom: 8px;
      background-color: #fff3e0;
      border-radius: 4px;
    }
    .warning-icon {
      color: #ff9800;
      margin-right: 8px;
    }
    .url-text {
      word-break: break-all;
    }
    .cancel-button {
      background-color: #6366f1;
      color: #fff;
      transition: all 0.3s ease;
    }
    .cancel-button:hover {
      background-color: #fff !important;
      color: #6366f1 !important;
      border: 2px solid #6366f1 !important;
    }
  `]
})
export class UrlWarningDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<UrlWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string;
      message: string;
      urls: string[];
    }
  ) {}
} 