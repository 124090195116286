import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserPreferenceService } from "../services/user-preference.service";
import { AuthenticationGuard } from "../shared/guards/authentication.guard";
import { WorkspaceVersion } from "../models/workspace-version.model";
import { WorkspaceVersionService } from "../shared/services/workspace-version.service";
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from "../shared/components/base.component";
import { OnBoardingSharedService } from "../services/on-boarding-shared.service";
import { MatDialog } from "@angular/material/dialog";
import { TelemetryNotificationComponent } from "./webcomponents/telemetry-notification.component";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { LicenceSevices } from "../shared/services/license.service";
import { CdkConnectedOverlay } from "@angular/cdk/overlay";
import { TestCaseTag } from 'app/models/test-case-tag.model';
import { TestCaseTagService } from 'app/services/test-case-tag.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModelContentComponent } from '../../app/components/model/model-content/model-content.component';
import { FirstChromeInstallComponent } from './webcomponents/first-chrome-install.component';
import { ChromeExtensionService } from '../services/chrome.service';
import { WelcomeDialogComponent } from './welcome-dialog.component';

declare var chrome: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [
    `.credit-usage-card {
      background: white;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }

    .progress {
      height: 8px;
      background-color: #E5E7EB;
      border-radius: 4px;
      overflow: hidden;
    }

    .progress-bar {
      background-color: #6366F1;
      border-radius: 4px;
    }

    .text-muted {
      color: #6B7280;
      font-size: 14px;
    }

    .h3 {
      font-size: 24px;
      font-weight: bold;
      margin-top: 4px;
    }
    `
  ]
})
export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy {
  public version: WorkspaceVersion;
  @ViewChild('shareFeedBackBtn') public shareFeedBackBtn: ElementRef;
  @ViewChild('sortByOptions') overlayDir: CdkConnectedOverlay;
  showTelemetryNotification: boolean;
  Isinstalled: boolean = false;
  lastRun: any;
  private isDialogOpen = false;
  lastRunId: string | null = null;
  iframeURL: string;
  iframeURLNew: any;
  lockData: any;
  IsUpgradeBtn: boolean;
  openFilter: boolean;
  cqaOrigin: string
  IsManagePlanBtn: boolean;
  filterByValue: string;
  isExtensionInstalled: boolean;
  private extensionId = 'pgadpooodaipbhbchnojijmlkhpamadh';
  checkExtensionInstall: boolean = true;
  private clickedOnFilterToClose: boolean;
  selectedValue: string = 'LAST_90_DAYS'; // Set 'SUCCESS' as the default value
  baseUrl: string;
  public tags: TestCaseTag[];
  public filterTagIds: number[];
  public createdDateRange = new FormGroup({
    start: new FormControl('', Validators.required),
    end: new FormControl('', Validators.required)
  });
  maxDate = new Date();
  showWelcomePopup = true;

  constructor(
    public authGuard: AuthenticationGuard,
    public route: ActivatedRoute,
    private router: Router,
    private versionService: WorkspaceVersionService,
    private userPreferenceService: UserPreferenceService,
    public onBoardingSharedService: OnBoardingSharedService,
    public matModal: MatDialog,
    private chromeExtensionService: ChromeExtensionService,
    private sanitizer: DomSanitizer,
    public LicenceSevices: LicenceSevices,
    private testCaseTagService: TestCaseTagService

  ) {
    super(authGuard);
    this.route.queryParams.subscribe(params => {
      this.showTelemetryNotification = params['showTelemetryNotification'];
    });
    this.fetchTags();
    this.checkExtension();
    // this.openFirstInstallChrome();
  }

  createCase() {
    if (!this.isDialogOpen) {
      const dialogRef = this.matModal.open(ModelContentComponent, {
        width: '700px',
        data: {},
      });

      this.isDialogOpen = true;

      dialogRef.afterClosed().subscribe(() => {
        this.isDialogOpen = false;
      });
    }
  }

  
  async checkExtension() {
    this.isExtensionInstalled =
      await this.chromeExtensionService.isExtensionInstalled(this.extensionId);
    if (this.isExtensionInstalled) {
      this.checkExtensionInstall = false;
    }else{
      this.checkExtensionInstall = true;
    }
    console.log("checkExtensionInstall ::", this.checkExtensionInstall)
  }

  ngOnDestroy() {
    this.onBoardingSharedService.setLastRunId(null);
  }

  ngOnInit(): void {
    console.log("checkExtensionInstall ::", this.checkExtensionInstall)
    this.LicenceSevices.lockData$.subscribe(data => {
      if (data) {
        this.lockData = data;
        console.log("lockData DASHBOARD::", this.lockData)
        if (this.lockData && this.lockData.subscribed == false) {
          this.IsUpgradeBtn = true
          this.IsManagePlanBtn = false
        } else {
          this.IsUpgradeBtn = false
          this.IsManagePlanBtn = true
        }
      }
    });
    this.fetchVersion();
    if (this.showTelemetryNotification) {
      const dialogRef = this.matModal.open<TelemetryNotificationComponent>(TelemetryNotificationComponent, {
        width: '33%',
        position: { top: '25px', right: '25px' },
        panelClass: ['mat-dialog', 'border-rds-6', 'border-active-t-5']
      });
      dialogRef.afterClosed().subscribe(() => {
        this.router.navigate(['dashboard']);
      })
    }
    let data = {
      jwt: localStorage.getItem("_t"),
      origin : window.location.hostname.split('.')[0],
      userEmail: localStorage.getItem('useremail')
    };
    chrome.runtime.sendMessage('pgadpooodaipbhbchnojijmlkhpamadh', { message: "getStatus", data: data })
    this.onBoardingSharedService.lastRunId$.subscribe((data) => {
      if (data) {
        this.lastRunId = data;
        this.iframeURL = window.location.href;
        const url = new URL(this.iframeURL);
        this.iframeURL = url.origin;
        this.iframeURL = this.iframeURL + "/td/test_case_results/";
        this.iframeURL = this.iframeURL + data;
        this.iframeURLNew = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeURL);
        // Temporarily disable Angular routing
        // Assign the SafeResourceUrl to the iframeURLNew property
        // Re-enable Angular routing after a short delay
        setTimeout(() => {
          window.history.replaceState({}, '', this.router.url);
        }, 2000);
      }
    });

    // this.pushToParent(this.route, this.route.params);
  }

  getTimeinMinutefromsec(timeInms = 1) {
    let timestamp = timeInms;  // Convert milliseconds to seconds
    let minutes = timestamp / 60;  // Calculate total minutes
    return Math.round(minutes);
  }
  fetchVersion() {
    this.userPreferenceService.show().subscribe(res => {
      if (res?.versionId) {
        this.versionService.show(res.versionId).subscribe(res => {
          this.version = res;
        }, err => this.loadDemoVersion());
      } else if (res?.projectId) {
        this.versionService.findAll("projectId:" + res.projectId).subscribe(versions => {
          this.version = versions.content[0];
        }, () => {
          this.loadDemoVersion();
        })
      } else {
        this.loadDemoVersion();
      }
    });
  }


  loadDemoVersion() {
    this.versionService.findAll("isDemo:true").subscribe(versions => {
      this.version = versions.content[0];
    })
  }

  get testsigmaOSEnabled() {
    return this.authGuard?.openSource?.isEnabled;
  }

  filter(filterByValue: string) {
    // console.log(filterByValue)
  }

  openFilterByOptions(event: Event) {
    // event.preventDefault();
    // event.stopPropagation();
    if (this.clickedOnFilterToClose) {
      this.clickedOnFilterToClose = false;
      return;
    }
    this.openFilter = true;
    setTimeout(() => {
      this.overlayDir.overlayRef._outsidePointerEvents.subscribe(res => {
        this.overlayDir.overlayRef.detach();
        // if (this.eventFromFilterButton(res))
        //   this.clickedOnFilterToClose = true;
        // else
        //   this.clickedOnFilterToClose = false;
        this.openFilter = false;
      });
    }, 200);
  }

  onSelectChange() {
    if (this.selectedValue != 'CUSTOM') {
      const filterObj = {
        tags: this.filterTagIds,
        createdDateRange: this.createdDateRange,
        customDays: this.selectedValue
      }
      this.LicenceSevices.setDayFilter(filterObj);
    }
  }

  onSelectTag() {
    const filterObj = {
      tags: this.filterTagIds,
      createdDateRange: this.createdDateRange,
      customDays: this.selectedValue
    }
    this.LicenceSevices.setDayFilter(filterObj);
  }

  applyCustomFilter() {
    if (this.createdDateRange.status === 'VALID') {
      const filterObj = {
        tags: this.filterTagIds,
        createdDateRange: this.createdDateRange,
        customDays: this.selectedValue
      }
      this.LicenceSevices.setDayFilter(filterObj);
    }
  }

  clearFilter() {
    this.createdDateRange.reset();
    this.createdDateRange.updateValueAndValidity();
    const filterObj = {
      tags: this.filterTagIds,
      createdDateRange: this.createdDateRange,
      customDays: 'TODAY'
    }
    this.LicenceSevices.setDayFilter(filterObj);
  }

  installPlugin() {
    window.open("https://chromewebstore.google.com/detail/contextqa-test-automation/pgadpooodaipbhbchnojijmlkhpamadh", "_blank");
  }

  private fetchTags(): void {
    this.testCaseTagService.findAll(undefined).subscribe(res => {
      this.tags = res;
    });
  }

  constructQueryString() {

  }

  dateInvalid(DateRange) {
    return ((DateRange.controls.start.value || DateRange.controls.start.errors?.matDatepickerParse?.text) ||
      (DateRange.controls.end.value || DateRange.controls.end.errors?.matDatepickerParse?.text)) &&
      DateRange.invalid;
  }

  openFirstInstallChrome() {
    this.matModal.open(FirstChromeInstallComponent, {
      width: '512px',
      position: { left: '80px', bottom: '60px' },
      panelClass: ['mat-overlay', 'onboarding-help-container'],
      disableClose: true
    });
  }

  closeWelcomePopup() {
    this.showWelcomePopup = false;
    localStorage.setItem('welcomePopupShown', 'true');
  }

  private showWelcomeDialog(): void {
    const dialogRef = this.matModal.open(WelcomeDialogComponent, {
      width: '100%',
      maxWidth: '680px',
      panelClass: 'welcome-dialog-container',
      disableClose: true // Prevents closing by clicking outside or pressing ESC
    });

    dialogRef.afterClosed().subscribe(() => {
      // Handle any actions after dialog is closed if needed
      console.log('Welcome dialog was closed');
    });
  }
}
