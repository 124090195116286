import { ModelContentComponent } from "../model/model-content/model-content.component";

export interface ChatbotAction {
  type: 'dialog' | 'navigation' | 'function' | 'document';
  payload: {
    component?: any;
    dialogConfig?: any;
    route?: string;
    callback?: () => void;
    link?: string;
  };
}

interface BaseMessage {
  sentBy: "bot" | "user";
  timestamp?: Date;
  action?: ChatbotAction;
}

interface TextMessage extends BaseMessage {
  type: "text";
  text: string;
}

interface ImageMessage extends BaseMessage {
  type: "image";
  text?: string;
  image: string;
}

interface VideoMessage extends BaseMessage {
  type: "video";
  text?: string;
  video: string;
}

interface TextQuestion {
  type: "text";
  label: string;
}

interface NumberQuestion {
  type: "number";
  label: string;
}

export interface SelectOption {
  text: string;
  value: string;
  action?: ChatbotAction;
  route?: string;
  next?: string;
  requiresTestCase?: boolean;
  followUpMessage?: string;
  description?: string;
}

interface SelectQuestion {
  type: "select";
  options: SelectOption[];
}

interface InputQuestion {
  type: "input";
  label: string;
  placeholder?: string;
  isAutocomplete?: boolean;
  validation?: {
    required?: boolean;
    pattern?: string;
    errorMessage?: string;
  };
}

interface QuestionMessage extends BaseMessage {
  type: "question";
  text: string;
  id: string;
  question: TextQuestion | NumberQuestion | SelectQuestion | InputQuestion;
}

export type Message =
  | TextMessage
  | ImageMessage
  | VideoMessage
  | QuestionMessage;

export const MESSAGES: Message[] = [
  {
    id: "main_menu",
    type: "question",
    text: "Choose a step to get started:",
    sentBy: "bot",
    question: {
      type: "select",
      options: [
        {
          text: "🚀 Step 1: Test Creation & Organization",
          value: "creation",
          next: "creation_options",
          description: "Create and organize your test suite using AI assistance. I can help you generate test cases based on your requirements, prioritize tests by impact, and organize them into logical test plans. Perfect for starting a new project or expanding test coverage."
        },
        {
          text: "⚡ Step 2: Test Execution & Optimization",
          value: "execution",
          next: "execution_options",
          description: "Run tests efficiently with automated scheduling and parallel execution. I can help optimize test sequences, reduce redundancy, and identify the most critical tests to run first. Ideal for teams looking to speed up their testing cycle."

        },
        {
          text: "📊 Step 3: Results & Analysis",
          value: "results",
          next: "results_options",
          description: "Analyze test results to identify patterns, recurring issues, and improvement opportunities. I can generate detailed reports, highlight critical bugs, and suggest fixes based on similar past issues in your codebase."

        }
      ]
    }
  },
  {
    id: "creation_options",
    type: "question",
    text: "What would you like to do?",
    sentBy: "bot",
    question: {
      type: "select",
      options: [
        {
          text: "🔨 Create a Test Case",
          value: "create_test",
          next: "test_creation_type",
          description: ""
        },
        {
          text: "📂 Arrange Your Test Suite",
          value: "organize",
          route: "/td/:versionId/suites",
          description: ""

        },
        {
          text: "🔌 Integrations",
          value: "integrations",
          route: "/settings/plugs",
          description: ""

        }
      ]
    }
  },
  {
    id: "test_creation_type",
    type: "question",
    text: "How would you like to create your test case?",
    sentBy: "bot",
    question: {
      type: "select",
      options: [
        {
          text: "1️⃣ AI-Generated Test 🤖",
          value: "ai_test",
          action: {
            type: 'dialog',
            payload: {
              component: ModelContentComponent,
              dialogConfig: {
                width: '700px',
                data: {}
              }
            }
          }
        },
        {
          text: "2️⃣ Record a Test 🎥",
          value: "record_test",
          action: {
            type: 'dialog',
            payload: {
              component: ModelContentComponent,
              dialogConfig: {
                width: '700px',
                data: {}
              }
            }
          }
        },
        {
          text: "3️⃣ Manual Test Steps 📝",
          value: "manual_test",
          route: "/td/:versionId/cases/create"
        }
      ]
    }
  },
  {
    id: "execution_options",
    type: "question",
    text: "What would you like to do?",
    sentBy: "bot",
    question: {
      type: "select",
      options: [
        {
          text: "▶️ Execute Tests",
          value: "execute_tests",
          next: "execution_input",
          requiresTestCase: true,
          description: "test"

        },
        {
          text: "⚙️ Apply Best Practices",
          value: "best_practices",
          next: "execution_input",
          requiresTestCase: true,
          followUpMessage: "best_practices_options",
          description: "test"

        },
        {
          text: "🛠️ Advanced Testing Options",
          value: "advanced",
          next: "execution_input",
          requiresTestCase: true,
          followUpMessage: "advanced_options",
          description: "test"

        }
      ]
    }
  },
  {
    id: "execution_type",
    type: "question",
    text: "How do you want to run your tests?",
    sentBy: "bot",
    question: {
      type: "select",
      options: [
        {
          text: "1️⃣ Single Run (Default Chrome browser) 🌍",
          value: "single_run",
          action: {
            type: 'document',
            payload: {
              link: 'https://learning.contextqa.com/contextqa-portal/test-development-section/run/run-testcase'
            }
          }
        },
        {
          text: "2️⃣ Parallel Execution (Faster run time) ⚡",
          value: "parallel_run",
          action: {
            type: 'document',
            payload: {
              link: 'https://learning.contextqa.com/advance-features/parallel-test-execution'
            }
          }
        },
        {
          text: "3️⃣ Cross-Browser Execution (Run on multiple browsers) 🔀",
          value: "cross_browser",
          action: {
            type: 'document',
            payload: {
              link: 'https://learning.contextqa.com/getting-started-with-contextqa/advance-testing/cross-browser-testing'
            }
          }
        }
      ]
    }
  },
  {
    type: "text",
    text: "🚀 Step 1: Test Creation & Organization",
    sentBy: "user",
  },
  {
    type: "text",
    text: "⚡ Step 2: Test Execution & Optimization",
    sentBy: "user",
  },
  {
    type: "text",
    text: "📊 Step 3: Results & Analysis",
    sentBy: "user",
  },
  {
    id: "results_options",
    type: "question",
    text: "What would you like to do?",
    sentBy: "bot",
    question: {
      type: "select",
      options: [
        {
          text: "📊 View & Analyze Results",
          value: "view_results",
          next: "view_results_options"
        },
        {
          text: "📈 Insights & Reports",
          value: "reports",
          next: "reports_options"
        },
        {
          text: "🤖 AI Assistance & Help",
          value: "ai_help",
          next: "help_options"
        }
      ]
    }
  },
  {
    id: "organize_options",
    type: "question",
    text: "Do you want to organize your test cases?",
    sentBy: "bot",
    question: {
      type: "select",
      options: [
        {
          text: "1️⃣ Move tests to a folder 📁",
          value: "move_tests",
          route: "/td/:versionId/suites"
        },
        {
          text: "2️⃣ Create a new folder ➕",
          value: "create_folder",
          route: "/td/:versionId/suites/new"
        },
        {
          text: "3️⃣ Skip this step ⏭️",
          value: "skip_organize"
        }
      ]
    }
  },
  {
    id: "view_results_options",
    type: "question",
    text: "Test execution complete! What would you like to do?",
    sentBy: "bot",
    question: {
      type: "select",
      options: [
        {
          text: "1️⃣ View reports (Functional, PDF, Excel) 📊",
          value: "view_reports",
          next: "view_reports_options",
          action: {
            type: 'document',
            payload: {
              link: 'https://learning.contextqa.com/contextqa-portal/bug-report'
            }
          }
        },
        {
          text: "2️⃣ Run again  🔁",
          value: "run_again",
          next: "execution_type"
        },
        {
          text: "3️⃣ Optimize test with best practices 🛠️",
          value: "optimize",
          next: "best_practices_options"
        }
      ]
    }
  },
  {
    id: "best_practices_options",
    type: "question",
    text: "Want to improve test efficiency? Choose an option:",
    sentBy: "bot",
    question: {
      type: "select",
      options: [
        {
          text: "1️⃣ Step Groups (Organize test steps) 📑",
          value: "step_groups",
          action: {
            type: 'document',
            payload: {
              link: 'https://learning.contextqa.com/contextqa-portal/test-development-section/run/run-testcase'
            }
          }
        },
        {
          text: "2️⃣ Data-Driven Testing (Run with multiple inputs) 🔄",
          value: "data_driven",
          action: {
            type: 'document',
            payload: {
              link: 'https://learning.contextqa.com/getting-started-with-contextqa/data-driven-testing-in-contextqa'
            }
          }
        },
        {
          text: "3️⃣ Parameterization (Use dynamic variables) 🔧",
          value: "parameterization",
          action: {
            type: 'document',
            payload: {
              link: 'https://learning.contextqa.com/advance-features/parameterization'
            }
          }
        }
      ]
    }
  },
  {
    id: "advanced_options",
    type: "question",
    text: "Do you need advanced testing?",
    sentBy: "bot",
    question: {
      type: "select",
      options: [
        {
          text: "1️⃣ Performance Testing ⚡",
          value: "performance",
          action: {
            type: 'document',
            payload: {
              link: 'https://learning.contextqa.com/advance-features/performance-testing'
            }
          }
        },
        {
          text: "2️⃣ Accessibility Testing ♿",
          value: "accessibility",
          action: {
            type: 'document',
            payload: {
              link: 'https://learning.contextqa.com/advance-features/accessibility-testing'
            }
          }
        },
        {
          text: "3️⃣ No, proceed with standard execution ⏭️",
          value: "standard"
        }
      ]
    }
  },
  {
    id: "integration_options",
    type: "question",
    text: "Want to connect with external tools?",
    sentBy: "bot",
    question: {
      type: "select",
      options: [
        {
          text: "1️⃣ JIRA (Bug tracking) 🐞",
          value: "jira",
          route: "/settings/plugs/bug_report"
        },
        {
          text: "2️⃣ Slack (Notifications & alerts) 🔔",
          value: "slack",
          route: "/settings/plugs/bug_report"
        },
        {
          text: "3️⃣ GitHub (Sync test repositories) 💾",
          value: "github",
          route: "/settings/plugs"
        }
      ]
    }
  },
  {
    id: "reports_options",
    type: "question",
    text: "Need deeper test analysis?",
    sentBy: "bot",
    question: {
      type: "select",
      options: [
        {
          text: "1️⃣ View Insights 🔍",
          value: "critical_paths",
          action: {
            type: 'document',
            payload: {
              link: 'https://learning.contextqa.com/getting-started-with-contextqa/quality-insights'
            }
          }
        },
        // {
        //   text: "2️⃣ Check heatmaps 🔥",
        //   value: "heatmaps",
        //   action: {
        //     type: 'document',
        //     payload: {
        //       link: 'https://learning.contextqa.com/getting-started-with-contextqa/quality-insights'
        //     }
        //   }
        // },
        {
          text: "3️⃣ View failed test cases ❌",
          value: "failed_cases",
          action: {
            type: 'document',
            payload: {
              link: 'https://learning.contextqa.com/contextqa-portal/test-development-section/run/run-history'
            }
          }
        }
      ]
    }
  },
  {
    id: "help_options",
    type: "question",
    text: "Need any help?",
    sentBy: "bot",
    question: {
      type: "select",
      options: [
        {
          text: "1️⃣ Show interactive tutorial 🎥",
          value: "tutorial",
          action: {
            type: 'document',
            payload: {
              link: 'https://learning.contextqa.com/contextqa-portal'
            }
          }
        },
        {
          text: "2️⃣ View step-by-step documentation 📖",
          value: "documentation",
          action: {
            type: 'document',
            payload: {
              link: 'https://learning.contextqa.com/contextqa-portal'
            }
          }
        },
        {
          text: "3️⃣ No, I'm good! Continue 🚀",
          value: "continue"
        }
      ]
    }
  },
  {
    id: "execution_input",
    type: "question",
    text: "Search and select a Test Case to proceed:",
    sentBy: "bot",
    question: {
      type: "input",
      label: "Test Case",
      placeholder: "Search test cases...",
      isAutocomplete: true,
      validation: {
        required: true,
        errorMessage: "Please select a valid test case"
      }
    }
  }
];



export const TEST_LIVE_EXECUTION = [{
  id: "test_live_execution",
  type: "question",
  text: "Choose an action to proceed:",
  sentBy: "bot",
  question: {
    type: "select",
    options: [
      { text: "📄 Run This Test Case", value: "RunTestCase", next: "Run_Test_Case" },
      { text: "📝 Show Me Previous Run History", value: "RunHistory", next: "Run_History" },
      { text: "🔄 Apply Testing Best Practices", value: "TestingPractices", next: "Testing_Practices" },
    ]
  }
},
  // {
  //   id: "test_live_execution",
  //   type: "question",
  //   text: "More suggestions:",
  //   sentBy: "bot",
  //   question: {
  //     type: "select",
  //     options: [
  //       { text: "🏠 Take me to Home Screen", value: "navigateHome", next: "home_screen", route: "/assistant" }
  //     ]
  //   }
  // }

]
export const RUN_RESULT = [{
  id: "run_result",
  type: "question",
  text: "Choose an action to proceed:",
  sentBy: "bot",
  question: {
    type: "select",
    options: [
      { text: "📄 Show Me Report ", value: "RunResult", next: "Run_Result" },]
  }

}]
export const TEST_LIVE_EXECUTIONS = [{
  id: "test_live_executions",
  type: "question",
  text: "Choose an action to proceed:",
  sentBy: "bot",
  question: {
    type: "select",
    options: [
      { text: "📄 Close This And Take Me To The Dashboard ", value: "GoToDashboard", next: "Go_TO_Dashboard", route: '/dashboard' },
      { text: "📖 Show Me Result", value: "ShowMeResult", next: "Show_result" },
      { text: "🚀 Help  Me To Debug", value: "HelpToDebug", next: "Help_To_Debug" }]
  }

}]


export const MESSAGE_MAP = MESSAGES.reduce((acc, message) => {
  if ('id' in message) {
    acc[message.id] = message;
  }
  return acc;
}, {} as Record<string, Message>);

export const FOLLOW_UP_MESSAGES: Record<string, string> = {
  'execute_tests': 'execution_type',
  'best_practices': 'best_practices_options',
  'advanced': 'advanced_options'
};

export const TEST_CASE_CONFIRMATION_MESSAGES: Record<string, string> = {
  'execute_tests': 'Test Case {id} selected. How would you like to execute it?',
  'best_practices': 'Test Case {id} selected. Let\'s optimize it with best practices:',
  'advanced': 'Test Case {id} selected. Choose advanced testing options:'
};
