import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-logs-expanded-dialog',
    templateUrl: './logs-expanded-dialog.component.html',
    styleUrls: ['./logs-expanded-dialog.component.scss']
})
export class LogsExpandedDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<LogsExpandedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    copyAllLogs(): void {
        if (!this.data.logs || this.data.logs.length === 0) {
            return;
        }

        const logText = this.data.logs
            .map(log => {
                const cleanedText = log.text.replace(/^step\s*\d*\s*:?\s*/i, '');
                return `[${this.formatLogTimestamp(log.timestamp)}] ${cleanedText}`;
            })
            .join('\n');

        navigator.clipboard.writeText(logText).then(() => {
            console.log('All logs copied to clipboard');
        }, (err) => {
            console.error('Could not copy logs: ', err);
        });
    }

    private formatLogTimestamp(timestamp: Date): string {
        if (!timestamp) return '';
        return new Date(timestamp).toISOString().substr(11, 12); // Format as HH:MM:SS.sss
    }
}
