<!-- <div class="dashboard-section-title d-flex justify-content-center" [translate]="'dashboard.test_cases.total'"></div>
<div class="">
  <div class="highChart theme-w-o-h-scroll x-sm-h ts-col-100  d-flex justify-content-center" style="margin-top: 10px">
    <app-by-summary-donut-chart style="margin-right: 16px" *ngIf="entity?.length" [width]="230" [height]="250"
      [entity]="entity"></app-by-summary-donut-chart>
    <div *ngIf="!entity?.length" class="empty-full-container-transparent-bg">
      <div class="empty-full-content">
        <div class="empty-run-xs"></div>
        <div class="text-t-secondary pt-30 pb-18" [translate]="'dashboard.test_cases.empty'"></div>
      </div>
    </div>
  </div>
  <div [class.d-flex]="entity?.length < 3" style="display: flex; justify-content: center; margin-top: 10px"
    class="legend-container overflow-auto align-item-center display-flex">
    <div *ngFor="let legend of entity" style="margin-right: 5px; cursor: default"
      class=" legend-item align-items-center">
      <span class="rounded-circle d-inline-block p-4 img-wh sm-wh" [ngStyle]="{background:
      legend['color']}" [matTooltip]="legend['name']">&nbsp;</span>
      <span class="legend-y-text" [textContent]="legend['y']"></span>
      <span [textContent]="legend['name']">&nbsp;</span>
    </div>
  </div>
</div> -->
<div class="latest-run-card ts-col-100">
  <h6 [translate]="'dashboard.test_cases.total'"></h6>
  <div class="d-flex flex-wrap justify-content-center">
    <div class="ts-col-100">
      <app-by-summary-donut-chart *ngIf="entity?.length" [width]="230" [height]="250"
        [entity]="entity"></app-by-summary-donut-chart>
      <div *ngIf="!entity?.length" class="empty-full-container-transparent-bg p-12">
        <div class="empty-full-content">
          <div class="empty-run-xs"></div>
          <div class="text-t-secondary pt-30 pb-18" [translate]="'dashboard.test_cases.empty'"></div>
        </div>
      </div>
    </div>

    <div [class.d-flex]="entity?.length < 3" style="display: flex; justify-content: center; margin-top: 10px"
      class="legend-container overflow-auto align-item-center display-flex">
      <div *ngFor="let legend of entity" style="margin-right: 5px; cursor: default"
        class=" legend-item align-items-center">
        <span class="rounded-circle d-inline-block p-4 img-wh sm-wh" [ngStyle]="{background:
      legend['color']}" [matTooltip]="legend['name']">&nbsp;</span>
        <span class="legend-y-text" [textContent]="legend['y']"></span>
        <span [textContent]="legend['name']">&nbsp;</span>
      </div>
    </div>
  </div>
</div>