<div class="secondary-menu h-100">
  <ul id="ul_version_home" class="item-container">
    <li class="ml-20 mt-10">
      <ng-container *ngIf="!lockData?.workspace">
        <app-common-button label=" Workspace"></app-common-button> 
      </ng-container>
    </li>
    <app-workspace-switcher class="position-relative" [leftNavPath]="detailsAliasName" (onProjectSwitch)="closeDialog()"
      [version]="version" *ngIf="lockData?.workspace"></app-workspace-switcher>
    <div class="secondary-nav-container">
      <span [textContent]="'td_nav.design_development' | translate" class="category-header">
      </span>
      <li (click)="closeDialog()" [class.active]="detailsAliasName == 'cases' || detailsAliasName == 'step_groups'"
        [routerLink]="['/td', versionId, 'cases']">
        <a [class.active]="detailsAliasName == 'cases' || detailsAliasName == 'step_groups'"
          [routerLink]="['/td', versionId, 'cases']" class="nav-items">
          <i class="fa-test-cases-alt"></i>
          <span [textContent]="'td_nav.test_case' | translate"></span>
        </a>
      </li>
      <!-- <li (click)="closeDialog()" [class.active]="detailsAliasName == 'analytics'"
          [routerLink]="['/td', versionId, 'analytics']">
        <a [routerLinkActive]="'active'" [routerLink]="['/td', versionId, 'analytics']" class="nav-items">
          <i class="fa-action-text"></i>
          <span [textContent]="'td_nav.analytics' | translate"></span>
        </a>
      </li> -->
      <li class="ml-6">
        <ng-container *ngIf="!lockData?.elementDirectory">
          <app-common-button label="Elements" customClass="lockDataBtnSidebar"></app-common-button> 
        </ng-container>
      </li>
      <li (click)="closeDialog()" [class.active]="detailsAliasName == 'elements'" *ngIf="!version?.workspace?.isRest && lockData?.elementDirectory"
        [routerLink]="['/td', versionId, 'elements']" [routerLinkActive]="'active'">
        <a [class.active]="detailsAliasName == 'elements'" [routerLink]="['/td', versionId, 'elements']"
          [routerLinkActive]="'active'" class="nav-items">
          <i class="fa-elements-alt"></i>
          <span [textContent]="'td_nav.elements' | translate"></span>
        </a>
      </li>

      <li class="ml-6">
        <ng-container *ngIf="!lockData?.testDataProfile">
          <app-common-button label="Test Data Profile" customClass="lockDataBtnSidebar"></app-common-button> 
        </ng-container>
      </li>
      <li (click)="closeDialog()" [class.active]="detailsAliasName == 'data'" *ngIf="lockData?.testDataProfile" [routerLink]="['/td', versionId , 'data']"
        [routerLinkActive]="'active'">
        <a [class.active]="detailsAliasName == 'data'" [routerLink]="['/td', versionId , 'data']"
          [routerLinkActive]="'active'" class="nav-items">
          <i class="fa-test-data-alt"></i>
          <span [textContent]="'td_nav.test_data_profile' | translate"></span>
        </a>
      </li>
      <li class="ml-6">
        <ng-container *ngIf="!lockData?.mobileInspector">
          <app-common-button label="Mobile Inspector" customClass="lockDataBtnSidebar"></app-common-button> 
        </ng-container>
      </li>
      <li (click)="closeDialog()" [class.active]="detailsAliasName == 'mobile-inspector'"
        [routerLink]="['/td', versionId, 'mobile-inspector']" *ngIf="lockData?.mobileInspector">
        <a [class.active]="detailsAliasName == 'mobile-inspector'" [routerLink]="['/td', versionId, 'mobile-inspector']"
          class="nav-items">
          <i class="fa-test-plan"></i>
          <span [textContent]="'page_title.mobile_inspector' | translate"></span>
        </a>
      </li>
      <li class="ml-6 ">
        <ng-container *ngIf="!lockData?.uploads">
          <app-common-button label=" Uploads" customClass="lockDataBtnSidebar"></app-common-button> 
        </ng-container>
      </li>
      <li (click)="closeDialog()" *ngIf="lockData?.uploads" [routerLink]="['/td', versionId, 'uploads']"
        [routerLinkActive]="'active'">
        <a [routerLink]="['/td', versionId, 'uploads']" [routerLinkActive]="'active'" class="nav-items">
          <i class="fa-uploads-alt"></i>
          <span [textContent]="'td_nav.upload' | translate"></span>
        </a>
      </li>

      <span [textContent]="'td_nav.test_plan_result' | translate" class="category-header"></span>
      <!-- <li (click)="closeDialog()" [class.active]="detailsAliasName == 'requirements'" [routerLinkActive]="'active'"
        [routerLink]="['/td', versionId, 'requirements']">
        <a [class.active]="detailsAliasName == 'requirements'" [routerLinkActive]="'active'"
          [routerLink]="['/td', versionId, 'requirements']" class="nav-items">
          <i class="fa-test-suites-alt"></i>
          <span [textContent]="'td_nav.requirement' | translate"></span>
        </a>
      </li> -->
      <li class="ml-6">
        <ng-container *ngIf="!lockData?.knowledgeBasePrompt">
          <app-common-button label="Knowledge Base" customClass="lockDataBtnSidebar"></app-common-button> 
        </ng-container>
      </li>
      <li *ngIf="lockData?.knowledgeBasePrompt" (click)="closeDialog()" [class.active]="detailsAliasName === 'Knowledge_Base'" [routerLinkActive]="'active'"
        [routerLink]="['/td', versionId, 'Knowledge_Base']">
        <a [class.active]="detailsAliasName == 'Knowledge_Base'" [routerLinkActive]="'active'"
          [routerLink]="['/td', versionId, 'Knowledge_Base']" class="nav-items">
          <i class="fas fa-robot"></i>
          <span [textContent]="'td_nav.Knowledge_Base' | translate"></span>
        </a>
      </li>

      <li class="ml-6">
        <ng-container *ngIf="!lockData?.persona">
          <app-common-button label="Custom Agents" customClass="lockDataBtnSidebar"></app-common-button> 
        </ng-container>
      </li>
      <li *ngIf="lockData?.persona" (click)="closeDialog()" [class.active]="detailsAliasName === 'custom_agents'" [routerLinkActive]="'active'"
        [routerLink]="['/td', versionId, 'custom_agents']">
        <a [class.active]="detailsAliasName === 'custom_agents'" [routerLinkActive]="'active'"
          [routerLink]="['/td', versionId, 'custom_agents']" class="nav-items">
          <i class="fas fa-brain"></i>
          <span [textContent]="'Custom Agents'"></span>
        </a>
      </li>


      <li  (click)="closeDialog()" [class.active]="detailsAliasName == 'suites'" [routerLinkActive]="'active'"
        [routerLink]="['/td', versionId, 'suites']">
        <a [class.active]="detailsAliasName == 'suites'" [routerLinkActive]="'active'"
          [routerLink]="['/td', versionId, 'suites']" class="nav-items">
          <i class="fa-test-suites-alt"></i>
          <span [textContent]="'td_nav.test_suite' | translate"></span>
        </a>
      </li>
      <li (click)="closeDialog()" [class.active]="detailsAliasName == 'plans'"
        [routerLink]="['/td', versionId, 'plans']" [routerLinkActive]="'active'">
        <a [class.active]="detailsAliasName == 'plans'" [routerLink]="['/td', versionId, 'plans']"
          [routerLinkActive]="'active'" class="nav-items">
          <i class="fa-test-plan"></i>
          <span [textContent]="'td_nav.test_plan' | translate"></span>
        </a>
      </li>

      <li class="ml-6">
        <ng-container *ngIf="!lockData?.testRuns">
          <app-common-button label="Test Runs" customClass="lockDataBtnSidebar"></app-common-button> 
        </ng-container>
      </li>
      <li *ngIf="lockData?.testRuns" (click)="closeDialog()" [class.active]="detailsAliasName == 'testruns'"
        [routerLink]="['/td', versionId, 'testruns']" [routerLinkActive]="'active'">
        <a [class.active]="detailsAliasName == 'testruns'" [routerLink]="['/td', versionId, 'testruns']"
          [routerLinkActive]="'active'" class="nav-items">
          <i class="fa-test-plan"></i>
          <span [textContent]="'td_nav.test_run' | translate"></span>
        </a>
      </li>

      <li class="ml-6">
        <ng-container *ngIf="!lockData?.testRuns">
          <app-common-button label="Run Results" customClass="lockDataBtnSidebar"></app-common-button> 
        </ng-container>
      </li>
      <li *ngIf="lockData?.testRuns" (click)="closeDialog()"
        [class.active]="detailsAliasName == 'runs' || detailsAliasName == 'test_case_results' || detailsAliasName == 'suite_results' || detailsAliasName == 'machine_results'"
        [routerLink]="['/td', versionId, 'results']" [routerLinkActive]="'active'">
        <a [class.active]="detailsAliasName == 'runs' || detailsAliasName == 'test_case_results' || detailsAliasName == 'suite_results' || detailsAliasName == 'machine_results'"
          class="nav-items" [routerLink]="['/td', versionId,'results']" [routerLinkActive]="'active'">
          <i class="fa-run-results-alt"></i>
          <span [textContent]="'td_nav.run_result' |translate"></span>
        </a>
      </li>

      <li class="ml-6">
        <ng-container *ngIf="!lockData?.environments">
          <app-common-button label="Environments" customClass="lockDataBtnSidebar"></app-common-button> 
        </ng-container>
      </li>
      <li *ngIf="lockData?.environments" (click)="closeDialog()" [class.active]="detailsAliasName == 'environments'"
        [routerLink]="['/td', versionId, 'environments']" [routerLinkActive]="'active'">
        <a [class.active]="detailsAliasName == 'environments'" [routerLink]="['/td', versionId, 'environments']"
          [routerLinkActive]="'active'" class="nav-items">
          <i class="fa-globe-alt"></i>
          <span [textContent]="'td_nav.environment' | translate"></span>
        </a>
      </li>

      <span [textContent]="'td_nav.integration_and_reports' | translate" class="category-header"></span>
      <li class="ml-6 ">
        <ng-container *ngIf="!lockData?.graphs">
          <app-common-button label=" Graphs" customClass="lockDataBtnSidebar"></app-common-button> 
        </ng-container>
      </li>
      <li  (click)="closeDialog()" [class.active]="detailsAliasName == 'graphs'" [routerLinkActive]="'active'"
        [routerLink]="['/td', versionId, 'graphs']" *ngIf="lockData?.graphs">
        <a [class.active]="detailsAliasName == 'graphs'" [routerLinkActive]="'active'"
          [routerLink]="['/td', versionId, 'graphs']" class="nav-items">
          <i class="fa-test-suites-alt"></i>
          <span class="nav-text">
            <span [textContent]="'td_nav.graphs' | translate"></span>
            <span class="beta-label">Beta</span>
          </span>
        </a>
      </li>




      <!--      <span *ngIf="!version?.workspace?.isRest" [textContent]="'td_nav.help' | translate" class="category-header">-->
      <!--      </span>-->
      <!--      <li (click)="closeDialog()" *ngIf="!version?.workspace?.isRest">-->
      <!--        <a [routerLinkActive]="'active'" [routerLink]="['/td', versionId, 'actions']" class="nav-items">-->
      <!--          <i class="fa-action-text"></i>-->
      <!--          <span [textContent]="'td_nav.nl_actions' | translate"></span>-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li (click)="closeDialog()" *ngIf="!version?.workspace?.isRest">-->
      <!--        <a [routerLinkActive]="'active'" [routerLink]="['/td', versionId, 'helps']" class="nav-items">-->
      <!--          <i class="fa-action-text"></i>-->
      <!--          <span [textContent]="'td_nav.nl_help' | translate"></span>-->
      <!--        </a>-->
      <!--      </li>-->
    </div>
  </ul>
</div>
<div style="flex: 1;">
  <router-outlet *ngIf="!data"></router-outlet>
</div>