<div class="chatbot-container" [class.fullscreen]="isFullscreen">
  <div class="chatbot-content">
    <div class="chatbot-header" *ngIf="!isFullscreen">
      <span>AI Assistant</span>
      <button mat-icon-button (click)="onRestartChat()" matTooltip="Restart Chat">
        <mat-icon>refresh</mat-icon>
      </button>
      <button mat-icon-button (click)="onCloseChatbot()" matTooltip="Close Chat">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <!-- Add tab navigation -->
    <div class="tab-navigation">
      <button [class.active]="activeTab === 'navigate'" (click)="onSwitchTab('navigate')" class="tab-button">
        <mat-icon>navigation</mat-icon>
        Navigate
      </button>
      <button *ngIf="featureSupport" [class.active]="activeTab === 'testcase'" (click)="onSwitchTab('testcase')"
        class="tab-button">
        <mat-icon>science</mat-icon>
        Test Case Generation
      </button>
      <ng-container *ngIf="!featureSupport">
        <app-common-button label=" Test Case Generation"></app-common-button>
      </ng-container>
    </div>

    <!-- Navigation Tab Content -->
    <div class="chatbot-messages" [class.initial-state]="isInitialState" *ngIf="activeTab === 'navigate'">
      <h1 class="initial-title" *ngIf="isInitialState">
        Hi, I'm AI assistant
        <mat-icon
          matTooltip="This AI testing assistant helps you automate and optimize your software testing process. I can guide you through creating test plans, executing tests efficiently, and analyzing results to improve your product quality. Tell me what you're working on, and I'll suggest the best approach for your specific testing needs.">
          info
        </mat-icon>
      </h1>

      <ng-container *ngIf="isCaseStepsPage">
        <div class="bot-message" *ngFor="let option of testLiveExecutionActions">
          <div class="message-content">
            <p>{{ option.text }}</p>
            <div class="options-container">
              <button mat-button *ngFor="let option of option.question.options" (click)="redirect(option)"
                class="option-button">
                <span class="option-content">
                  {{ option.text }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isRunResultpage">
        <div class="bot-message" *ngFor="let option of runResult">
          <div class="message-content">
            <p>{{ option.text }}</p>
            <div class="options-container">
              <button mat-button *ngFor="let option of option.question.options" (click)="redirect(option)"
                class="option-button">
                <span class="option-content">
                  {{ option.text }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isExecutionStarted">
        <div class="bot-message" *ngFor="let option of testLiveExecutions">
          <div class="message-content">
            <p>{{ option.text }}</p>
            <div class="options-container">
              <button mat-button *ngFor="let option of option.question.options" (click)="redirect(option)"
                class="option-button">
                <span class="option-content">
                  {{ option.text }}
                </span>
              </button>
            </div>
          </div>
        </div>
        <hr>
      </ng-container>
      <ng-container *ngIf="!isCaseStepsPage && !isRunResultpage && !isExecutionStarted">
        <div *ngFor="let message of messages" [ngClass]="{
            'user-message': message.sentBy === 'user',
            'bot-message': message.sentBy === 'bot',
            'inactive-message':
              message.type === 'question' && !isCurrentMessage(message)
          }">
          <div class="avatar" *ngIf="message.sentBy === 'bot'">
            <mat-icon>smart_toy</mat-icon>
          </div>
          <div class="avatar" *ngIf="message.sentBy === 'user'">
            <mat-icon>account_circle</mat-icon>
          </div>
          <div class="message-content">
            <p>{{ message.text }}</p>

            <div *ngIf="message.type === 'question'" class="options-container">
              <ng-container *ngIf="message.question.type === 'select'">
                <button mat-button *ngFor="let option of message.question.options" (click)="onOptionSelect(option)"
                  [disabled]="!isCurrentMessage(message)" class="option-button">
                  <span class="option-content">
                    {{ option.text }}
                    <mat-icon *ngIf="option?.description" [matTooltip]="option.description">
                      info
                    </mat-icon>
                    <mat-icon *ngIf="option.action?.type === 'document'" class="link-icon">link</mat-icon>
                  </span>
                </button>
              </ng-container>

              <ng-container *ngIf="message?.question?.type === 'input' && showInput">
                <div class="search-container">
                  <div class="search-input-wrapper">
                    <input type="text" [formControl]="inputControl" [placeholder]="
                        message.question.placeholder || 'Search test cases...'
                      " (keyup.enter)="onInputSubmit()" #searchInput autocomplete="off" />
                    <div class="search-icon">
                      <div class="spinner" *ngIf="isSearching"></div>
                      <svg *ngIf="!isSearching" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18"
                        height="18">
                        <path fill="none" stroke="currentColor" stroke-width="2"
                          d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 1 0-.7.7l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0A4.5 4.5 0 1 1 14 9.5 4.5 4.5 0 0 1 9.5 14z" />
                      </svg>
                    </div>
                  </div>

                  <div class="search-results" *ngIf="
                      (filteredTestCases | async)?.length > 0 ||
                      isSearching ||
                      (noResults && inputControl.value?.trim())
                    ">
                    <div class="search-status" *ngIf="isSearching">
                      <div class="searching-message">Searching...</div>
                    </div>
                    <div class="search-status" *ngIf="
                        noResults && !isSearching && inputControl.value?.trim()
                      ">
                      <div class="no-results-message">No test cases found</div>
                    </div>
                    <div class="search-result-item" *ngFor="let testCase of filteredTestCases | async"
                      (click)="inputControl.setValue(testCase); onInputSubmit()">
                      <span class="test-case-id">#{{ testCase.id }}</span>
                      <span class="test-case-name">{{ testCase.name }}</span>
                    </div>
                  </div>

                  <div class="error-message" *ngIf="inputError">
                    {{ inputError }}
                  </div>
                </div>
              </ng-container>

              <button *ngIf="isCurrentMessage(message) && messageHistory.length > 1" mat-button class="back-button"
                (click)="onBack()">
                <mat-icon>arrow_back</mat-icon> Go Back
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- Test Case Generation Tab Content -->
    <div class="test-case-tab-content" [style.display]="activeTab === 'testcase' ? 'block' : 'none'">
      <app-chatbot-test-case-panel [isVisible]="activeTab === 'testcase'" (closePanel)="onCloseChatbot()">
      </app-chatbot-test-case-panel>
    </div>

  </div>
</div>