import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KnowledgeBaseService {
  constructor(private http: HttpClient) {}
  private apiUrl = 'https://web-finder.contextqa.com/agent/custom_prompt';
  private personaUrl = 'https://web-finder.contextqa.com/agent/persona'
  getKnowledgeBases(searchTerm: string = ''): Observable<any> {
    const url = searchTerm ? `${this.apiUrl}?search=${searchTerm}` : this.apiUrl;
    return this.http.get(url);
  }

  getPersonas() {
    return this.http.get(this.personaUrl); // Adjust the endpoint as per your API
  }
} 