<!-- <div class="d-flex">
  <app-left-nav
    *ngIf="authGuard.session?.user && !isOnboardingRoute"
  ></app-left-nav>
  <div
    class="d-flex flex-wrap vh-100 ts-col-100-70"
    [class.ts-col-100]="isOnboardingRoute"
  >
    <div class="ts-col-100 h-100">
      <router-outlet></router-outlet>
    </div>
    <simple-notifications
      [options]="{ preventDuplicates: true }"
    ></simple-notifications>
  </div>
</div> -->

<mat-sidenav-container>
  <mat-sidenav-content>
    <div class="d-flex">
      <app-left-nav
        *ngIf="authGuard.session?.user && !isOnboardingRoute"
      ></app-left-nav>
      <div
        class="d-flex flex-wrap vh-100 ts-col-100-70"
        [class.ts-col-100]="isOnboardingRoute"
      >
        <div class="ts-col-100 h-100">
          <router-outlet></router-outlet>
        </div>
        <simple-notifications
          [options]="{ preventDuplicates: true }"
        ></simple-notifications>
      </div>
    </div>
  </mat-sidenav-content>
  <mat-sidenav
    *ngIf="authGuard.session?.user && !isOnboardingRoute"
    mode="side"
    [opened]="isChatbotOpened"
    position="end"
  >
    <app-chatbot
      (chatbotClose)="onToggleChatbot(false)"
      (openedChange)="onToggleChatbot(true)"
    >
    </app-chatbot>
  </mat-sidenav>
</mat-sidenav-container>

<button
  mat-icon-button
  color="primary"
  class="chatbot mat-elevation-z8"
  [class.chatbot--opened]="isChatbotOpened"
  (click)="onToggleChatbot(true)"
  *ngIf="!isAssistantRoute"
>
  <img src="/assets/color_icons/cqa-logo.svg" alt="Chatbot" />
</button>
