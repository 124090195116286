<div class="project-switch-wrapper">
  <div #changeStep class="project-info flex-column" (click)="toggle()" [matMenuTriggerFor]="menu" *ngIf="version">
    <div class="project-switch-container" [class.active]="show">
      <div class="w-90">
        <div class="application-info d-flex flex-column">
          <div class="application-title ts-col-100" [matTooltip]="version?.workspace?.name">
            <span class="mr-5" [class.mb-n4]="version?.workspace?.isWeb"
              [class.fa-project-website]="version?.workspace?.isWeb"
              [class.fa-project-ios]="version?.workspace?.isIosNative"
              [class.fa-project-andriod]="version?.workspace?.isAndroidNative"
              [class.fa-project-mobile]="version?.workspace?.isMobileWeb"
              [class.fa-project-api]="version?.workspace?.isRest">
            </span>
            <span [textContent]="version?.workspace?.name"></span>
          </div>
          <div class="version-label mr-5 ts-col-100">
            <span [textContent]="version?.versionName" [matTooltip]="version?.versionName"></span>
            <span class="save_as_active" *ngIf="userPreference?.versionId != version?.id"
              [matTooltip]="'project_switcher.save_as_active' | translate">*</span>
          </div>
        </div>
      </div>
      <span class="w-10 text-right">
        <img src="/assets/images/arrow-angle-right-solid-svgrepo-com.svg" width="20px">
        <!-- <i class="fa-arrow-down"></i> -->
      </span>
    </div>
  </div>
  <mat-menu #menu="matMenu" class="project-dropdown vw-100 vh-100 pt-0">
    <div (click)="stopAction($event)" class="w-100 d-flex">
      <div class="ts-col-15">
        <div class="project-info flex-column mt-n2" (click)="toggleModal()" *ngIf="version" id="project_div">
          <div class="project-switch-container">
            <div class="w-90">
              <div class="application-info d-flex flex-column">
                <div class="application-title ts-col-100" [matTooltip]="version?.workspace?.name">
                  <span class="mr-5" [class.mb-n4]="version?.workspace?.isWeb"
                    [class.fa-project-website]="version?.workspace?.isWeb"
                    [class.fa-project-ios]="version?.workspace?.isIosNative"
                    [class.fa-project-andriod]="version?.workspace?.isAndroidNative"
                    [class.fa-project-mobile]="version?.workspace?.isMobileWeb"
                    [class.fa-project-api]="version?.workspace?.isRest">
                  </span>
                  <span [textContent]="version?.workspace?.name"></span>
                </div>
                <div class="version-label mr-5 ts-col-100">
                  <span [textContent]="version?.versionName" [matTooltip]="version?.versionName"></span>
                  <span class="save_as_active" *ngIf="userPreference?.versionId != version?.id"
                    [matTooltip]="'project_switcher.save_as_active' | translate">*</span>
                </div>
              </div>
            </div>
            <span class="w-10 text-right">
              <i class="fa-arrow-right"></i>
            </span>
          </div>
        </div>
        <ul id="ul_version_home" class="project-nav item-container w-100">
          <div class="secondary-nav-container">
            <li>
              <a [routerLink]="['/workspaces', applications?.content[0].id, 'test_case_types']"
                [routerLinkActive]="'active'" (click)="menuHide()" class="nav-items">
                <i class="fa-test-case-types"></i>
                <span [translate]="'application_settings.left_nav.test_case_types'"></span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/workspaces', applications?.content[0].id, 'test_case_priorities']"
                [routerLinkActive]="'active'" (click)="menuHide()" class="nav-items">
                <i class="fa-test-case-priorities"></i>
                <span [translate]="'application_settings.left_nav.test_case_priorities'"></span>
              </a>
            </li>
          </div>
        </ul>
      </div>
      <div class="ts-col-85">
        <div class="py-15 workspace-container">
          <div class="header-row">
            <div>
              <div class="fz-20 ml-20 rb-medium" [translate]="'work_space.switcher.title'"></div>
            </div>
            <div class="d-flex">
              <button [routerLink]="['/workspaseForm']" [translate]="'btn.common.create'" *ngIf="isWorkspace"
                class="theme-btn-primary"></button>
              <div [matTooltip]="'hint.message.common.close' | translate" class="close-btn fz-30 pointer rb-light"
                (click)="closeToggleModal()">&times;
              </div>
            </div>
          </div>

          <form [formGroup]="searchForm" class="search-form">
            <mat-form-field>
              <input matInput placeholder="Search versions" formControlName="searchTerm" style="padding-bottom: 18px;">
            </mat-form-field>
          </form>

          <div class="workspace-grid">
            <div class="workspace-card" *ngFor="let workspaceList of applicationsData">
              <div class="card-header">
                <div class="card-icon">
                  <i class="bg-active border-rds-26 fa-bi_window fz-26 p-10"></i>
                </div>
                <div class="card-title fz-18 rb-medium" [translate]='workspaceList.workspace?.name'></div>
              </div>
              <div class="card-content">
                <div [innerHTML]="workspaceList.workspace?.description"></div>
              </div>
              <div class="card-actions">
                <div class="live-btn-container">
                  <button class="live-btn" (click)="toggleDropdown(workspaceList.workspace.id)">
                    <span [translate]="'work_space.switcher.live'"></span>
                    <i class="fa-arrow-right ml-20"></i>
                  </button>
                  <div class="dropdown-menu" [class.show]="activeDropdown === workspaceList.id">
                    <div class="dropdown-header rb-medium" [translate]="'work_space.switcher.select_version'"></div>
                    <div class="dropdown-items">
                      <div class="dropdown-item" *ngFor="let version of liveWebVersions" (click)="go(version)"
                        [style.display]="version.workspaceId === workspaceList.id ? 'block' : 'none'">
                        <span>{{ version?.name }}</span>
                        <i class="fa-arrow-right ml-20"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-controls">
            <button mat-button (click)="onPageChange(currentPage - 1)" [disabled]="versions?.first">Previous</button>
            <span>Page {{versions?.number + 1}} of {{versions?.totalPages}}</span>
            <button mat-button (click)="onPageChange(currentPage + 1)" [disabled]="versions?.last">Next</button>
          </div>
        </div>
      </div>
    </div>
  </mat-menu>
</div>