<div>
    <mat-tab-group class="tab-group-row">

        <mat-tab label="Response Body">
            <div style="display: flex; justify-content: flex-end; margin-top:10px;">
                <button mat-flat-button (click)="addVarification()">
                  Add Verification
                </button>
              </div>
            <form [formGroup]="form">
                <table class="table">
                    <thead style="background-color: #F2F2F2">
                        <tr>
                            <th scope="col">S.no</th>
                            <th scope="col">JSON Path</th>
                            <th scope="col">Verification </th>
                            <th scope="col">Data Type </th>
                            <th scope="col" style="width: 30%">Expected Value</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let item of getValidationForm().controls;index as i">
                        <tr formArrayName="body">
                            <ng-container formGroupName="{{i}}">
                                <td scope="row">{{i+1}}</td>
                                <td scope="row">
                                    <input placeholder="Json Path" type="text" style="width: 100%;"
                                        formControlName="jsonPath"
                                        required
                                        >
                                        <!-- <mat-error *ngIf="getValidationForm()?.at(i)?.get('jsonPath')?.hasError('required') && (getValidationForm()?.at(i)?.get('jsonPath')?.touched || getValidationForm()?.at(i)?.get('jsonPath')?.dirty)">
                                            Json Path is required.
                                        </mat-error> -->
                                </td>
                                <td>
                                    <mat-select formControlName="verificationType">
                                        <mat-option value="equals">Equals</mat-option>
                                        <mat-option value="not-equals">Not-equal</mat-option>
                                        <mat-option value="less-then">less than</mat-option>
                                        <mat-option value="greater-then">greater-then</mat-option>
                                        <mat-option value="is-null">is-null</mat-option>
                                        <mat-option value="is-not-null">is-not-null</mat-option>
                                    </mat-select>
                                </td>
                                <td>
                                  <mat-select formControlName="expectedType" (selectionChange)="onDataTypeChange(i,
                                  $event.value)">
                                    <mat-option value="string">String</mat-option>
                                    <mat-option value="number">Number</mat-option>
                                    <mat-option value="boolean">Boolean</mat-option>
                                    <mat-option value="array">Array</mat-option>
                                    <mat-option value="object">Object</mat-option>
                                  </mat-select>
                                </td>
                                <td scope="row" *ngIf="form.get('body').get(i.toString())?.get('expectedType').value ===
                                'string' || form.get('body').get(i.toString())?.get('expectedType').value === 'array' ||
                                form.get('body').get(i.toString())?.get('expectedType').value === 'object'">
                                  <input [placeholder]="form.get('body').get(i.toString())?.get('expectedType').value
                                   === 'string' ? 'Expected Value in String': form.get('body').get(i.toString())?.get('expectedType').value
                                   === 'object' ? 'Expected Value in Object':
                                   form.get('body').get(i.toString())?.get('expectedType').value
                                   === 'array' ? 'Expected Value in Array': 'Expected Value in String'" type="text"
                                         style="width: 100%;"
                                      formControlName="expectedValue"  required>
                                  <div style="color: red"
                                         *ngIf="form.get('body').get(i.toString())?.get('expectedValue')?.touched &&
           form.get('body').get(i.toString())?.get('expectedValue')?.hasError('invalidType')">
                                   <span>{{
                                       form.get('body').get(i.toString())?.get('expectedValue')?.errors?.message.toString() |
                                           json }}</span>
                                  </div>
                                </td>
                              <td scope="row" *ngIf="form.get('body').get(i.toString())?.get('expectedType').value === 'number'">
                                <input [placeholder]="'Expected Value in Number'" type="number" style="width: 100%;"
                                       formControlName="expectedValue"  required>
                              </td>
                              <td scope="row" *ngIf="form.get('body').get(i.toString())?.get('expectedType').value === 'boolean'">
                                <mat-select formControlName="expectedValue" >
                                  <mat-option value="true">true</mat-option>
                                  <mat-option value="false">false</mat-option>
                                </mat-select>
                              </td>
                                <td style="text-align: center;">
                                    <!-- <mat-icon color="success" style="cursor: pointer;"
                                        (click)="responseData()">done</mat-icon>
                                    &nbsp; -->
                                    <mat-icon color="warn" style="cursor: pointer;"
                                        (click)="deleteRow(i)">delete</mat-icon>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </form>

        </mat-tab>
        <mat-tab label="Status">
            <div style="display: flex; justify-content: flex-end; margin-top: 3%;">
                <button mat-flat-button (click)="addVarification1()">Add Verification
                </button>
              </div>
            <!-- <span (click)="addVarification1()" style="font-weight: bold; cursor: pointer; float: right;"> <mat-icon>add</mat-icon>Add
                Verification1</span> -->
            <form action="" [formGroup]="form1">
            <table class="table">
                <thead style="background-color: #F2F2F2">
                    <tr>
                        <th scope="col">S.no</th>
                        <th scope="col">Verification </th>
                        <th scope="col" style="width: 30%">Expected Value</th>
                    </tr>
                </thead>
                <tbody *ngFor="let item of getValidationForm1().controls;index as i">
                    <tr formArrayName="status">
                        <ng-container formGroupName="{{i}}">
                        <td scope="row">{{ i + 1 }}</td>
                        <td>
                            <mat-select formControlName="verificationType">
                                <mat-option value="equals">Equals</mat-option>
                                <mat-option value="not-equals">Not-equal</mat-option>
                                <mat-option value="less-then">less than</mat-option>
                                <mat-option value="greater-then">Greater-then</mat-option>
                            </mat-select>
                        </td>
                        <td scope="row">
                            <input formControlName="expectedValue" placeholder="Expected Value" type="number"
                                style="width: 100%;">
                        </td>
                        <td>
                            <!-- <mat-icon color="success" style="cursor: pointer;" (click)="responseData()">done</mat-icon>
                            &nbsp; -->
                            <mat-icon color="warn" style="cursor: pointer;" (click)="deleteRow1(i)">delete</mat-icon>
                        </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </form>
        </mat-tab>
    </mat-tab-group>
</div>
