import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return '';
        // Create a temporary div element
        const temp = document.createElement('div');
        temp.innerHTML = value;
        // Return the text content only (strips HTML)
        return temp.textContent || temp.innerText || '';
    }
} 