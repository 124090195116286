import {Injectable} from '@angular/core';
import {HttpHeadersService} from "../shared/services/http-headers.service";
import {UrlConstantsService} from "../shared/services/url.constants.service";
import {Pageable} from "../shared/models/pageable";
import {Page} from "../shared/models/page";
import {HttpClient} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {TestCaseResult} from "../models/test-case-result.model";
import {DataSourceService} from "../shared/services/data-source.service";

@Injectable({
  providedIn: 'root'
})
export class TestCaseResultService implements DataSourceService {

  constructor(
    private http: HttpClient,
    private httpHeaders: HttpHeadersService,
    private URLConstants: UrlConstantsService) {
  }

  public findAll(filter?: string, sortBy?: string, pageable?: Pageable): Observable<Page<TestCaseResult>> {
    return this.http.get<Page<TestCaseResult>>(this.URLConstants.testCaseResultsUrl, {
      headers: this.httpHeaders.contentTypeApplication,
      params: this.httpHeaders.serializeParams(filter, sortBy, pageable)
    }).pipe(
      map(data => new Page<TestCaseResult>().deserialize(data, TestCaseResult)),
      catchError(() => throwError('Problem while fetching TestCaseResults'))
    )
  }

  public show(id: Number): Observable<TestCaseResult> {
    return this.http.get<TestCaseResult>(this.URLConstants.testCaseResultsUrl + "/" + id, {
      headers: this.httpHeaders.contentTypeApplication
    }).pipe(
      map(data => new TestCaseResult().deserialize(data)),
      catchError(() => throwError('Problem while fetching TestCaseResult'))
    )
  }

  public testCaseAiSuggestions(): Observable<any> {
    return this.http.get(this.URLConstants.testCaseResultsAiSuggestionsUrl + "/test_case", {
      headers: this.httpHeaders.contentTypeApplication,
      observe: 'response'  // This will give us the full response including headers
    }).pipe(
      map(response => {
        return response.body;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public approveTestCaseSuggestions(suggestionNumbers: number[]): Observable<any> {
    return this.http.post(this.URLConstants.testCaseResultsAiSuggestionsUrl + "/approve", suggestionNumbers, {
      headers: this.httpHeaders.contentTypeApplication
    }).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public showWithoutSerilize(id: Number): any {
    return this.http.get(this.URLConstants.testCaseResultsUrl + "/" + id, {
      headers: this.httpHeaders.contentTypeApplication
    }).pipe(
      map(data => data),
      catchError(() => throwError('Problem while fetching TestCaseResult'))
    )
  }

  public downloadExcelFile(id: number, testPlanId: number): Observable<Blob> {
    return this.http.get(this.URLConstants.testRundataUrl + "/export/" + id + '/runs/' + testPlanId, {
      headers: this.httpHeaders.contentTypeApplication,
      responseType: 'blob' // Ensure response type is set to 'blob'
    }).pipe(
      catchError((error) => {
        console.error('Problem while fetching TestCaseResult:', error);
        return throwError('Problem while fetching TestCaseResult');
      })
    );
  }

  public downloadPDFFile(id: number, testPlanId: number): Observable<Blob> {
    return this.http.get(this.URLConstants.testRundataUrl + "/export/runs/" + testPlanId, {
      headers: this.httpHeaders.contentTypeApplication,
      responseType: 'blob' // Ensure response type is set to 'blob'
    }).pipe(
      catchError((error) => {
        console.error('Problem while fetching TestCaseResult:', error);
        return throwError('Problem while fetching TestCaseResult');
      })
    );
  }

  public exportScreenshots(id: Number): Observable<void> {
    return this.http.put<void>(this.URLConstants.testCaseResultsUrl + "/" + id + "/export_screenshots", {
      headers: this.httpHeaders.contentTypeApplication
    }).pipe(
      catchError(() => throwError('Problem while exporting Screenshots TestCaseResult'))
    )
  }

  public update(testCaseResult: TestCaseResult): Observable<TestCaseResult> {
    return this.http.put<TestCaseResult>(this.URLConstants.testCaseResultsUrl + "/" + testCaseResult.id, testCaseResult.serialize(), {
      headers: this.httpHeaders.contentTypeApplication
    }).pipe(
      map(data => new TestCaseResult().deserialize(data)),
      catchError(() => throwError('Problem while update TestCaseResult'))
    )
  }

  public createTestCase(id): Observable<TestCaseResult> {
    return this.http.get<TestCaseResult>(this.URLConstants.testCaseResultsUrl + "/" + id + '/create', {
      headers: this.httpHeaders.contentTypeApplication
    }).pipe(
      map(data => new TestCaseResult().deserialize(data)),
      catchError(() => throwError('Problem while fetching TestCaseResult'))
    )
  }

  private modalClosedSource = new Subject<void>();

  modalClosed$ = this.modalClosedSource.asObservable();

  notifyModalClosed() {
    console.log("test")
    this.modalClosedSource.next();
  }
}
