import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  OnInit,
  ViewChild,
  Inject
} from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TestCase } from "app/models/test-case.model";
import { TestStepService } from "app/services/test-step.service";
import { Socket } from "ngx-socket-io";
import { BehaviorSubject, Subscription, timer } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { TestStep } from "../../../../models/test-step.model";
import { LicenceSevices } from "app/shared/services/license.service";
import { ChatModelComponent } from "../chat-model/chat-model.component";
import { TranslateService } from "@ngx-translate/core";
import { TestCaseResultService } from "app/services/test-case-result.service";
import { ExecutionCloseModelComponent } from "../execution-mode-close/execution-mode-close.component";
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChatbotUiStateService } from "app/services/chatbot-ui-state.service";
import { LogsExpandedDialogComponent } from "../logs-expanded-dialog/logs-expanded-dialog.component";

interface ChatMessage {
  text: string;
  addedBy: "USER" | "SYSTEM";
  stepStatus?: string;
  stepId?: string;
  isFailedStep?: boolean;
  newLocator?: string;
  newMetadata?: string;
}

interface StepLog {
  text: string;
  complete: boolean;
  id: string;
  timestamp?: Date;
}

@Component({
  selector: "app-execution-model",
  templateUrl: "./execution-model.component.html",
  styleUrls: ["./execution-model.component.scss"],
})
export class ExecutionModelComponent implements OnInit, DoCheck {
  @ViewChild("logContainer") private logContainer: ElementRef;
  @ViewChild("chatContainer") private chatContainer: ElementRef;
  expandedSteps: { [key: number]: boolean } = {};

  public Prop: any;
  public loader: any;
  public TestCase: any;
  public TestSteps: any[];
  public TestStep: TestStep[];
  public ExecutedStepsData: TestCase[];
  public time: any;
  public failSteps: any;
  public testRunId: any;
  public successSteps: any;
  public newMetadata: any;
  public stepsData: any = [];
  public chatLogs: any[] = [];
  public stopExecution: any;
  public activeLog: string = "console";
  stepGroupSteps: any = [];
  private lastLogLength: number = 0;
  public Conversation: any;
  public message: any;
  public faildStepsDetail: any;
  private extensionId = "pgadpooodaipbhbchnojijmlkhpamadh";

  private timerSubscription: Subscription | null = null;
  private inputEnabled = new BehaviorSubject<boolean>(false);
  public inputEnabled$ = this.inputEnabled.asObservable();

  public remainingTime$ = new BehaviorSubject<number>(0);
  private timerStarted: boolean = false;
  public locatorNotDetect: boolean = false;
  testCaseExecutionLog: any;
  public origin: string = "";

  public readonly CIRCLE_CIRCUMFERENCE = 2 * Math.PI * 52; // 52 is the radius of our circle

  // Add this property to track loading state
  public isStoppingExecution: boolean = false;

  public displayedLogs: { [stepId: string]: StepLog[] } = {};

  private chatDialogRef: MatDialogRef<ChatModelComponent> | null = null;
  private executionCloseRef: MatDialogRef<ExecutionCloseModelComponent> | null = null;


  // Add this property to track current active step
  public currentActiveStepIndex: number | null = null;

  public imageContainerHeight: number = 70; // Default height
  public consoleContainerHeight: number = 30; // Default height
  private isResizing: boolean = false;
  private startY: number = 0;
  private startHeights = {
    image: 0,
    console: 0
  };

  executionForm: FormGroup;
  knowledgeBaseList = [
    { id: 1, name: 'Knowledge Base 1' },
    { id: 2, name: 'Knowledge Base 2' },
    // Add your knowledge base options here
  ];

  isChatbotOpened: boolean = false;

  public showUserInputField: boolean = false;
  public showUserInputFieldinDebug: boolean = false;


  constructor(
    private dialogRef: MatDialogRef<ExecutionModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private testStepService: TestStepService,
    public router: Router,
    private socket: Socket,
    private cdr: ChangeDetectorRef,
    public LicenceSevices: LicenceSevices,
    private dialog: MatDialog,
    public translate: TranslateService,
    private testCaseResultService: TestCaseResultService,
    private chatbotUiState: ChatbotUiStateService
  ) {
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.executionForm = this.fb.group({
      knowledgeBase: [null]
    });
  }

  startResize(event: MouseEvent) {
    this.isResizing = true;
    this.startY = event.clientY;
    this.startHeights = {
      image: this.imageContainerHeight,
      console: this.consoleContainerHeight
    };

    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  }

  private handleMouseMove = (event: MouseEvent) => {
    if (!this.isResizing) return;

    const deltaY = event.clientY - this.startY;
    const deltaVh = (deltaY / window.innerHeight) * 100;

    let newImageHeight = this.startHeights.image + deltaVh; // Reversed direction
    let newConsoleHeight = this.startHeights.console - deltaVh;

    // Enforce min/max heights (in vh units)
    if (newImageHeight < 20) {
      newImageHeight = 20;
      newConsoleHeight = 80;
    } else if (newImageHeight > 80) {
      newImageHeight = 80;
      newConsoleHeight = 20;
    }

    this.imageContainerHeight = newImageHeight;
    this.consoleContainerHeight = newConsoleHeight;
  };


  private handleMouseUp() {
    this.isResizing = false;
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  }

  ngOnInit(): void {
    this.expandedSteps = this.TestSteps.map(() => true);

    this.LicenceSevices.lockData$.subscribe(data => {
      if (data) {
        this.testCaseExecutionLog = data.featureSupport.testCaseExecutionLog;
      }
    });
    this.origin = window.location.hostname.split(".")[0];

    // Subscribe to chatbot state changes using the specified pattern
    this.chatbotUiState.isOpened$.subscribe(
      isOpened => {
        this.isChatbotOpened = isOpened;
        if (this.dialogRef) {
          this.dialogRef.updateSize(isOpened ? "calc(100vw - 400px)" : "100vw");
          this.dialogRef.updatePosition({
            right: isOpened ? "400px" : "0",
            top: "0px"
          });
        }
      }
    );
  }

  ngDoCheck() {
    if (
      this.Prop &&
      this.Prop.log &&
      this.Prop.log.length > this.lastLogLength
    ) {
      const newLogs = this.Prop.log.slice(this.lastLogLength);
      newLogs.forEach((log) => {
        this.addNewLog(log, this.Prop.id);
        this.addChatLog(log, this.Prop);
      });
      this.lastLogLength = this.Prop.log.length;

      // Only update currentActiveStepIndex for running steps, but don't force close others
      this.TestSteps?.forEach((step, index) => {
        const expandableStatuses = ['RUNNING', 'STARTED', 'SUCCESS', 'PLANNER', 'CONVERSATION'];
        if (step.executionData?.stepStatus && expandableStatuses.includes(step.executionData.stepStatus)) {
          this.currentActiveStepIndex = index;
          this.expandedSteps[index] = true;
        } else {
          this.expandedSteps[index] = false;
        }
      });

      this.TestSteps?.forEach((step) => {
        if (step.executionData?.stepStatus === "CONVERSATION") {
          if (!this.chatDialogRef) {
            this.openLinkedTestPlansDialog(step);
          } else {
            this.updateDialogData(step);
          }
        }
      });
    }

    if (
      this.Prop &&
      this.Prop.stepStatus === "LOCATOR_NOT_DETECT" &&
      !this.timerStarted
    ) {
      this.startTimer();
      this.timerStarted = true;
    }

    if (
      this.Prop &&
      this.Conversation &&
      this.Conversation.stepStatus === "TIMEOUT" &&
      !this.timerStarted
    ) {
      this.startTimer();
      this.timerStarted = true;
    }

    if (this.Prop && this.Prop.status === "COMPLETED" && this.chatDialogRef) {
      this.chatDialogRef.close();
      this.chatDialogRef = null;
    }
  }

 
  addChatLog(log: string, Prop: any) {
    const validSteps = [
      "WAITING_FOR_STOP",
      "TIMEOUT",
      "WAITING_STATUS",
      "AI_AGENT",
      "AI_ASK",
      "DEBUGGE_WITH_AI",
    ];
    if (validSteps.includes(Prop.stepFrom)) {
      // Remove any "Step X:" prefix from log messages, regardless of the step number
      const cleanedLog = log.replace(/^step\s*\d*\s*:?\s*/i, '');
      const chatMessage: ChatMessage = {
        text: cleanedLog,
        addedBy: "SYSTEM",
        stepStatus: Prop.stepStatus,
        stepId: Prop.id,
        isFailedStep:
          Prop.stepStatus === "CONVERSATION" &&
            Prop.stepFrom === "DEBUGGE_WITH_AI"
            ? true
            : false,
      };
      if (
        Prop.stepStatus === "CONVERSATION" &&
        Prop.stepFrom === "DEBUGGE_WITH_AI"
      ) {
        this.newMetadata = "";
      }
      this.chatLogs.push(chatMessage);
      this.cdr.detectChanges();
      this.scrollChatToBottom();
    }
  }

  private addNewLog(log: string, stepId: string): void {
    if (!this.displayedLogs[stepId]) {
      this.displayedLogs[stepId] = [];
    }

    // Remove any "Step X:" prefix from log messages, regardless of the step number
    const cleanedLog = log.replace(/^step\s*\d*\s*:?\s*/i, '');

    this.displayedLogs[stepId].push({
      text: "",
      complete: false,
      id: stepId,
      timestamp: new Date()
    });
    this.simulateTyping(cleanedLog, this.displayedLogs[stepId].length - 1, stepId);
  }

  redirectToCE() {
    if (this.Conversation.stepStatus === "LOCATOR_NOT_DETECT") {
      this.testStepService.show(this.Conversation.id).subscribe((steps) => {
        this.faildStepsDetail = steps;
        if (this.faildStepsDetail) {
          if (chrome && chrome.runtime) {
            let data = {
              type: "test_case",
              // id: testcase.id,
              // result: testcase,
              action: "openSidePanelFromPortal1",
              origin: window.location.hostname.split(".")[0],
              // origin: "dtest01",
              stepNumber: 2, //Add position - 1
              faildStepsDetail: this.faildStepsDetail,
              jwt: localStorage.getItem("_t"),
              userEmail: localStorage.getItem("useremail"),
            };
            chrome.runtime.sendMessage(
              this.extensionId,
              { message: "openSidePanelFromPortal1", data: data },
              (data: any) => { }
            );
            // this.router.navigate(['/td', 'cases', testcase.id, 'steps']);
            const url =
              this.faildStepsDetail.event.targetPageUrl ||
              this.faildStepsDetail.event.href;
            window.open(url, "_blank");
          } else {
            // this.router.navigate(['/td', 'cases', testcase.id, 'steps']);
            const url =
              this.faildStepsDetail.event.targetPageUrl ||
              this.faildStepsDetail.event.href;
            window.open(url, "_blank");
          }
        }
      });
    }
  }
  private simulateTyping(log: string, index: number, stepId: string): void {
    let charIndex = 0;
    const interval = setInterval(() => {
      if (charIndex < log.length) {
        this.displayedLogs[stepId][index].text += log[charIndex];
        charIndex++;
        this.cdr.detectChanges();
        this.scrollToBottom();
      } else {
        clearInterval(interval);
        this.displayedLogs[stepId][index].complete = true;
        this.cdr.detectChanges();
      }
    }, 50);
  }

  private scrollToBottom(): void {
    if (!this.logContainer?.nativeElement) {
      console.warn("logContainer is not initialized.");
      return;
    }

    try {
      this.logContainer.nativeElement.scrollTop =
        this.logContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error("Error while scrolling to bottom:", err);
    }
  }

  private scrollChatToBottom(): void {
    try {
      if (!this.chatContainer?.nativeElement) return;
      this.chatContainer.nativeElement.scrollTop =
        this.chatContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }

  /** Re Run */
  reRun(value?: boolean): void {
    this.dialogRef.close({ data: "you confirmed" });
    this.testStepService.onRerun.emit();
  }

  /** Close Dialog */
  closeDialog() {
    this.executionCloseRef = this.dialog.open(ExecutionCloseModelComponent, {
      width: "400px",
      height: "200px",
    });

    this.executionCloseRef.afterClosed().subscribe(result => {
      this.executionCloseRef = null;
      if (result && result.data === "you confirmed") {
        this.dialogRef.close(result);
        this.stopExecutionevent();
        this.testCaseResultService.notifyModalClosed();

      }
    });

  }
  openStep(step: any, index: number): void {
    this.expandedSteps[index] = true;
    this.getStepDetailData(step, index);
  }

  /** Get Step Data */
  expandedIndex: number | null = null;
  getStepDetailData(data: any, index: number) {
    this.expandedSteps[index] = true;
    // this.currentActiveStepIndex = index;

    if (data.stepGroupId) {
      let query = "testCaseId:" + data.stepGroupId;
      this.testStepService.findAll(query).subscribe((res) => {
        this.stepGroupSteps = res;
        this.stepGroupSteps.content = this.stepGroupSteps?.content.sort((a, b) => a.position - b.position)
      });
    }
  }

  /** Re direct to test run  */
  redirectToRunDetail() {
    const url = this.router
      .createUrlTree(["/td/runs/", this.testRunId])
      .toString();
    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree(["/run-history-view"], {
    //     queryParams: {
    //       testCaseId: this.TestCase.id,
    //       runResultId: this.Prop.runResultId,
    //       isManualExecution: false,
    //     },
    //   })
    // );

    window.open(url, "_blank");
  }

  sentevent() {
    if (!this.message || this.message.trim() === '') {
      return;
    }

    if (
      this.Conversation &&
      this.Conversation.stepStatus == "LOCATOR_NOT_DETECT"
    ) {
      this.chatLogs.push({ text: "Locator updated", addedBy: "USER" });
      this.socket.emit("internal", {
        message: "Locator updated",
        topic: this.Conversation?.replyBack_id,
      });
    } else {
      this.chatLogs.push({ text: this.message, addedBy: "USER" });
      this.socket.emit("internal", {
        message: this.message,
        topic: this.Conversation?.replyBack_id,
      });
    }
    this.scrollChatToBottom();
    this.Conversation = null;
    this.message = "";
    this.timerStarted = false;
    this.showUserInputField = false; // Reset the input field visibility
    this.showUserInputFieldinDebug = false;
  }

  stop_agent() {
    this.message = 'STOP_AGENT';
    this.sentevent();
    this.message = '';
  }

  handleStepAction(action: string, id: any) {
    this.socket.emit('internal', {
      action: action,
      topic: this.Conversation?.replyBack_id,
      stepId: id,
    });
    // this.closeDialog();
  }
  updateStepData(action: string, type: 'locator' | 'metadata', id: any) {
    this.socket.emit('internal', {
      action: action,
      type: type,
      value: this.message,
      topic: this.Conversation?.replyBack_id,
      stepId: id,
    });
    this.message = '';
    // this.dialogRef.close();
    // this.closeDialog();
  }
  private startTimer() {
    this.stopTimer(); // Ensure any existing timer is stopped
    this.inputEnabled.next(true);
    this.locatorNotDetect = true;

    const duration = 2 * 60; // 2 minutes in seconds
    const TimeoutDuration = 1 * 50;
    if (this.Conversation.stepStatus === "TIMEOUT") {
      this.remainingTime$.next(TimeoutDuration);
    } else {
      this.remainingTime$.next(duration);
    }

    this.timerSubscription = timer(0, 1000)
      .pipe(takeWhile(() => this.remainingTime$.value > 0))
      .subscribe(() => {
        const currentTime = this.remainingTime$.value;
        if (currentTime > 0) {
          this.remainingTime$.next(currentTime - 1);
        }
        if (currentTime === 1) {
          this.inputEnabled.next(false);
          this.stopTimer();
          if (this.Conversation.stepStatus === "TIMEOUT") {
            this.Conversation = null;
          }
          this.timerStarted = false;
          this.locatorNotDetect = false;
        }
      });
  }

  private stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }

  public formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  }

  public getDashOffset(remainingTime: number): number {
    const progress = 1 - remainingTime / (2 * 60); // 2 minutes total
    return this.CIRCLE_CIRCUMFERENCE * progress;
  }

  stopExecutionevent() {
    if (!this.stopExecution?.id) {
      return;
    }

    try {
      this.isStoppingExecution = true;
      this.socket.emit("internal", {
        message: "stop_generation",
        topic: this.stopExecution.id,
      });

      setTimeout(() => {
        this.Conversation = null;
        this.message = "";
        this.timerStarted = false;
        this.isStoppingExecution = false;
      }, 1000);
    } catch (error) {
      this.isStoppingExecution = false;
    }
  }

  private updateDialogData(steps: any) {
    if (this.chatDialogRef) {
      const newData = {
        Prop: this.Prop,
        Conversation: {
          replyBack_id: this.Conversation?.replyBack_id || null,
          stepStatus: steps.executionData?.stepStatus || null,
        },
        chatLogs: this.chatLogs || [],
        stepData: steps,
      };

      this.chatDialogRef.componentInstance.updateData(newData);
    }
  }

  openLinkedTestPlansDialog(steps: any) {
    // We're no longer opening a dialog, so we'll just set the Conversation data
    this.Conversation = {
      replyBack_id: steps.executionData?.replyBack_id || null,
      stepStatus: steps.executionData?.stepStatus || null,
      id: steps.id
    };
  }

  onSubmit() {
    this.dialogRef.close(this.executionForm.value);
  }

  onCancel() {
    this.dialogRef.close();
  }

  public copyLogs(stepId: string): void {
    if (!this.displayedLogs[stepId] || this.displayedLogs[stepId].length === 0) {
      return;
    }

    // The logs should already be cleaned at this point, but just to be safe
    const logText = this.displayedLogs[stepId]
      .map(log => {
        const cleanedText = log.text.replace(/^step\s*\d*\s*:?\s*/i, '');
        return `[${this.formatLogTimestamp(log.timestamp)}] ${cleanedText}`;
      })
      .join('\n');

    navigator.clipboard.writeText(logText).then(() => {
      console.log('Logs copied to clipboard');
    }, (err) => {
      console.error('Could not copy logs: ', err);
    });
  }

  private formatLogTimestamp(timestamp: Date): string {
    if (!timestamp) return '';
    return timestamp.toISOString().substr(11, 12);
  }

  // Add method to expand logs in a dialog
  public expandLogs(stepId: string, stepStatus: string): void {
    if (!this.displayedLogs[stepId] || this.displayedLogs[stepId].length === 0) {
      return;
    }

    this.dialog.open(LogsExpandedDialogComponent, {
      width: '80%',
      height: '80%',
      data: {
        logs: this.displayedLogs[stepId],
        stepId: stepId,
        stepStatus: stepStatus
      },
      panelClass: 'logs-expanded-dialog'
    });
  }
}
