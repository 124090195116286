import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.css']
})
export class WelcomeDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<WelcomeDialogComponent>
  ) {}

  closeDialog() {
    // Store in localStorage to remember user has seen the popup
    localStorage.setItem('welcomePopupShown', 'true');
    // Close the dialog using Material's dialog ref
    this.dialogRef.close();
  }
}