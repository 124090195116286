import { Component, EventEmitter, OnInit, Output, Inject, Input, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { Message, MESSAGES, SelectOption, MESSAGE_MAP, FOLLOW_UP_MESSAGES, TEST_CASE_CONFIRMATION_MESSAGES, TEST_LIVE_EXECUTION, RUN_RESULT, TEST_LIVE_EXECUTIONS } from "./chatbot.config";
import { MatDialog } from "@angular/material/dialog";
import { ChatbotAction } from "./chatbot.config";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { WorkspaceVersionService } from "app/shared/services/workspace-version.service";
import { WorkspaceVersion } from "app/models/workspace-version.model";
import { UserPreferenceService } from "app/services/user-preference.service";
import { UserPreference } from "app/models/user-preference.model";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormControl, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith, debounceTime, switchMap, tap, catchError, finalize, distinctUntilChanged } from 'rxjs/operators';
import { TestCaseService } from "app/services/test-case.service";
import { of } from 'rxjs';
import { DocViewerModalComponent } from "../doc-viewer-modal/doc-viewer-modal.component";
import { ChatbotStateService } from "app/services/chatbot-state.service";
import { ChatbotUiStateService } from "app/services/chatbot-ui-state.service";
import { TestStepService } from "app/services/test-step.service";
import { LicenceSevices } from "app/shared/services/license.service";
import { ChatbotTestCasePanelComponent } from "./chatbot-test-case-panel.component";

export interface TestCase {
  id: string;
  name: string;
}

@Component({
  selector: "app-chatbot",
  templateUrl: "./chatbot.component.html",
  styleUrls: ["./chatbot.component.scss"],
})
export class ChatbotComponent implements OnInit {
  messages: Message[] = [];
  testLiveExecutionActions = TEST_LIVE_EXECUTION;
  runResult = RUN_RESULT;
  testLiveExecutions = TEST_LIVE_EXECUTIONS;
  messageHistory: string[] = []; // Track message history for back navigation
  currentMessageId: string = 'main_menu';
  currentDocLink: string | null = null;
  safeSrcUrl: SafeResourceUrl | null = null;
  showDocFrame: boolean = false;
  @Output() chatbotClose = new EventEmitter<void>();
  private isDialogOpen = false;
  public versionId = 1;
  public version: WorkspaceVersion;
  public userPreference: UserPreference;
  isLoading = false;
  iframeError: boolean = false;
  errorMessage: string = '';
  inputControl: FormControl = new FormControl('');
  showInput: boolean = false;
  inputError: string = '';
  private selectedOption: SelectOption | null = null;
  filteredTestCases: Observable<TestCase[]>;
  testCases: TestCase[] = []; // This will store all test cases
  isSearching = false;
  noResults = false;
  private searchSubject = new Subject<string>();
  @Input() isFullscreen: boolean = false;
  @Output() openedChange = new EventEmitter<void>();
  currentUrl: string = '';
  isCaseStepsPage: boolean = false;
  isRunResultpage: boolean = false;
  isExecutionStarted: boolean = false;
  activeTab: 'navigate' | 'testcase' | 'chat' = 'navigate';
  featureSupport: any;
  @ViewChild(ChatbotTestCasePanelComponent) testCasePanel: ChatbotTestCasePanelComponent;

  constructor(
    private matModal: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public workspaceVersionService: WorkspaceVersionService,
    public userPreferenceService: UserPreferenceService,
    private sanitizer: DomSanitizer,
    private testCaseService: TestCaseService,
    private chatbotStateService: ChatbotStateService,
    private chatbotUiState: ChatbotUiStateService,
    private sharedService: ChatbotStateService,
    private testStepService: TestStepService,
    private licenceService: LicenceSevices,
    private cdr: ChangeDetectorRef
  ) {
    this.initializeChat();
  }
  ngOnInit(): void {
    this.licenceService.lockData$.subscribe(data => {
      if (data) {
        this.featureSupport = data.featureSupport.aiTestCaseAssistant;
      }
    });
    this.updateRouteCondition(this.router.url);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateRouteCondition(event.url);
      }
    });

    this.testStepService.execution$.subscribe((testCaseId) => {
      if (testCaseId) {
        this.isExecutionStarted = true;
      }
    });

    this.fetchVersion();
    this.setupSearch();

    this.chatbotStateService.messages$.subscribe(messages => {
      if (messages.length > 0) {
        this.messages = messages;
      }
    });

    this.chatbotStateService.messageHistory$.subscribe(history => {
      if (history.length > 0) {
        this.messageHistory = history;
      }
    });

    this.chatbotStateService.currentMessageId$.subscribe(id => {
      if (id) {
        this.currentMessageId = id;
      }
    });

    this.route.data.subscribe(data => {
      this.isFullscreen = data['fullscreen'] || false;
    });

    // Add subscription to activeTab changes
    this.chatbotUiState.activeTab$.subscribe(tab => {
      if (tab) {
        this.activeTab = tab;
      }
    });

    this.chatbotUiState.testCaseSuggestion$.subscribe((suggestion) => {
      if (suggestion) {
        this.chatbotUiState.setTestCaseDetails(suggestion);
      }
    });
  }


  private updateRouteCondition(url: string) {
    this.currentUrl = url;

    this.isCaseStepsPage = (
      (this.currentUrl.startsWith('/td/cases/') && this.currentUrl.endsWith('/steps'))
    );

    this.isRunResultpage = (
      (this.currentUrl.startsWith('/td/1/results'))
    );
    console.log("Current URL:", this.currentUrl);
    console.log("Is Case Steps Page:", this.isCaseStepsPage);

    // Force UI update
    this.cdr.detectChanges();
  }

  fetchVersion() {
    this.userPreferenceService.show().subscribe(res => {
      this.versionId = Number(res.versionId) || 1;
    });
  }

  initializeChat() {
    const state = this.chatbotStateService.getCurrentState();

    if (state.messages.length > 0) {
      // Use existing state if available
      this.messages = state.messages;
      this.messageHistory = state.messageHistory;
      this.currentMessageId = state.currentMessageId;
    } else {
      // Initialize new chat
      const initialMessage = MESSAGE_MAP['main_menu'];
      if (initialMessage) {
        this.messages = [initialMessage];
        this.messageHistory = ['main_menu'];
        this.currentMessageId = 'main_menu';
        this.updateChatbotState();
      }
    }
  }

  onCloseChatbot() {
    this.chatbotClose.emit();
    this.testCasePanel.chatMessages = [];
  }

  onRestartChat() {
    this.chatbotStateService.resetState();
    this.initializeChat();

    // Reset the test case panel data if it exists
    if (this.testCasePanel) {
      this.testCasePanel.chatMessages = [];
      this.testCasePanel.featureDescription = '';
      this.testCasePanel.isLoading = false;
      this.testCasePanel.warningMessage = '';
    }

    // Switch to navigation tab to ensure panel is reset properly
    // this.activeTab = 'navigate';
  }

  // Add method to check if a message is the current one
  isCurrentMessage(message: Message): boolean {
    return 'id' in message && message.id === this.currentMessageId;
  }

  // Add method to go back
  onBack(): void {
    if (this.messageHistory.length > 1) {
      // Remove current message
      this.messageHistory.pop();

      // Get previous message ID
      const previousMessageId = this.messageHistory[this.messageHistory.length - 1];
      const previousMessage = MESSAGE_MAP[previousMessageId];

      if (previousMessage) {
        // Remove messages until we find the previous question message
        while (this.messages.length > 0) {
          const lastMessage = this.messages[this.messages.length - 1];
          if (lastMessage.type === 'question' && 'id' in lastMessage && lastMessage.id === previousMessageId) {
            break;
          }
          this.messages.pop();
        }

        // Set the current message ID
        this.currentMessageId = previousMessageId;
      }
    }

    // Add state update after messages are modified
    this.updateChatbotState();
  }

  onIframeError() {
    // Automatically handle the error by opening in new tab
    this.isLoading = false;
    this.openDocInNewWindow();
    this.closeDocFrame();
  }

  openDocInNewWindow() {
    if (this.currentDocLink) {
      // Open in modal dialog instead of new window
      const dialogRef = this.matModal.open(DocViewerModalComponent, {
        width: '19vw',
        height: '51vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        position: { top: '5rem', right: '1rem' },
        panelClass: 'doc-viewer-dialog',
        data: { url: this.currentDocLink },
        hasBackdrop: false
      });

      dialogRef.afterClosed().subscribe(() => {
        this.closeDocFrame();
      });
    }
  }

  openDocInSameWindow() {
    if (this.currentDocLink) {
      window.location.href = this.currentDocLink;
    }
  }

  onIframeLoad() {
    this.isLoading = false;
    this.iframeError = false;
  }

  onOptionSelect(option: SelectOption) {
    console.log("??", option)

    // Only allow selection if it's the current message
    const lastMessage = this.messages[this.messages.length - 1];
    if (lastMessage.type === 'question' && !this.isCurrentMessage(lastMessage)) {
      return;
    }

    // Store the selected option for later use
    this.selectedOption = option;

    // Add user's selection as a message
    this.messages.push({
      type: "text",
      text: option.text,
      sentBy: "user",
      timestamp: new Date()
    });

    // Find and add next message if specified
    if (option.next && MESSAGE_MAP[option.next]) {
      const nextMessage = { ...MESSAGE_MAP[option.next], timestamp: new Date() };

      // If it's an input question, set up the form control
      if (nextMessage.type === 'question' &&
        nextMessage.question.type === 'input' &&
        nextMessage.question.isAutocomplete) {
        const validation = nextMessage.question.validation;
        this.inputControl = new FormControl('', {
          validators: [Validators.required]
        });
        this.showInput = true;
        this.setupTestCaseFilter();
      }

      this.messages.push(nextMessage);
      this.messageHistory.push(option.next);
      this.currentMessageId = option.next;
    }

    // Handle document type actions
    if (option.action?.type === 'document') {
      this.isLoading = true;
      this.currentDocLink = option.action.payload.link;

      // First try loading in iframe
      this.safeSrcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentDocLink);
      this.showDocFrame = true;

      // Set a shorter timeout since we know GitBook will block it
      setTimeout(() => {
        if (this.isLoading) {
          // If loading fails, automatically open in new tab
          this.openDocInNewWindow();
          this.closeDocFrame();
        }
      }, 1000); // Reduced timeout to 1 second since we expect it to fail
      return;
    }

    // Handle navigation if route is specified
    if (option.route) {
      const routePath = option.route.replace(':versionId', this.versionId.toString());
      // Set chatbot state before navigation
      this.chatbotUiState.setOpened(true);
      this.router.navigate([routePath]);
    }

    // Handle dialog/function actions
    if (option.action) {
      this.executeAction(option.action);
    }

    // Add state update after messages are modified
    this.updateChatbotState();
  }

  onInputSubmit() {

    console.log(">>>>")
    if (this.isSearching) {
      return; // Prevent submission during search
    }

    if (!this.isValidTestCase(this.inputControl.value)) {
      this.inputError = 'Please select a valid test case';
      return;
    }

    const selectedTestCase = this.inputControl.value as TestCase;

    // Store user message
    this.messages = this.messages || [];
    this.messages.push({
      type: "text",
      text: `Selected Test Case: ${selectedTestCase.id} - ${selectedTestCase.name}`,
      sentBy: "user",
      timestamp: new Date()
    });

    // Clear input and errors
    this.showInput = false;
    this.inputControl.reset();
    this.inputError = '';

    // Update chatbot UI state before navigation
    this.chatbotUiState.setOpened(true);

    // Navigate and execute test only if navigation is successful
    this.router.navigate(['/td', 'cases', selectedTestCase.id, 'steps'])
      .then(success => {
        if (success) {
          this.executeTest(selectedTestCase.id);
        } else {
          console.error("Navigation failed");
        }
      })
      .catch(err => console.error("Navigation error:", err));

    // Update chatbot state only if messages exist
    if (this.messages.length > 0) {
      this.updateChatbotState();
    }
  }

  // Helper function for type checking
  private isValidTestCase(value: any): value is TestCase {
    return value && typeof value.id === 'number' && typeof value.name === 'string';
  }

  private executeTest(testCaseId: string) {
    if (!this.selectedOption) return;

    // Get the appropriate confirmation message
    const confirmationMessage = TEST_CASE_CONFIRMATION_MESSAGES[this.selectedOption.value] ||
      'Test Case {id} selected.';

    // Add the confirmation message
    this.messages.push({
      type: "text",
      text: confirmationMessage.replace('{id}', testCaseId),
      sentBy: "bot",
      timestamp: new Date()
    });

    // Get the appropriate follow-up message ID
    const followUpMessageId = FOLLOW_UP_MESSAGES[this.selectedOption.value];

    if (followUpMessageId && MESSAGE_MAP[followUpMessageId]) {
      const followUpMessage = MESSAGE_MAP[followUpMessageId];
      this.messages.push({
        ...followUpMessage,
        timestamp: new Date()
      });
      this.messageHistory.push(followUpMessageId);
      this.currentMessageId = followUpMessageId;
    }

    // Clear the selected option
    this.selectedOption = null;

    // Add state update after messages are modified
    this.updateChatbotState();
  }

  private executeAction(action: ChatbotAction) {
    switch (action.type) {
      case 'dialog':
        if (action.payload.component && !this.isDialogOpen) {
          const dialogRef = this.matModal.open(
            action.payload.component,
            action.payload.dialogConfig || {}
          );
          this.isDialogOpen = true;
          dialogRef.afterClosed().subscribe(() => {
            this.isDialogOpen = false;
          });
        }
        break;
      case 'function':
        if (action.payload.callback) {
          action.payload.callback();
        }
        break;
      case 'navigation':
        // Handle navigation if needed
        break;
    }
  }

  closeDocFrame() {
    this.showDocFrame = false;
    this.currentDocLink = null;
    this.safeSrcUrl = null;
  }
  private setupSearch() {
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(searchTerm => {
        // If input is empty, reset states and stop searching
        if (!searchTerm?.trim()) {
          this.isSearching = false;
          this.noResults = false;
          return;
        }

        this.isSearching = true;
        this.noResults = false;
      }),
      switchMap(searchTerm => {
        if (!searchTerm?.trim()) {
          return of({ content: [] }); // Return empty result immediately
        }
        const query = `name:*${encodeURIComponent(searchTerm.trim())}*`;
        return this.testCaseService.findAll(query).pipe(
          catchError(() => of({ content: [] })),
          finalize(() => this.isSearching = false)
        );
      })
    ).subscribe(response => {
      this.noResults = response.content.length === 0;
    });
  }


  private setupTestCaseFilter() {
    this.filteredTestCases = this.inputControl.valueChanges.pipe(
      tap(value => {
        if (typeof value === 'string') {
          this.searchSubject.next(value);
        }
      }),
      switchMap(value => {
        if (typeof value !== 'string') {
          return of([]);
        }
        if (!value?.trim()) {
          return of([]);
        }
        const query = `name:*${encodeURIComponent(value.trim())}*`;
        return this.testCaseService.findAll(query).pipe(
          map(response => response.content || []),
          catchError(() => of([]))
        );
      })
    );
  }

  displayFn = (testCase: TestCase): string => {
    return testCase ? `#${testCase.id} - ${testCase.name}` : '';
  }

  // Add this getter to determine if we're in initial state
  get isInitialState(): boolean {
    return this.isFullscreen &&
      this.messages.length === 1 &&
      this.currentMessageId === 'main_menu';
  }

  // Add this method to update state
  private updateChatbotState() {
    this.chatbotStateService.updateState(
      this.messages,
      this.messageHistory,
      this.currentMessageId
    );
  }
  closeModals() {
    this.matModal.closeAll();
  }
  redirect(option: any) {
    console.log(option)
    if (option.value === "RunTestCase") {
      this.sharedService.triggerRunTestCase();
    }
    if (option.value === "RunHistory") {
      this.sharedService.triggerRunHistory();
    }
    this.router.navigate([option.route]);
    this.closeModals();

  }

  onSwitchTab(tab: 'navigate' | 'testcase') {
    this.activeTab = tab;
  }

}
