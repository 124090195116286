import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { LicenceSevices } from 'app/shared/services/license.service';

@Injectable({
  providedIn: "root",
})
export class LockDataGuard implements CanActivate, CanActivateChild {
  private lockData: any = null; // Ensure it's initially null

  constructor(private licenceSevices: LicenceSevices, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.getFeatureAccess(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.getFeatureAccess(route, state);
  }


  private getFeatureAccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.lockData !== null) {
      console.log("Using Cached Feature Data");
      return this.checkFeatureAccess(state.url);
    }

    return this.licenceSevices.getAll().pipe(
      switchMap((data: any) => {
        this.lockData = data.featureSupport;
        console.log("Feature Data Loaded:", this.lockData);
        return of(this.checkFeatureAccess(state.url));
      })
    );
  }

  private checkFeatureAccess(url: string): boolean {
    const feature = this.getFeatureKeyFromRoute(url);
    console.log("Checking feature access for:", feature);
    console.log("Feature access in lockData:", this.lockData);

    if (feature && this.lockData && this.lockData[feature] === false) {
      console.warn("Access Denied: Redirecting to dashboard...");
      this.router.navigate(["/dashboard"]);
      return false;
    }

    console.log("Access Granted for:", feature);
    return true;
  }

  private getFeatureKeyFromRoute(url: string): string | null {
    console.log("URL:", url);

    if (url.includes("/elements")) return "elementDirectory";
    if (url.includes("/mobile-inspector")) return "mobileInspector";
    if (url.includes("/graphs")) return "graphs";
    if (url.includes("/impactAnalysis")) return "impactAnalysis";
    if (url.includes("/workspaces")) return "workspace";
    if (url.includes("/uploads")) return "uploads";
    if (url.includes("/Knowledge_Base")) return "knowledgeBasePrompt";
    if (url.includes("/custom_agents")) return "persona";
    if (url.includes("/data")) return "testDataProfile";
    if (url.includes("/results")) return "runResults";
    if (url.includes("/environments")) return "environments";
    return null;
  }
}
