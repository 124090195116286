import {
    Component,
    OnInit,
    HostBinding,
    ChangeDetectorRef,
    AfterViewInit,
    ViewChild,
    ElementRef
} from "@angular/core";
import { NgModule } from '@angular/core';
import { BaseComponent } from "../../shared/components/base.component";
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { CustomAgentService, ApiResponse, CustomAgent } from "./custom-agent.service";
import { FormsModule } from '@angular/forms';
import { NotificationsService,  NotificationType} from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ProgressAnimationType } from "ngx-toastr/toastr/toastr-config";
import { ToastrModule } from 'ngx-toastr';
import 'trix';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: "app-custom-agent",
    templateUrl: "./custom-agent.components.html",
    styleUrls: ["./custom-agent.components.scss"]
})
export class CustomAgentComponent extends BaseComponent implements OnInit, AfterViewInit {
    selectedTabIndex = 1;
    showModal = false;
    name = '';
    description = '';
    editingCustomAgentId: string | null = null;
    showDeleteConfirmation = false;
    custom_agent_ToDelete: CustomAgent | null = null;
    editor: any;
    @ViewChild('trixEditor') trixEditor: ElementRef;
    custom_agent: any;

    constructor(
        private CustomAgentService: CustomAgentService,
        private notificationService: NotificationsService,
        public translate: TranslateService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.loadCustomAgents();
    }

    ngAfterViewInit() {
        // Initialize any view-related logic here
    }

    loadCustomAgents() {
        this.CustomAgentService.getCustomAgents()
            .subscribe({
                next: (response: ApiResponse) => {
                    // Set isActive based on matching default ID
                    // this.prompts = (response?.response?.prompts || []).map(prompt => ({
                    //     ...prompt,
                    //     name: prompt.title,
                    //     description: prompt.prompt,
                    //     isActive: prompt.id === response?.response?.default
                    // }));
                    this.custom_agent = response?.response
                    console.log('API Response:', response);
                    console.log('Loaded prompts:', this.custom_agent);
                },
                error: (error) => {
                    console.error('Error fetching prompts:', error);
                    this.custom_agent = [];
                }
            });
    }

    editCustomAgent(custom_agent: CustomAgent) {
        this.editingCustomAgentId = custom_agent.id;
        this.name = custom_agent.name;
        this.description = custom_agent.description;
        this.showModal = true;
    }

    public showNotification(type: NotificationType, message: string) {
        const options = {
            timeOut: 7000,
            positionClass: 'toast-bottom-left',
            progressBar: true,
            progressAnimation: 'increasing' as ProgressAnimationType,
            closeButton: true,
            enableHtml: true,
            toastClass: 'ngx-toastr',
            titleClass: 'toast-title',
            messageClass: 'toast-message',
            easing: 'ease-in',
            easeTime: 300,
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning'
            }
        };

        switch (type) {
            case NotificationType.Success:
                this.notificationService.success('Success', message, options);
                break;
            case NotificationType.Error:
                this.notificationService.error('Error', message, options);
                break;
            case NotificationType.Info:
                this.notificationService.info('Info', message, options);
                break;
            default:
                this.notificationService.info('Info', message, options);
        }
    }

    deleteCustomAgent(customAgent: CustomAgent) {
        this.custom_agent_ToDelete = customAgent;
        this.showDeleteConfirmation = true;
    }

    confirmDelete() {
        if (!this.custom_agent_ToDelete) return;

        this.CustomAgentService.deleteCustomAgent(this.custom_agent_ToDelete.id)
            .subscribe({
                next: () => {
                    this.showNotification(NotificationType.Success, "Custom Agent was successfully deleted");
                    this.loadCustomAgents();
                    this.closeDeleteConfirmation();
                },
                error: (error) => {
                    this.showNotification(NotificationType.Error, "Failed to delete Custom Agent");
                    console.error('Error deleting Custom Agent:', error);
                    this.closeDeleteConfirmation();
                }
            });
    }

    closeDeleteConfirmation() {
        this.showDeleteConfirmation = false;
        this.custom_agent_ToDelete = null;
    }

    savePrompt() {
        if (!this.name || !this.description) {
            return;
        }

        const request = this.editingCustomAgentId 
            ? this.CustomAgentService.updateCustomAgent(this.editingCustomAgentId, this.name, this.description)
            : this.CustomAgentService.createCustomAgent(this.name, this.description);

        request.subscribe({
            next: () => {
                if(this.editingCustomAgentId){
                    this.showNotification(NotificationType.Success, "Custom Agent was successfully updated");
                }else{
                    this.showNotification(NotificationType.Success, "Custom Agent was successfully created");
                }
                this.loadCustomAgents();
                this.closeModal();
            },
            error: (error) => {
                if(this.editingCustomAgentId){
                    this.showNotification(NotificationType.Error, "Failed to update Custom Agent");
                }else{
                    this.showNotification(NotificationType.Error, "Failed to create Custom Agent");
                }
                console.error('Error saving Custom Agent:', error);
            }
        });
    }

    openCreateDialog() {
        this.editingCustomAgentId = null;
        this.showModal = true;
        this.name = '';
        this.description = '';
    }

    closeModal() {
        this.showModal = false;
        this.editingCustomAgentId = null;
    }

    togglePromptStatus(data: CustomAgent) {
        const newStatus = !data.isActive;
        this.CustomAgentService.updateCustomAgentStatus(data.id, newStatus)
            .subscribe({
                next: () => {
                    // Update all other prompts to inactive if this one is being activated
                    if (newStatus) {
                        this.custom_agent.forEach(p => {
                            if (p.id !== data.id) {
                                p.isActive = false;
                            }
                        });
                    }
                    data.isActive = newStatus;
                    const message = newStatus ? 
                        "Prompt was successfully set as default" : 
                        "Prompt was successfully deactivated";
                    this.showNotification(NotificationType.Success, message);
                    this.loadCustomAgents();
                },
                error: (error) => {
                    this.showNotification(NotificationType.Error, "Failed to update Custom Agent status");
                    console.error('Error updating prompt status:', error);
                }
            });
    }

    onTrixChange(event: any) {
        this.description = event.target.innerHTML;
    }

    setDescription(description: string) {
        if (this.trixEditor) {
            this.trixEditor.nativeElement.editor.loadHTML(description);
        }
    }
}

@NgModule({
    declarations: [CustomAgentComponent],
    imports: [
        CommonModule,
        ScrollingModule,
        FormsModule,
        ToastrModule.forRoot({
            timeOut: 7000,
            positionClass: 'toast-bottom-left',
            preventDuplicates: true,
            progressBar: true,
            progressAnimation: 'increasing',
            closeButton: true,
            enableHtml: true,
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning'
            },
            toastClass: 'ngx-toastr'
        }),
        MatIconModule,
        MatButtonModule
    ],
    exports: [CustomAgentComponent]
})

export class CustomAgentModule { }