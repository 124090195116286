<div class="logs-expanded-container">
  <div class="logs-expanded-header">
    <div class="logs-expanded-title">
      <mat-icon [ngClass]="{
        'success': data.stepStatus === 'SUCCESS',
        'failure': data.stepStatus === 'FAILURE',
        'pending': data.stepStatus !== 'SUCCESS' && data.stepStatus !== 'FAILURE'
      }">
        {{ data.stepStatus === 'SUCCESS' ? 'check_circle' :
        data.stepStatus === 'FAILURE' ? 'error' : 'hourglass_empty' }}
      </mat-icon>
      <h2>Step Logs</h2>
      <div class="status-badge" [ngClass]="{
        'success': data.stepStatus === 'SUCCESS',
        'failure': data.stepStatus === 'FAILURE',
        'pending': data.stepStatus !== 'SUCCESS' && data.stepStatus !== 'FAILURE'
      }">
        {{ data.stepStatus || 'PENDING' }}
      </div>
    </div>
    <div class="logs-expanded-actions">
      <button mat-button (click)="copyAllLogs()">
        <mat-icon>content_copy</mat-icon>
        Copy All
      </button>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="logs-expanded-content">
    <div class="log-entry" *ngFor="let log of data.logs; let i = index" [ngClass]="{'typing': !log.complete}">
      <div class="log-timestamp">{{ log.timestamp | date:'HH:mm:ss.SSS' }}</div>
      <div class="log-message" [class.complete]="log.complete">
        {{ log.text }}
        <span *ngIf="!log.complete" class="typing-cursor">|</span>
      </div>
    </div>

    <div *ngIf="!data.logs || data.logs.length === 0" class="logs-empty-state">
      <mat-icon class="empty-icon">info</mat-icon>
      <span class="empty-text">No logs available for this step</span>
    </div>
  </div>
</div>
