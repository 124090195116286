import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class ConversionService {
    private apiUrl = 'https://web-finder.contextqa.com/agent/enhance';


    constructor(private http: HttpClient) { }

    enhanceDescription(description: string): Observable<any> {
        const payload = {
            query: description
        };

        return this.http.post(this.apiUrl, payload, {
            headers: {
                'origin': '@https://web-finder.contextqa.com',
                'Content-Type': 'application/json'
            }
        }).pipe(
            catchError(this.handleError)
        );
    }

    private handleError(error: any): Observable<any> {
        console.error('An error occurred:', error);
        return throwError(() => error);
    }
}
    
