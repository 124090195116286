import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";

@Injectable()
export class UrlConstantsService {
  public environmentUrl = `${environment.apiUrl}`;

  public CQA_LAB_SERVERurl = `${environment.CQA_LAB_SERVER}`;

  public CQA_LAB_SERVERurl_1 = `${environment.CQA_LAB_SERVER_1}`;

  // public apiBase = this.environmentUrl;
  public isGCP: boolean = false

  public PYTHON_AI_ENDPOINT = "https://web-finder.contextqa.com"
  // public apiBase1 = this.isGCP ? this.CQA_LAB_SERVERurl : this.CQA_LAB_SERVERurl_1;
  public apiBase1 = this.CQA_LAB_SERVERurl_1;
  public sessionUrl = '/sessions';
  public agentsUrl = '/settings/agents';
  public browserUrl = '/test_devices_type?query=deviceType:browser';
  public deviceUrl = '/test_devices_type?query=deviceType:device';
  public uploadsUrl = "/uploads";
  public dashboardHealth = "/dashboard/health";
  public dashboardTrends = "/dashboard/trends";
  public uploadVersionsUrl = "/upload_versions";
  public elementURL = "/elements";
  public elementScreenNameURL = "/elements_screen_name";
  public workspaceVersionsURL = "/workspace_versions";

  public testSuiteExecute = this.apiBase1 + '/ts';
  public testPlanExecute = this.apiBase1 + '/tp';

  public videoContant = '/video/content';
  public videoTest = '/video/test';


  public attachmentsUrl = '/attachments';
  public environmentsUrl = '/environments';
  public mobileInspectionsUrl = '/mobile_inspections';
  public licenceUrl = '/license';
  public FreePricing = '/stripe/checkout?licenseType=price_1NiHpeKCkF1UmVlRsN9pdjwg'
  public BaciPricing = '/stripe/checkout?licenseType=price_1NiHrwKCkF1UmVlRXGm4GAsu'
  public PremiumPricing = '/stripe/checkout?licenseType=price_1NiHsgKCkF1UmVlRBtCzXhFF'
  public ElitePricing = '/stripe/checkout?licenseType=price_1NiHpeKCkF1UmVlRsN9pdjwg'

  public testPlansUrl = '/test_plans';
  public testRundataUrl = '/test_plan_results';
  public testDevicesUrl = '/test_devices';

  public testPlanResultsUrl = '/test_plan_results';
  public testPlanResultsUrl1 = this.apiBase1 + '/tp'
  public testPlanResultsUrl2 = this.apiBase1 + '/tp_rerun'

  public testDeviceResultsUrl = '/test_device_results';
  public testSuiteResultsUrl = '/test_suite_results';
  public testCaseResultsUrl = '/test_case_results';
  public testCaseResultsAiSuggestionsUrl = '/test_case_ai_suggession';
  public testCaseDataDrivenResultsUrl = '/test_case_data_driven_results';
  public testStepResultsUrl = '/test_step_results';
  public executionResultOpenai = '/openai';
  public naturalTextActionsUrl = '/natural_text_actions';
  public openAiCode = '/openai/test_cases';
  public testCasesUrl = '/test_cases';
  public impactAnalysisList = '/test_case_results/impact';
  public dryTestPlansUrl = '/dry_test_plans';
  public userPreferencesUrl = '/user_preferences';
  public testCaseMatrix = '/test_cases/matrix/tags';
  public testCaseMatrixDay = '/test_cases/matrix/days';
  public projectsUrl = '/new/projects';
  public workspacesUrl = '/workspaces';
  public testCaseTagsUrl = '/testcase_tags';
  public testSuiteTagsUrl = '/testsuite_tags';
  public testPlanTagsUrl = '/test_plan_tags';
  public elementTagsUrl = "/element_tags"
  public screenshotComparisonsUrl = '/screenshot_comparisons';
  public testStepScreenshotsUrl = '/test_step_screenshots';
  public testCasePrioritiesUrl = '/test_case_priorities';
  public testCaseTypesUrl = '/test_case_types';
  public integrationsUrl = "/settings/integrations";
  public externalMappingsUrl = "/external_mappings";
  public dataProfileUrl = '/test_data';
  public elementFilterUrl = '/element_filters';
  public testCaseFilterUrl = '/test_case_filters';
  public stepGroupFilterUrl = '/step_group_filters';
  public scheduledPlanUrl = '/schedule_test_plans';
  public testStepsUlr = '/test_steps';

  public testCaseExecute = this.apiBase1 + '/tc';

  public suggestionEngineUrl = "/suggestion_results";
  public testDataFunctionsUrl = "/default_data_generators";
  public testSuitesUrl = "/test_suites";
  public testSuiteshierarchyUrl = "/test_suites/hierarchy";
  public folderViewURL = "/test_suites/folderView";
  public folderStructureURL = "/test_suites/hierarchy";
  public adhocRunConfigurationsUrl = "/adhoc_run_configurations";
  public loginUrl = '/login';
  public logoutUrl = '/logout';
  public iosSettingUrl = '/settings/provisioning_profiles';
  public kibbutzUrl = '/kibbutz';
  public addonUrl = '/addons';
  public cloudMobileInspectionsUrl = '/driver_sessions';
  public testsigmaOSConfigURL = '/testsigma_os_config';
  public storageConfigURL = '/storage_config';
  public authConfigURL = '/auth_config';
  // public serverURL =  '/servers';
  public osServerDetailsURL = '/os_server_details';

  public backupUrl = "/settings/backups";
  public backupExportUrl = "/settings/backups/export";
  public backupXmlUrl = "/settings/backups/xml";

  public onboardingURL = '/onboarding';
  public platformURL = '/platforms';

  public agentDownloadTagUrl = this.agentsUrl + "/download_tag";

  public aiSuggestion = this.PYTHON_AI_ENDPOINT + "/case/suggestions/edge";
  public aiSuggestionSingle = this.PYTHON_AI_ENDPOINT + "/case/suggestions/single";
  public aiSuggestionTestcase = this.PYTHON_AI_ENDPOINT + "/case/suggestions/ai";
  public documentUpload = this.PYTHON_AI_ENDPOINT + "/agent/transform-excel-to-contextqa";
  public requirementsUpload = this.PYTHON_AI_ENDPOINT + "/case/suggestions/requirements";

  constructor() {
  }

  reasignVariable() {
    this.testCaseExecute = this.apiBase1 + '/tc';
    this.testPlanResultsUrl1 = this.apiBase1 + '/tp';
    this.testPlanResultsUrl2 = this.apiBase1 + '/tp_rerun';
    this.testSuiteExecute = this.apiBase1 + '/ts';
    this.testPlanExecute = this.apiBase1 + '/tp';
  }

}
