<div class="execution-start-container">
  <div class="execution-start-content">
    <div class="confirm-overlay" >
      <p class="confirm-message">Are you sure to close execution?</p>
      <div class="confirm-buttons">
        <button mat-button (click)="cancelClose()" class="theme-btn-clear-default">Cancel</button>
        <button mat-button (click)="confirmClose()"  class="theme-btn-clear-default">OK</button>

      </div>
    </div>
  </div>
</div>
