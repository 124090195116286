import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, Optional, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TestCaseStatus } from 'app/enums/test-case-status.enum';
import { WorkspaceVersion } from 'app/models/workspace-version.model';
import { TestCaseService } from 'app/services/test-case.service';
import { UserPreferenceService } from 'app/services/user-preference.service';
import { WorkspaceVersionService } from 'app/shared/services/workspace-version.service';
import { MixpanelService } from '../../../mixpanel.service';
import { NgZone } from '@angular/core';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AiTestCaseService } from 'app/services/ai-test-case.service';
import { Page } from 'app/shared/models/page';
import { TestCase } from 'app/models/test-case.model';
import { ChromeExtensionService } from '../../../services/chrome.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NotificationType } from 'angular2-notifications';
import { ProgressAnimationType, ToastrService } from 'ngx-toastr';
import { CustomAgentService, ApiResponse } from '../../custom-agent/custom-agent.service';
import { AiPromptsService } from '../../ai-prompts/ai-prompts.service';
import { AiPrompt } from '../../ai-prompts/ai-prompts.service';
import { K } from '@angular/cdk/keycodes';
import { Socket } from "ngx-socket-io";
import { HttpErrorResponse } from '@angular/common/http';
import { TestStepService } from 'app/services/test-step.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { ExecutionModelComponent } from '../../results/execution/execution-model/execution-model.component';
import { SharedDataService } from '../../../../app/shared/services/shared-data';
import { LicenceSevices } from 'app/shared/services/license.service';
import { UrlWarningDialogComponent } from '../../shared/url-warning-dialog/url-warning-dialog.component';
import { ConversionComponent } from '../conversion.component';
import { ConversionService } from '../conversion.service';

@Component({
  selector: 'app-model-content',
  templateUrl: './model-content.component.html',
  styleUrls: ['./model-content.component.scss']
})
export class ModelContentComponent implements OnInit {

  public versionId: any;
  public version: WorkspaceVersion;
  public urlForm: FormGroup;
  public origin: any;
  public testCase: any = {};
  public testCaseList: Page<TestCase> | null = null;
  public testCaseId: number;
  public isFromAiChat: boolean = false;
  public cqaOrigin: string;
  public testSuiteId: number;
  public prompts: Array<AiPrompt> = [];
  public testSteps: any;
  public seconds: number = 0;
  public minutes: number = 0;
  public failSteps: number = 0;
  public successSteps: number = 0;
  public stepsData: any[] = [];
  public interval: any;
  public ExecutedStepsData: any[] = [];
  public lastValidImageUrl: string | null = null;
  public enhancedData: any = null;

  @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef<any>;
  dialogReff!: MatDialogRef<any>;
  tabs: chrome.tabs.Tab[] = [];
  isExtensionInstalled: boolean;
  private extensionId = 'pgadpooodaipbhbchnojijmlkhpamadh';
  checkExtensionInstall: boolean = true;
  createdTestCaseData: any;
  openTabs: string[] = [];
  isLoading = false;
  testcaseListLength;
  public selectedTabUrl: string | null = null;
  filteredUrls: Observable<any[]>;
  predefinedUrls: any[] = [];
  isInputFocused = false;
  selectedOption: 'prebuilt' | 'AITestcase' | 'uploadDocument' | 'qa' | 'manuall' | null = null;
  public aiTestcaseForm: FormGroup;
  public autoCompleteActive = false;
  public selectedPrerequisite: any = null;
  @ViewChild('autoComplete') autoComplete: any;
  private dropdownVisible = false;
  public isDropdownOpen = false;
  public showWarning: boolean = true;
  isAIInputFocused = false;
  aiFilteredUrls: Observable<any[]>;
  documentForm: FormGroup;
  selectedFileName: string | null = null;
  showAdvancedSettings = false;
  customAgents: ApiResponse | null = null;
  private IsExecuteBtn = false;
  private stespDetail: any;
  private topicId: string | undefined;
  private testRunId: string | undefined;
  lockData: any;
  public SAMPLE_XLSX_URL = 'https://cqa-media.s3.us-east-2.amazonaws.com/contextQA_sample_testcases.xlsx';
  public isRequirementsMode = false;
  public SAMPLE_CSV_URL = 'assets/sample-requirements.csv';

  actions = [
    {
      title: 'https://magenta-flan-a983d0.netlify.app/login/',
      description: "Login using the credentials email: admin@demo.com and password: admin123, then click on the Login button. Navigate to the Recently Viewed section and select the Classic T-Shirt. Choose Size S, Scroll to the middle of the screen, and click on the Add to Bag button. Then, click on the cart icon at the top right and proceed by clicking the Checkout button. Enter the coupon code: 502060, then scroll to the middle of the screen click on Checkout again, and verify that the order has been successfully placed.",
    },
    {
      title: 'https://magenta-flan-a983d0.netlify.app/login/',
      description: "Log in using the credentials: email admin@demo.com and password admin123, then click the Login button. Navigate to the Address section and select New Address. Enter the required details: Name as John, enter Address as John House, Country as Peak, State as Clock Tower, Postal Code as 205060, Finally, click the Save button to complete the process.",
    },
    {
      title: 'http://crm-demo.contextqa.com/login',
      description: "Enter email and password then log in., Click on 'Invoices', Click on 'Add New Invoice', Click on 'Client', Enter 'Aarti', Select the 'Aarti' option, Click on 'Item Name', Enter 'Mobile', Click on 'Quantity' and enter 2, Click on 'Price' and enter 25,Scroll down the page, Click on the 'Select Tax Value' dropdown, In the 'Select Tax Value' dropdown, choose 'Tax 0%' Option, Click on 'Save'.",
    },
    {
      title: 'http://crm-demo.contextqa.com/login',
      description: "Log in using the default credentials, then go to the Customer section. Click on 'Add New Client' to add a new client. Fill in the details: enter 'John Doe' as the name in the right sidebar, type 'Afghanistan' in the Country field in the right sidebar and select the first suggestion, enter 'Peak' as the address in the right sidebar, '+1 8844557784' as the phone number in the right sidebar, and 'John@gmail.com' as the email in the right sidebar. Once all details are entered, click Submit. Validate the email and phone number format, and ensure the client is successfully registered.",
    }
  ];

  public executionDialog: MatDialogRef<any>;
  public logMessages: any[] = [];
  public rerunSubscription: any;
  public OnRun: () => void;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private socket: Socket,
    public userPreferenceService: UserPreferenceService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ModelContentComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public testCaseService: TestCaseService,
    public translate: TranslateService,
    private mixpanelService: MixpanelService,
    private versionService: WorkspaceVersionService,
    private ngZone: NgZone,
    private aiTestCaseService: AiTestCaseService,
    private ChromeExtensionService: ChromeExtensionService,
    private customAgentService: CustomAgentService,
    private aiPromptsService: AiPromptsService,
    private testStepService: TestStepService,
    private sharedDataService: SharedDataService,
    private LicenceSevices: LicenceSevices,
    private conversionService: ConversionService,
    @Optional() public toastrService?: ToastrService
  ) {
    this.testSuiteId = data.testSuiteId;
    this.checkExtension();
    this.urlForm = this.formBuilder.group({
      url: [''],
      preRequisite: ['']
    });
    this.aiTestcaseForm = this.formBuilder.group({
      preRequisite: [''],
      url: ['', [Validators.required, this.urlValidator()]],
      description: ['', Validators.required],
      knowledgeBase: ['none'],
      persona: ['default']
    });
    this.documentForm = this.formBuilder.group({
      document: [null, Validators.required]
    });
    this.loadPrompts();
  }
  ngOnInit(): void {
    this.FetchLicence();
    this.urlForm = this.formBuilder.group({
      url: ['', Validators.required],
      preRequisite: ['']

    });
    this.aiTestcaseForm = this.formBuilder.group({
      preRequisite: [''],
      url: ['', [Validators.required, this.urlValidator()]],
      description: ['', Validators.required],
      knowledgeBase: ['none'],
      persona: ['default']
    });

    // Ensure the knowledgeBase control is set to 'none'
    this.aiTestcaseForm.patchValue({
      knowledgeBase: 'none',
      persona: 'default'
    });

    this.origin = window.location.hostname.split('.')[0];
    this.fetchVersion();
    this.fetchTestcase();
    this.fetchTabs();

    // Add validator subscription
    this.aiTestcaseForm.get('preRequisite').valueChanges.subscribe(value => {
      const urlControl = this.aiTestcaseForm.get('url');

      if (!value || value === 'none') {
        urlControl.setValidators([Validators.required]);
      } else {
        urlControl.clearValidators();
      }

      urlControl.updateValueAndValidity();
    });

    // Restore saved form values if they exist
    const savedValues = sessionStorage.getItem('formValues');
    if (savedValues) {
      const values = JSON.parse(savedValues);
      this.urlForm.patchValue(values);

      // Optional: Clear storage after restoring
      sessionStorage.removeItem('formValues');
    }

    // Setup filter for AI testcase form
    this.aiFilteredUrls = this.aiTestcaseForm.get('url').valueChanges.pipe(
      startWith(''),
      map(value => this._filters(value))
    );

    this.loadCustomAgents();
  }

  FetchLicence() {
    this.LicenceSevices.getAll().subscribe((data: any) => {
      this.lockData = data;
    })
  }

  private initializeFilteredUrls() {
    console.log('Initializing filtered URLs with predefinedUrls:', this.predefinedUrls);
    this.filteredUrls = this.urlForm.get('url')!.valueChanges.pipe(
      startWith(''),
      map(value => {
        console.log('Filtering with value:', value);
        return this._filter(value);
      })
    );
  }

  private _filter(value: string): any[] {
    if (!value) {
      console.log('Empty value, returning all predefinedUrls:', this.predefinedUrls);
      return this.predefinedUrls;
    }

    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
    const filtered = this.predefinedUrls.filter(item => {
      const urlToMatch = item.url.toLowerCase();
      return urlToMatch.includes(filterValue);
    });
    console.log('Filtered URLs:', filtered);
    return filtered;
  }

  displayFn = (item: any): string => {
    if (!item) return '';
    return typeof item === 'string' ? item : item.url;
  }

  onOptionSelected(event: any) {
    if (!event?.option?.value) return;

    const selectedOption = event.option.value;
    console.log('Selected option:', selectedOption);

    // Get the URL without protocol
    const url = selectedOption.url;
    console.log('Setting URL to:', url);

    // Update form control
    this.ngZone.run(() => {
      this.urlForm.get('url')?.setValue(url);
      this.isInputFocused = false;
    });
  }

  fetchTabs() {
    console.log("Fetching tabs...");

    try {
      if (typeof chrome !== 'undefined' && chrome?.runtime?.sendMessage) {
        chrome.runtime.sendMessage(
          this.extensionId,
          { event: 'getTabs' },
          (response) => {
            this.ngZone.run(() => {
              if (response && response.tabUrls) {
                this.tabs = response.tabUrls.map((url: string) => ({
                  url: url
                }));

                this.predefinedUrls = this.tabs.map(tab => ({
                  url: tab.url
                }));
                console.log('Predefined URLs updated:', this.predefinedUrls);

                this.initializeFilteredUrls();
              } else {
                console.log('No tabs response or empty tabUrls');
                this.setFallbackUrls();
              }
            });
          }
        );
      } else {
        console.log('Chrome runtime not available, using fallback data');
        this.setFallbackUrls();
      }
    } catch (error) {
      console.warn('Error fetching tabs:', error);
      this.setFallbackUrls();
    }
  }

  private setFallbackUrls() {
    this.predefinedUrls = [];
    console.log('Fallback URLs set:', this.predefinedUrls);
    this.initializeFilteredUrls();
  }

  fetchVersion() {
    this.userPreferenceService.show().subscribe(res => {
      this.versionId = res?.versionId;
      if (this.versionId) {
        this.versionService.show(this.versionId).subscribe(version => {
          this.version = version;
        });
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
  fetchTestcase() {
    const query = "deleted"
    const short = "createdDate,desc"
    const page = "undefined"
    this.testCaseService.findAll(query, short).subscribe((testcaseList) => {
      console.log("testcaseList ::", testcaseList)
      this.testcaseListLength = testcaseList.content.length
    })
  }

  getCurrentItemsforKnowledgeBase(items: any[], value: any) {
    if (!items?.length || !value) return null;
    return items.find(item => item.id === (value.id || value));
  }

  getCurrentItem(items: Page<TestCase> | null, value: any) {
    if (!items?.content || !value) return null;
    return items.content.find(item => item.id === value?.id);
  }

  fetchTestCases(term?) {
    let searchName = '';
    if (term) {
      searchName = ",name:*" + term + "*";
    }
    this.testCaseService.findAll("isStepGroup:false,workspaceVersionId:" + this.versionId + ",deleted:false" + searchName + ",status:" + TestCaseStatus.READY, undefined).subscribe(res => {
      res.content = res.content.filter(testcase => this.testCaseId != testcase.id)
      if (this.testCase?.id && this.testCase?.preRequisite) {
        if (!res?.content?.find(req => req.id == this.testCase.preRequisite)) {
          res.content.push(this.testCase.preRequisiteCase);
        }
      }
      this.testCaseList = res;
    });
  }
  setPreRequisite(event: any) {
    if (event) {
      this.aiTestcaseForm.patchValue({
        preRequisite: event
      });
    } else {
      // Handle when none is selected
      this.aiTestcaseForm.patchValue({
        preRequisite: 'none'
      });
    }
  }

  listenForTabUpdates(): void {
    if (chrome && chrome.runtime) {
      chrome.runtime.onMessage.addListener((message, sender, sendResponse) => {
        this.ngZone.run(() => {
          if (message.event === 'tabsUpdated' && message.tabs) {
            this.tabs = message.tabs.map((url: string, index: number) => ({
              url,
              // title: `Tab ${index + 1}` // Replace with actual title if available
            }));
            console.log('Real-time updated tabs:', this.tabs);
          }
        });
      });
    }
  }
  // Update the URL field when a tab is selected
  onTabSelect(selectedUrl: string): void {
    this.urlForm.patchValue({ url: selectedUrl });
  }
  onSubmit() {
    if (this.urlForm.valid) {
      this.isLoading = true;
      const url = this.urlForm.get('url').value;
      const fullUrl = url;

      let testCase = {
        "isExtensionUsed": false,
        "name": formatDate(new Date(), 'yyyy-MM-dd hh:mm', 'en-US').toString() + "_test_case",
        "description": null,
        "status": TestCaseStatus.READY,
        "sendMailNotification": false,
        "isStepGroup": false,
        "priorityId": 1,
        "type": 1,
        "preRequisite": this.urlForm.value.preRequisite,
        "isDataDriven": false,
        "workspaceVersionId": this.versionId,
        "testSuiteId": this.testSuiteId,
        "deleted": false,
        "testDataStartIndex": 0,
        "tags": []
      };
      let fieldName = 'Test Case';
      this.testCaseService.autocreate(testCase).subscribe(
        (testcase) => {
          this.mixpanelService.setUserProperties({ "Number_of_test_cases": this.testcaseListLength + 1 });
          this.mixpanelService.track('Test Case Created', {
            "id": testcase.id,
            "title": testcase.name,
            "status": TestCaseStatus.READY,
            "method": "Recorder",
          });
          this.isLoading = false;
          if (chrome && chrome.runtime) {
            let data = {
              type: "test_case",
              id: testcase.id,
              result: testcase,
              action: "openSidePanelFromPortal",
              origin: window.location.hostname.split(".")[0],
              jwt: localStorage.getItem("_t"),
              userEmail: localStorage.getItem('useremail')
            };
            chrome.runtime.sendMessage(
              this.extensionId,
              { message: "openSidePanelFromPortal", data: data },
              (data: any) => {
              }
            );
            this.closeDialog();
            this.router.navigate(['/td', 'cases', testcase.id, 'steps']);
            window.open(fullUrl, '_blank');
          } else {
            this.closeDialog();
            this.router.navigate(['/td', 'cases', testcase.id, 'steps']);
            window.open(fullUrl, '_blank');
          }
        },
        error => {
          this.isLoading = false;
          this.translate.get('message.common.created.failure', { FieldName: fieldName }).subscribe((res) => {
            // Handle error display
          })
        }
      )
    }
  }

  async createTestCaseCreateData(aiTestCaseData) {
    let testCaseSteps = [];

    // Add URL step if URL exists
    if (aiTestCaseData.url) {
      testCaseSteps.push({
        "actionName": "navigateToUrl",
        "naturalTextActionId": 425,
        "name": aiTestCaseData.url,
        "event": {
          "customEvent": "navigateToUrl",
          "href": aiTestCaseData.url
        },
        action: `Navigate to <span data-key=\"test-data\" data-event-key=\"href\" class=\"test_data action-flex-auto\" data-test-data-type=\"undefined\">${aiTestCaseData.url}</span>`,
        testData: aiTestCaseData.url,
        position: 0,
        type: "ACTION_TEXT"
      });
    }

    // Add description step if description exists
    if (aiTestCaseData.description) {
      const position = testCaseSteps.length; // Dynamic position based on whether URL step exists
      testCaseSteps.push({
        action: `AI Agent <span data-key=\"element\" data-event-key=\"ai_task\" class=\"element action-flex-auto\">${aiTestCaseData.description}</span>`,
        actionName: "ai_text_actions",
        event: {
          customEvent: "ai_text_actions",
          ai_task: aiTestCaseData.description,
          description: aiTestCaseData.description,
          type: "create_step",
          enhanced_task: this.enhancedData || null
        },
        naturalTextActionId: 568,
        type: "ACTION_TEXT",
        disabled: false,
        ignoreStepResult: false,
        visualEnabled: false,
        position: position
      });
    }

    return {
      "isExtensionUsed": false,
      "name": formatDate(new Date(), 'yyyy-MM-dd hh:mm', 'en-US').toString() + "_test_case",
      "description": null,
      "status": TestCaseStatus.READY,
      "sendMailNotification": false,
      "isStepGroup": false,
      "priorityId": 1,
      "type": 1,
      "preRequisite": aiTestCaseData.preRequisite.id,
      "isDataDriven": false,
      "workspaceVersionId": this.versionId,
      "testSuiteId": this.testSuiteId,
      "deleted": false,
      "testDataStartIndex": 0,
      "tags": [],
      "steps": testCaseSteps,
    };
  }

  async onSubmitAITestcase() {
    if (this.aiTestcaseForm.valid) {
      this.isLoading = true;
      const urlControl = this.aiTestcaseForm.get('url');
      const prerequisiteControl = this.aiTestcaseForm.get('preRequisite');
      const knowledgeBaseControl = this.aiTestcaseForm.get('knowledgeBase');
      const personaControl = this.aiTestcaseForm.get('persona');

      // Allow specific URLs for cqa domains
      const allowedUrls = [
        'opensource-demo.orangehrmlive.com',
        'crm-demo.contextqa.com',
        'magenta-flan-a983d0.netlify.app'
      ];

      const fullUrl = (!prerequisiteControl.value || prerequisiteControl.value === 'none') ?
        (urlControl.value.url) : null;

      // Check if URL is allowed for cqa domains
      console.log("this.lockData ::", this.lockData)
      if (this.lockData?.type == 'FREE') {
        const urlObj = new URL(fullUrl);
        const isAllowedUrl = allowedUrls.some(domain => urlObj.hostname.includes(domain));

        if (!isAllowedUrl) {
          const dialogRef = this.dialog.open(UrlWarningDialogComponent, {
            width: '400px',
            data: {
              title: 'URL Not Allowed',
              message: 'This URL is not allowed. Please use one of the demo URLs provided:',
              urls: [
                'http://crm-demo.contextqa.com/login',
                'https://magenta-flan-a983d0.netlify.app/login/'
              ]
            }
          });

          this.isLoading = false;
          return;
        }
      }

      let aiTestCaseData = {
        "url": fullUrl,
        "description": this.aiTestcaseForm.get('description').value,
        "preRequisite": prerequisiteControl.value,
        "knowledgeBase": knowledgeBaseControl.value,
        "persona": personaControl.value
      }

      let testCase = await this.createTestCaseCreateData(aiTestCaseData);
      testCase.testSuiteId = this.testSuiteId;

      this.testCaseService.autocreate(testCase).subscribe(
        (testcase) => {
          if (testcase.id) {
            this.testCaseId = testcase.id;
            this.fetchSteps();
            this.router.navigate(['/td', 'cases', testcase.id, 'steps']);
            this.openModalInstant();
            if (!this.IsExecuteBtn) {
              this.IsExecuteBtn = true;

              let parameters = [];
              if (aiTestCaseData.knowledgeBase && aiTestCaseData.knowledgeBase !== 'none') {
                parameters.push(`knowledgeId=${aiTestCaseData.knowledgeBase}`);
              }

              // Only add persona parameter if it's not 'defalut'
              if (aiTestCaseData.persona && aiTestCaseData.persona !== 'default') {
                parameters.push(`personaId=${aiTestCaseData.persona}`);
              }

              const parameter = parameters.join('&');

              this.testStepService.showexecute(testcase.id, parameter).subscribe(
                (step) => {
                  this.stespDetail = step;
                  this.mixpanelService.track('Test Case Execution Started', {
                    "id": testcase.id,
                    "title": testcase.name
                  });
                  this.IsExecuteBtn = false;

                  this.showNotification(NotificationType.Success, "Test case execution initiated successfully");
                  this.topicId = step?.topic;
                  this.testRunId = step?.data[0].id;
                  this.startWebSocket(step?.topic);
                },
                (error: HttpErrorResponse) => {
                  this.IsExecuteBtn = false;
                  if (error.status == 400 && error.error?.error) {
                    this.showAPIError(error, error.error.error);
                  } else {
                    this.showAPIError(error, "execution.initiate.failure");
                  }
                  this.mixpanelService.track('Test Case Execution Failed', {
                    "id": testcase.id,
                    "title": testcase.name
                  });
                  this.executionDialog.close();
                }
              );
            }
            this.closeDialog();
            this.isLoading = false;
          }
        },
        error => {
          console.error('Error creating test case:', error);
          this.isLoading = false;
        }
      );
    }
  }

  selectOption(option: 'prebuilt' | 'AITestcase' | 'uploadDocument' | 'qa' | 'manuall') {
    this.selectedOption = option;
    // Add your logic here for handling the selection
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    if (this.autoComplete && !this.autoComplete.elementRef.nativeElement.contains(event.target)) {
      if (this.autoComplete.hideDropdown) {
        this.autoComplete.hideDropdown();
      }
      this.isDropdownOpen = false;
    }
  }

  // Add method to handle dropdown opening
  onDropdownOpen() {
    this.isDropdownOpen = true;
  }

  // Add method to handle dropdown closing
  onDropdownClose() {
    this.isDropdownOpen = false;
  }

  @ViewChild('autoComplete') set autoCompleteElement(element: any) {
    if (element) {
      this.autoComplete = element;
      // Add click listener to close dropdown when clicking outside
      document.addEventListener('click', (event: MouseEvent) => {
        if (!element.elementRef.nativeElement.contains(event.target)) {
          this.ngZone.run(() => {
            this.isDropdownOpen = false;
          });
        }
      });
    }
  }

  onBackClick(): void {
    // Store current form values before going back
    const currentValues = {
      url: this.urlForm.get('url')?.value,
      // ... store other form values if any
    };

    // Store in session/local storage if needed
    sessionStorage.setItem('formValues', JSON.stringify(currentValues));

    // Keep validation state
    if (this.urlForm.valid) {
      this.urlForm.markAsPristine(); // Prevents "unsaved changes" warnings if any
      this.urlForm.markAsTouched(); // Keeps validation visual state
    }

    // Navigate back while preserving form state
    this.router.navigate(['/td', 'cases']);
  }

  // Add this new method to handle going back
  goBack() {
    // Reset AI testcase form
    this.aiTestcaseForm.reset();
    this.aiTestcaseForm.patchValue({
      preRequisite: null,
      url: '',
      description: ''
    });

    // Reset URL form
    this.urlForm.reset();
    this.urlForm.patchValue({
      url: '',
      preRequisite: null,

    });

    // Reset selected option
    this.selectedOption = null;

    // Clear any validation errors
    Object.keys(this.aiTestcaseForm.controls).forEach(key => {
      const control = this.aiTestcaseForm.get(key);
      control.setErrors(null);
      control.updateValueAndValidity();
    });

    Object.keys(this.urlForm.controls).forEach(key => {
      const control = this.urlForm.get(key);
      control.setErrors(null);
      control.updateValueAndValidity();
    });
    this.isInputFocused = false;
  }
  async checkExtension() {
    this.isExtensionInstalled =
      await this.ChromeExtensionService.isExtensionInstalled(this.extensionId);
    if (this.isExtensionInstalled) {
      this.checkExtensionInstall = false;
    } else {
      this.checkExtensionInstall = true;
    }
  }
  // Add public getter
  public get getExtensionId(): string {
    return this.extensionId;
  }

  dismissWarning() {
    this.showWarning = false;
  }

  onAIOptionSelected(event: MatAutocompleteSelectedEvent) {
    // Handle AI form option selection
    const selectedOption = event.option.value;
    this.aiTestcaseForm.patchValue({
      url: selectedOption
    });
  }

  private _filters(value: string): any[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.predefinedUrls.filter(option =>
        option.url.toLowerCase().includes(filterValue));
    }
    return this.predefinedUrls;
  }

  onAIUrlBlur(event: any) {
    this.isAIInputFocused = false;
    const urlControl = this.aiTestcaseForm.get('url');
    if (urlControl) {
      let urlValue = urlControl.value;

      // If it's a string (direct input) rather than an object from autocomplete
      if (typeof urlValue === 'string') {
        // Trim whitespace
        urlValue = urlValue.trim();

        if (urlValue) {
          // Add https:// if no protocol is specified
          if (!urlValue.match(/^https?:\/\//i)) {
            urlValue = 'https://' + urlValue;
          }

          // Update the form control value
          urlControl.setValue({
            url: urlValue
          });
        } else {
          // Clear the control if empty string
          urlControl.setValue(null);
        }
      }
    }
  }

  private urlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Return error if control value is empty or just whitespace
      if (!control.value || (typeof control.value === 'string' && !control.value.trim())) {
        return { required: true };
      }

      try {
        // Handle both object format and direct string input
        const urlString = control.value.url || control.value;

        // Check for empty string or just whitespace
        if (!urlString || (typeof urlString === 'string' && !urlString.trim())) {
          return { required: true };
        }

        // Check if URL starts with http:// or https://
        if (typeof urlString === 'string' && !urlString.match(/^https?:\/\//i)) {
          return { protocolRequired: true };
        }

        // Try to construct URL to validate format
        new URL(urlString);
        return null;
      } catch (e) {
        return { invalidUrl: true };
      }
    };
  }
  patchValue(data: any): void {
    this.aiTestcaseForm.patchValue({
      url: { url: data.title },
      description: data.description
    });
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      if (this.isRequirementsMode) {
        // Handle CSV files for requirements mode
        if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
          this.selectedFileName = file.name;
          this.documentForm.patchValue({
            document: file
          });
        } else {
          this.selectedFileName = null;
          this.documentForm.patchValue({
            document: null
          });
          alert('Please upload only CSV files for requirements mode');
        }
      } else {
        // Handle Excel files for document mode
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.type === 'application/vnd.ms-excel') {
          this.selectedFileName = file.name;
          this.documentForm.patchValue({
            document: file
          });
        } else {
          this.selectedFileName = null;
          this.documentForm.patchValue({
            document: null
          });
          alert('Please upload only Excel files (.xlsx or .xls)');
        }
      }
    }
  }

  showNotification(type: NotificationType, message, clickToClose?) {
    const temp = {
      type: type,
      title: status,
      content: message,
      timeOut: 7000,
      positionClass: 'toast-bottom-left',
      progressBar: true,
      progressAnimation: <ProgressAnimationType>'increasing',
      enableHtml: true
    };
    if (this.toastrService) {
      this.toastrService.show(temp.content, temp.title, temp, temp.type);
    }
  }

  onToggleChange(event: any) {
    this.isRequirementsMode = event.checked;
    // Reset the form when toggling modes
    this.documentForm.reset();
    this.selectedFileName = '';
  }

  // Add new method for mode selection
  selectMode(isRequirements: boolean) {
    this.isRequirementsMode = isRequirements;
    // Reset the form when changing modes
    this.documentForm.reset();
    this.selectedFileName = '';
  }

  onDocumentSubmit() {
    if (this.documentForm.valid) {
      this.isLoading = true;
      const file = this.documentForm.get('document')?.value;

      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('token', localStorage.getItem('_t') || '');
      formData.append('origin', this.origin === 'localhost' ? 'dtest01' : this.origin);

      if (this.isRequirementsMode) {
        // Call requirements API
        this.testCaseService.uploadRequirements(formData).subscribe(
          (res) => {
            console.log("Requirements upload response:", res);
            this.isLoading = false;
            this.closeDialog();
            debugger;
            this.showNotification(NotificationType.Success, res.message);
          },
          (error) => {
            console.error('Error uploading requirements:', error);
            this.isLoading = false;
            this.showNotification(NotificationType.Error, 'Failed to upload requirements. Please try again.');
          }
        );
      } else {
        // Existing document upload logic
        this.testCaseService.documentUpload(formData).subscribe(
          (res) => {
            console.log("res ::", res);
            this.isLoading = false;
            this.closeDialog();
            this.showNotification(NotificationType.Success, 'Document uploaded successfully');
          },
          (error) => {
            console.error('Error uploading document:', error);
            this.isLoading = false;
            this.showNotification(NotificationType.Error, 'Failed to upload document. Please try again.');
          }
        );
      }
    }
  }

  // Update the template items binding to use content array
  getTestCaseItems(): any[] {
    return this.testCaseList?.content || [];
  }

  toggleAdvancedSettings() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }

  loadCustomAgents() {
    this.customAgentService.getCustomAgents().subscribe({
      next: (response) => {
        this.customAgents = response;
        console.log("customAgents ::", this.customAgents)
      },
      error: (error) => {
        console.error('Error loading custom agents:', error);
      }
    });
  }

  loadPrompts() {
    this.aiPromptsService.getPrompts().subscribe({
      next: (response) => {
        this.prompts = response.response.prompts.map(prompt => ({
          id: prompt.id,
          title: prompt.title,
          flag: true,
          value: prompt.id,
          prompt: prompt.prompt,
          updated_at: prompt.updated_at
        }));
      },
      error: (error) => {
        console.error('Error loading prompts:', error);
      }
    });
  }

  public fetchSteps() {
    let query = "testCaseId:" + this.testCaseId;
    this.testStepService.findAll(query, "position").subscribe((res) => {
      this.testSteps = res;
      // this.testSteps.content = this.testSteps?.content.filter(step => step.type != TestStepType.WHILE_LOOP)
      if (this.testSteps.content.length > 0) {
        this.testSteps.content = [...res.content];
        this.testSteps.content[0].setStepDisplayNumber(this.testSteps.content);
      }
    });
  }

  /** Get Socket Data */
  //  private logMessages: string[] = [];
  getSocketData(data) {
    console.log("getSocketData ::", data)
    this.executionDialog.componentInstance.loader = false;
    if (data.data.stepStatus == "SUCCESS" && data.data.status != "START") {
      if (data.data.id) {
        this.successSteps++;
      }
    } else if (data.data.stepStatus == "FAILURE") {
      if (data.data.id) {
        this.failSteps++;
      }
    } else if (data.data.stepStatus == "CONVERSATION" || data.data.stepStatus == "LOCATOR_NOT_DETECT" || data.data.stepStatus == "TIMEOUT") {
      this.executionDialog.componentInstance.Conversation = data.data;
    }
    let stepData = this.testSteps.content.find(
      (step) => step.id == data.data.id
    );
    if (stepData) {
      stepData["executionData"] = data.data;
      this.ExecutedStepsData.push(stepData);
    }
    this.stepsData.push(data.data);
    this.testSteps.content = this.testSteps.content.map((step) => {
      if (step.id == data.data.id) {
        return {
          ...step,
          ...{ executionData: data.data },
        };
      } else {
        return step;
      }
    });
    // if(data.data.status != "COMPLETED"){
    if (data.data.imageUrl) {
      this.lastValidImageUrl = data.data.imageUrl;
    }
    data.data.imageUrl = data.data.imageUrl || this.lastValidImageUrl;
    this.executionDialog.componentInstance.Prop = data.data;
    // }
    this.executionDialog.componentInstance.ExecutedStepsData =
      this.ExecutedStepsData;
    this.executionDialog.componentInstance.TestSteps = this.testSteps.content;
    this.executionDialog.componentInstance.failSteps = this.failSteps;
    this.executionDialog.componentInstance.successSteps = this.successSteps;
    this.executionDialog.componentInstance.stepsData = this.stepsData;
    this.executionDialog.componentInstance.TestCase = this.testCase;
    this.executionDialog.componentInstance.testRunId = this.testRunId;
    if (data.data.status == "COMPLETED") {
      this.socket.removeListener(this.topicId);
      this.lastValidImageUrl = null;
      // this.executionDialog.close();
      this.reInitialData();
    }
  }

  reInitialData() {
    this.seconds = 0;
    this.minutes = 0;
    this.failSteps = 0;
    this.successSteps = 0;
    this.stepsData = [];
    clearInterval(this.interval);
    this.ExecutedStepsData = [];
  }

  /** Open Modal When clock on Run */
  openModalInstant() {
    let config = new MatDialogConfig();
    config = {
      width: "100vw",
      maxWidth: "100%",
      height: "100%",
      panelClass: "full-screen-modal",
      hasBackdrop: false,
    };
    this.executionDialog = this.dialog.open(ExecutionModelComponent, config);
    this.executionDialog.componentInstance.loader = true;
    this.fetchSteps();
    this.logMessages = [];
    this.rerunSubscription = this.testStepService.onRerun.subscribe(() => {
      this.socket.removeListener(this.topicId);
      this.reInitialData();
      this.fetchSteps();
      setTimeout(() => {
        this.OnRun();
      }, 500);
    });
  }

  stopWatch() {
    this.seconds++;
    if (this.seconds / 60 === 1) {
      this.seconds = 0;
      this.minutes++;
    }
  }
  /** Open Execution Model */
  startWebSocket(topicId: any) {
    let messageLog: any = [];
    let networkLog: any = [];
    console.log("socket ::", this.socket)
    this.socket.on(topicId, (data: any) => {
      console.log("topicId ::", topicId)
      if (data.data.result && data.data.result.length && data.data.stepStatus == "FAILED") {
        data["type"] = "execution";
        data.data["stepStatus"] = "FAILURE"; // we will remove this near feautre
        this.sharedDataService.setTestCaseResult(data);
      }

      if (data.data.status == "START") {
        this.interval = setInterval(() => {
          this.stopWatch();
          let time =
            (this.minutes < 10 ? "0" + this.minutes : this.minutes) +
            ":" +
            (this.seconds < 10 ? "0" + this.seconds : this.seconds);
          this.executionDialog.componentInstance.time = time;
        }, 1000);
        this.executionDialog.afterClosed().subscribe((res) => {
          this.socket.removeListener(this.topicId);
          this.reInitialData();
          this.fetchSteps();
          if (this.rerunSubscription) {
            this.rerunSubscription.unsubscribe();
          }
        });
      }
      if (data.data.stepStatus == "WAITING_FOR_STOP") {
        this.executionDialog.componentInstance.stopExecution = data.data;
        return;
      }
      if (data.data?.messageLog) {
        data.data?.messageLog.forEach(item => messageLog.push({ ...item }));
        data.data.messageLog = messageLog;
      } else {
        data.data.messageLog = messageLog
      }
      if (data.data?.log) {
        this.logMessages.push(data.data?.log);
        data.data.log = this.logMessages;
      } else {
        data.data.log = this.logMessages;
      }
      if (data.data?.networkLog) {
        data.data?.networkLog.forEach(item => networkLog.push({ ...item }));
        data.data.networkLog = networkLog;
      } else {
        data.data.networkLog = networkLog
      }
      this.getSocketData(data);
    });
  }



  showAPIError(exception, internalErrorMSG, entityName?: string, parentEntity?: string) {
    if (exception['status'] == 401 && entityName) {
      this.showNotification(NotificationType.Error, "Invalid " + entityName);
      return;
    }

    if (exception['status'] == 422 || exception['status'] == 451) {
      let errorMessage = exception['error']['error'];
      if (errorMessage == "Entity with same name already exists, Please use different name" && Boolean(entityName)) {
        errorMessage = errorMessage.replace("Entity", entityName);
      } else if (errorMessage == "Entity has some relation please check it out" && Boolean(entityName)) {
        errorMessage = "Few " + entityName + " that you have selected are already in use and cannot be deleted. Please delete the ones which are in use from your " + parentEntity + " and try again ."
      }
      this.showNotification(NotificationType.Error, errorMessage);
    } else if (exception['status'] == 500)
      this.showNotification(NotificationType.Error, (exception?.error?.code ? this.translate.instant(exception?.error?.code) : internalErrorMSG) || internalErrorMSG);
    else if (exception?.error?.objectErrors?.length > 0)
      this.showNotification(NotificationType.Error, this.translate.instant('message.duplicate_entity'));
    else
      this.showNotification(NotificationType.Error, internalErrorMSG);
  }

  // private stripUrlProtocol(url: string): string {
  //   return url.replace(/^(https?:\/\/)/, '');
  // }

  // private ensureUrlProtocol(url: string): string {
  //   return url.startsWith('http') ? url : `https://${url}`;
  // }

  AIConversationalPrompt() {
    const description = this.aiTestcaseForm.get('description').value;
    const urlStep = this.aiTestcaseForm.get('url').value;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '800px';
    dialogConfig.height = '800px';
    dialogConfig.data = {
      label: 'Clear Description',
      customClass: 'description-dialog',
      description: description,
      urlStep: urlStep
    };

    // this.dialog.open(ConversionComponent, dialogConfig);
    const dialogRef = this.dialog.open(ConversionComponent, dialogConfig);

    // Subscribe to dialog close event
    dialogRef.afterClosed().subscribe(result => {
      console.log("result ::", result)
      this.enhancedData = result;
      console.log("enhancedData CLOSE::", this.enhancedData)
    });
  }

}
