<!-- <div class="latest-run-card ts-col-100">
  <h6 [translate]="'dashboard.latest_runs.title'"></h6>
  <div class="d-flex flex-wrap">
    <div class="ts-col-100">
      <cdk-virtual-scroll-viewport *ngIf="testPlanResults?.totalElements" itemSize="43" class="test-run-viewport">
        <div class="test-run-bg mb-10" [class.active]="result.id == activeExecutionResult?.id"
          *cdkVirtualFor="let result of testPlanResults">
          <div>
          <div class="bg-badge border-circle"
            [matTooltip]="(result.isRunning ? 'execution.result.running': 'execution.result.'+result.result) | translate">
            <div class="bg-color border-circle" [class.result-status-7]="result.isRunning"
              [class.result-status-0]="result.isPassed" [class.result-status-6]="result.isStopped"
              [class.result-status-2]="result.isAborted" [class.result-status-3]="result.isNotExecuted"
              [class.result-status-5]="result.isQueued" [class.result-status-1]="result.isFailed"></div>
          </div>
        </div>
          <div class="test-run-title">
            <a [routerLink]="['/td', 'testruns', result.id]">{{'R - ' + result.id}} </a> {{result.testPlan.name}}
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
      <app-placeholder-loader *ngIf="testPlanResults?.isFetching"></app-placeholder-loader>
    </div>
  </div>
</div> -->

<mat-tab-group class="latest-runs-tabs tab-group-row" animationDuration="0ms">
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="tab-label-container">
        <mat-icon class="tab-icon">history</mat-icon>
        <span class="tab-label">Latest Runs</span>
      </div>
    </ng-template>
    
    <div class="latest-run-card">
      <div class="d-flex flex-wrap">
        <div class="ts-col-100">
          <!-- Show empty state when no results -->
          <div *ngIf="testPlanResults?.isEmpty" class="empty-state">
            <mat-icon class="empty-icon">history</mat-icon>
            <p class="empty-text">No test runs available</p>
          </div>

          <cdk-virtual-scroll-viewport *ngIf="testPlanResults?.totalElements" 
            itemSize="56" class="test-run-viewport">
            <div class="test-run-item" [class.active]="result.id === activeExecutionResult?.id"
              *cdkVirtualFor="let result of testPlanResults">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center gap-3">
                  <div class="status-badge">
                    <div class="status-indicator" 
                      [matTooltip]="(result.isRunning ? 'execution.result.running': 'execution.result.'+result.result) | translate"
                      [class.running]="result?.isRunning"
                      [class.passed]="result?.isPassed"
                      [class.stopped]="result?.isStopped"
                      [class.aborted]="result?.isAborted"
                      [class.not-executed]="result?.isNotExecuted"
                      [class.queued]="result?.isQueued"
                      [class.failed]="result?.isFailed">
                    </div>
                  </div>
                  <div class="run-details">
                    <div class="d-flex align-items-center gap-2">
                      <a class="run-id" (click)="onClickTestResultData(result)">R-{{result.id}}</a>
                      <span class="run-name text-truncate">{{result.testCase.name}}</span>
                    </div>
                    <span class="run-time" *ngIf="result.startTime">
                      {{result.startTime | date:'MMM d, h:mm a'}}
                    </span>
                  </div>
                </div>
                <div class="run-actions">
                  <button mat-icon-button [matTooltip]="'View Details'"
                    (click)="onClickTestResultData(result)">
                    <mat-icon>chevron_right</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
          <app-placeholder-loader *ngIf="testPlanResults?.isFetching"></app-placeholder-loader>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab *ngIf="aiTestCaseSuggestions?.length > 0">
    <ng-template mat-tab-label>
      <div class="tab-label-container">
        <mat-icon class="tab-icon">smart_toy</mat-icon>
        <span class="tab-label">AI Generated</span>
      </div>
    </ng-template>
    
    <div class="ai-tests-content">
      <div *ngIf="!aiTestCaseSuggestions?.length" class="empty-state">
        <mat-icon class="empty-icon">science</mat-icon>
        <p class="empty-text">No AI generated test suggestions yet</p>
      </div>
      
      <div class="suggestion-list" *ngIf="aiTestCaseSuggestions?.length">
        <div class="select-all-header">
          <mat-checkbox 
            [(ngModel)]="selectAll"
            (change)="toggleSelectAll($event)">
            Select All
          </mat-checkbox>
          <button mat-raised-button 
            color="primary" 
            [disabled]="!hasSelectedSuggestions"
            (click)="approveSelected()">
            Approve
          </button>
        </div>
        <div class="suggestion-item" *ngFor="let suggestion of aiTestCaseSuggestions">
          <div class="suggestion-header">
            <div class="suggestion-header-left">
              <mat-checkbox 
                [(ngModel)]="suggestion.selected"
                (change)="onSuggestionSelect()">
              </mat-checkbox>
              <mat-icon (click)="previewTestCaseAISuggestion(suggestion)" class="suggestion-icon" style="color: #6366F1;">auto_awesome</mat-icon>
              <p (click)="previewTestCaseAISuggestion(suggestion)" class="suggestion-title">{{suggestion.data.name}}</p>
            </div>
            <!-- <div class="suggestion-actions">
              <button mat-icon-button [matTooltip]="'Preview'"  (click)="previewTestCaseAISuggestion(suggestion)">
                <mat-icon style="font-size: 16px;">visibility</mat-icon>
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

<!-- <div class="dashboard-section-title justify-content-center display-flex" style="margin-top: 10px; display: flex"
  [translate]="'dashboard.latest_runs.title'">
</div>
<div class="d-flex theme-border border-rds-5 h-100 flex-wrap" style="max-height: 290px !important;">
  <div class="ts-col-100 d-flex theme-border-t px-8 pb-8 overflow-hidden" style="height: calc(100% - 10px)">
    <div class="ts-col-90" [class.ts-col-100]="testPlanResults?.isEmpty">
      <app-placeholder-loader *ngIf="testPlanResults?.isFetching"></app-placeholder-loader>
      <cdk-virtual-scroll-viewport *ngIf="testPlanResults?.totalElements" itemSize="43"
        class="theme-section-container list-container virtual-scroll-viewport viewport-height"
        [class.ts-col-100]="testPlanResults?.isEmpty">
        <a class="list-view md-pm pointer green-highlight" (click)="setActiveResult(result)"
          [class.active]="result.id == activeExecutionResult?.id" *cdkVirtualFor='let result of testPlanResults'>
          <div class="ts-col-100 d-flex align-items-center">
            <span class="border-rds-12 d-inline-block p-4 mr-10 img-wh sm-wh"
              [matTooltip]="(result.isRunning ? 'execution.result.running': 'execution.result.'+result.result) | translate"
              [class.result-status-7]="result.isRunning" [class.result-status-0]="result.isPassed"
              [class.result-status-6]="result.isStopped" [class.result-status-2]="result.isAborted"
              [class.result-status-3]="result.isNotExecuted" [class.result-status-5]="result.isQueued"
              [class.result-status-1]="result.isFailed">&nbsp;</span>
            <span class="text-truncate" [textContent]="result.testPlan.name+' #'+result.id"></span>
          </div>
        </a>
        <app-placeholder-loader *ngIf="testPlanResults?.isFetching" [isChartWith]="true"></app-placeholder-loader>
      </cdk-virtual-scroll-viewport>

      <div *ngIf="testPlanResults?.isEmpty" class="empty-full-container-transparent-bg">
        <div class="empty-full-content">
          <div class="empty-run-xs"></div>
          <div class="text-t-secondary pt-30 pb-18" [translate]="'dashboard.latest_runs.'+period+'.empty'"></div>
        </div>
      </div>
    </div>
       <div class="ts-col-50" *ngIf="!testPlanResults?.isEmpty" >
         <div
           class="highChart ts-col-100 d-flex h-100 theme-gray-light border-rds-b-4"
           id="run-details-bar">
           <app-run-details-bar-chart
             class="ts-col-100 d-flex"
             [id]="'run-details-bar'"
             *ngIf="environmentResults"
             [results]="environmentResults"></app-run-details-bar-chart>
         </div>
       </div>
  </div>
</div> -->
