import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TestCaseDetailsKnowledgeBaseComponent } from './test-case-details-knowledge-base';

@NgModule({
  declarations: [TestCaseDetailsKnowledgeBaseComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatOptionModule,
    MatProgressSpinnerModule
  ],
  exports: [TestCaseDetailsKnowledgeBaseComponent]
})
export class TestCaseDetailsKnowledgeBaseModule { } 