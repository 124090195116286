<div
  class="h-100 result-summary-container">
  <div class="theme-overlay-container">
    <div class="theme-overlay-header" id="overlay-header">
      <span class="theme-overlay-title" [translate]="'runs.details.test_case_result'"></span>
      <button type="button"
              [matTooltip]="'hint.message.common.close' | translate"
              mat-icon-button
              mat-dialog-close
              class="close">
      </button>
    </div>

    <div
      *ngIf="testcaseResult"
      class="theme-overlay-content without-footer p-0">
      <div class="chart-section p-4">
        <div class="d-flex bg-white p-4 rounded shadow-sm">
          <div class="d-flex w-75 mx-auto">
            <div class="highChart m-auto">
              <app-result-donut-chart
                [width]="150" [height]="150" [resultEntity]="testcaseResult">
              </app-result-donut-chart>
            </div>
            <div class="legend-container">
              <ng-container *ngFor="let status of testStatuses">
                <div class="legend-item mb-3">
                  <i [class]="'fa-show legend-icon btn result-status-' + status.code"
                     [class.result-status-active]="status.code !== 0">
                  </i>
                  <span class="ml-2" [textContent]="(status.label | translate) + ' : ' + testcaseResult[status.countField]">
                  </span>
                </div>
              </ng-container>
              
              <div class="legend-item pt-3 mt-3 border-top">
                <strong [textContent]="('execution.result.test_step.total' | translate) + ' : ' + testcaseResult.totalCount">
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-4">
        <div class="details-grid">
          <div class="details-items">
            <label class="details-title font-weight-bold mb-2"
                   [translate]="'testcase.summary.test_case_priority'"></label>
            <div class="details-info" [textContent]="testcaseResult.testCasePriority?.displayName">
            </div>
          </div>
          <div class="details-items">
            <label class="details-title font-weight-bold mb-2"
                   [translate]="'testcase.summary.test_case_type'"></label>
            <div class="details-info" [textContent]="testcaseResult.testCaseType?.displayName">
            </div>
          </div>
          <div class="details-items">
            <label class="details-title font-weight-bold mb-2"
                   [translate]="'testcase.summary.data_driven'"></label>
            <div class="details-info"
                 [textContent]="(testcaseResult.parentId ? 'common.yes' : 'common.no') | translate"></div>
          </div>
          <div *ngIf="testcaseResult?.testDataId" class="details-items">
            <label class="details-title font-weight-bold mb-2"
                   [translate]="'testcase.summary.test_data_name'"></label>
            <div class="details-info">
              <a [routerLink]="['/td', 'data', testcaseResult?.testDataId]"
                 class="theme-text text-decoration-none"
                 [textContent]="testcaseResult?.testDataProfile?.name"></a>
            </div>
          </div>
          <div *ngIf="testcaseResult.testDataSetName" class="details-items">
            <label class="details-title font-weight-bold mb-2"
                   [translate]="'step_result.data_set_name'"></label>
            <div class="details-info"
                 [textContent]="testcaseResult.testDataSetName"></div>
          </div>
        </div>
        
        <div class="details-items mt-4">
          <label class="details-title font-weight-bold mb-2"
                 [translate]="'message.common.msg'"></label>
          <div class="details-info p-3 bg-light rounded"
               [innerHTML]="testcaseResult.message"></div>
        </div>
      </div>
    </div>
  </div>
</div>

