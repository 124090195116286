import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { WorkspaceType } from 'app/enums/workspace-type.enum';
import { WorkspaceVersion } from 'app/models/workspace-version.model';
import { TestCaseService } from 'app/services/test-case.service';
import { WorkspaceService } from 'app/services/workspace.service';
import { BaseComponent } from 'app/shared/components/base.component';
import { AuthenticationGuard } from 'app/shared/guards/authentication.guard';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ai-test-case-panel',
  template: `
    <div class="overlay">
      <div class="panel">
        <div class="panel-header">
          <h2>AI Test Case Generator</h2>
          <button (click)="close()" class="close-btn" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="panel-content">
          <p class="instructions">Provide a description of your feature, and our AI will generate test cases for you.</p>          
          <!-- Add warning message container -->
          <div *ngIf="warningMessage" class="warning-message">
          <i class="fa-warning text-danger"></i>
            {{ warningMessage }}
          </div>
          <app-ai-test-case-display
            *ngIf="testcaseDetails && version"
            [testcaseDetails]="testcaseDetails"
            [version]="version"
            [isFromAiChat]="true"
            [isTestPlanButton]="false">
          </app-ai-test-case-display>
          <div *ngIf="isLoading" class="loader-container">
            <div class="loader"></div>
            <p>Generating test cases...</p>
          </div>
        </div>
        <div *ngIf="isDropdownOpen">
              <div class="dropdown">
                <ul>
                  <li *ngFor="let item of optionList">
                  <div style="display: flex; height: 20px;" (click)="selectOption(item?.type)" >
                  <div style="width:80% ">{{ item?.label }}</div>
                  <div>
                  <img *ngIf="selectedOption === item?.type" src="../../../assets/images/green_true.png" style="width: 20px;">
                  </div>
                  </div>
                  </li>
                </ul>
              </div>
            </div>
          <div class="dropdown-button">
            <button class="dropdown-toggle" (click)="toggleDropdown()">
            <img src="../../../assets/images/small_ai.png" style="width: 20px;">
            </button>
          </div>
        <div class="panel-footer">
          <div class="input-area">
            <textarea
              placeholder="Describe your feature here..."
              [(ngModel)]="featureDescription"
              [disabled]="isLoading"
            ></textarea>
          </div>
          <button
            (click)="generateTestCases()"
            class="generate-btn"
            [disabled]="!featureDescription.trim() || isLoading"
          >
            <i class="fa fa-magic"></i> Generate Test Cases
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [`

   .dropdown {
      background: white;
    border: black;
    width: 40%;
    box-shadow: 0 7px 34px rgb(64 80 185 / 44%);
    border-radius: 4px;
    padding: 10px;
    border-radius: 15px 15px 15px 15px;
    margin: 0 15px 8px;
    }
    .dropdown-button{
    margin: 0 20px;
     background: white;
    }
    .dropdown-toggle {

    background: white;
    box-shadow: 0 7px 34px rgb(64 80 185 / 44%);
    border-radius: 15px 15px 15px 15px;
      color: #212529;
      border: none;
      cursor: pointer;
      border-radius: 20px;
    }
    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: white;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 10px;
      z-index: 1;
      min-width: 200px;
    }
    .dropdown-section {
      margin-bottom: 15px;
    }
    .section-header {
      font-weight: bold;
      margin-bottom: 8px;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    li {
      padding: 8px;
      cursor: pointer;
    }
    li:hover {
      background-color: #f0f0f0;
    }

    .overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: flex-end;
      z-index: 1000;
    }
    .panel {
      width: 500px;
      max-width: 80vw;
      height: 100%;
      background: white;
      display: flex;
      flex-direction: column;
      box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    }
    .panel-header {
      background: #3f51b5;
      color: white;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
    }
    .panel-header h2 {
      margin: 0;
      font-size: 1.5em;
    }
    .close-btn {
      background: none;
      border: none;
      color: white;
      font-size: 24px;
      cursor: pointer;
      transition: transform 0.2s;
    }
    .close-btn:hover {
      transform: scale(1.1);
    }
    .panel-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 20px;
      overflow-y: auto;
    }
    .instructions {
      margin-bottom: 20px;
      font-size: 1.1em;
      color: #555;
    }
    .input-area {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    textarea {
      margin-bottom: 20px;
      padding: 15px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 1em;
      resize: none;
      min-height: 100px;
    }
    .panel-footer {
      padding: 20px;
      border-top: 1px solid #eee;
    }
    .generate-btn {
      padding: 12px 24px;
      background: #4CAF50;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1em;
      transition: background-color 0.3s;
      float: right;
    }
    .generate-btn:hover:not(:disabled) {
      background: #45a049;
    }
    .generate-btn:disabled {
      background: #cccccc;
      cursor: not-allowed;
    }
    .generate-btn i {
      margin-right: 8px;
    }
    .loader-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .loader {
      border: 5px solid #f3f3f3;
      border-top: 5px solid #3498db;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 1s linear infinite;
    }
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
      .warning-message {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      margin: 10px auto;
      background-color: #fff3cd;
      border: 1px solid #ffeeba;
      border-radius: 4px;
      color: #856404;
      text-align: center;
      max-width: 90%;
    }

    .warning-message i {
      margin-right: 10px;
      font-size: 1.2em;
    }
  `]
})
export class AiTestCasePanelComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() closePanel = new EventEmitter<void>();
  version: any;
  selectedOption;
  optionList = [{ label: 'Document', type: 'documentation' }, { label: 'Knowledge Graph', type: 'default' }]; // Replace with actual options from your data source
  featureDescription: string = '';
  public testcaseDetails: any;
  isLoading: boolean = false;
  isDropdownOpen = false;
  warningMessage: string = '';
  private warningTimeout: any;

  constructor(private testCaseService: TestCaseService, private workspaceService: WorkspaceService,
    public notificationsService: NotificationsService,
    public authGuard: AuthenticationGuard,
    public toastrService: ToastrService,
    public translate: TranslateService,



  ) {
    super(authGuard, notificationsService, translate, toastrService);

  }

  ngOnInit() {
    this.workspaceService.findAll("isDemo:true,workspaceType:" + WorkspaceType.WebApplication).subscribe(res => {
      this.version = res.content[0];
    });
  }

  close() {
    if (this.warningTimeout) {
      clearTimeout(this.warningTimeout);
    }
    this.closePanel.emit();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  selectOption(item) {
    if (this.selectedOption == item) {
      this.selectedOption = null;
      return;
    }
    this.selectedOption = item;
  }
  previousConversations: any[] = [];
  generateTestCases() {
    if (this.featureDescription.trim()) {
      this.isLoading = true;
      this.warningMessage = '';
      this.testcaseDetails = null;
      const payload = {
        query: this.featureDescription,
        default: this.selectedOption,
        previous_conversation: this.previousConversations.length > 0 ? [...this.previousConversations] : undefined,
      };
      this.testCaseService.suggestTestcaseSingle(payload).subscribe(
        (result: any) => {
          console.log("result ::", result)
          if(result.success == true){
            result.data.testCase.steps = result.data.testCase.steps.map(step => {
              if (step.name && step.name.includes("${test-data}")) {
                const value = step.data[0];
                step.name = step.name.replace("${test-data}", value);
              }
              if (step.label && step.label.includes("${test-data}")) {
                const value = step.data[0];
                step.name = step.label.replace("${test-data}", value);
              }
  
              return step;
            });
            this.testcaseDetails = result.data.testCase;
            console.log(this.testcaseDetails)
            this.previousConversations.push({
              role: 'user',
              content: this.featureDescription,
            });
  
            // Add the API response to the conversation history
            this.previousConversations.push({
              role: 'system',
              content: result.data, // Add the API response (stringified for clarity)
            });
            this.featureDescription = ''; // Clear textarea
            this.isLoading = false;
            if (!result) {
              console.log(result)
            }
          }else{
            this.isLoading = false;
            this.setWarningMessage(result.message);
          }
        },

        error => {
          this.isLoading = false;
          if (error?.error) {
            this.setWarningMessage(error.error.message);
          } else {
            this.setWarningMessage('An error occurred while generating test cases. Please try again.');
          }
          // Here you might want to show an error message to the user
        }
      );
    }
  }

  setWarningMessage(message: string) {
    if (this.warningTimeout) {
      clearTimeout(this.warningTimeout);
    }
    
    this.warningMessage = message;
    
    this.warningTimeout = setTimeout(() => {
      this.warningMessage = '';
    }, 9000);
  }

  ngOnDestroy() {
    if (this.warningTimeout) {
      clearTimeout(this.warningTimeout);
    }
  }
}
