import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from "../../shared/components/base.component";
import { AuthenticationGuard } from "../../shared/guards/authentication.guard";
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TestCaseResultSummaryComponent } from "../webcomponents/test-case-result-summary.component";
import { TestCaseResult } from "../../models/test-case-result.model";
import { ActivatedRoute, Params, Router, RouterOutlet } from '@angular/router';
import { TestCaseResultService } from "../../services/test-case-result.service";
import { EnvironmentService } from "../../services/environment.service";
import { TestDeviceResultService } from "../../shared/services/test-device-result.service";
import { interval, of, Subscription } from 'rxjs';
import { ReportBugComponent } from "../webcomponents/report-bug.component";
import { WhyTestCaseFailedHelpComponent } from "../webcomponents/why-test-case-failed-help.component";
import { TestPlanResult } from "../../models/test-plan-result.model";
import { ResultConstant } from "../../enums/result-constant.enum";
import { StatusConstant } from "../../enums/status-constant.enum";
import { TestPlanResultService } from "../../services/test-plan-result.service";
import { TestCaseStatusFormComponent } from "../webcomponents/test-case-status-form.component";
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { TestStepResult } from "../../models/test-step-result.model";
import { TestCase } from "../../models/test-case.model";
import { TestCaseService } from "../../services/test-case.service";
import { TestSuiteResultService } from "../../services/test-suite-result.service";
import { DryTestPlanService } from "../../services/dry-test-plan.service";
import { Page } from "../../shared/models/page";
import { DryTestPlan } from "../../models/dry-test-plan.model";
import { TestDeviceService } from "../../services/test-device.service";
import { ToastrService } from "ngx-toastr";
import { TestPlanService } from "../../services/test-plan.service";
import { UserPreference } from "../../models/user-preference.model";
import { LeftNavComponent } from "../webcomponents/left-nav.component";
import {
  TestsigmaGitHubStarLoveComponent
} from "../../shared/components/webcomponents/testsigma-github-star-love.component";
import { UserPreferenceService } from "../../services/user-preference.service";
import { TestStepService } from 'app/services/test-step.service';
import { PopupOpenComponent } from "../manually-test-run-popup/popupOpen.component";
import { DomSanitizer } from "@angular/platform-browser";
import { ScreenShortOverlayComponent } from "../webcomponents/screen-short-overlay.component";
import { TestStepResultService } from 'app/services/test-step-result.service';
import { ElementService } from 'app/shared/services/element.service';
import { errorFixTypepopupComponent } from "../webcomponents/errorFix-Type-popup.component";
import { switchMap } from 'rxjs/operators';
import { LicenceSevices } from 'app/shared/services/license.service';

@Component({
  selector: 'app-test-case-result-details',
  templateUrl: './test-case-result-details.component.html',
  styles: [`.executed-count {
      display: flex;
    grid-gap: 8px;
    gap: 8px;
    background: #6466f1;
    border-radius: 16px;
    border-radius: 16px;
    cursor: pointer;
    padding-left: 4px;
    padding-right: 15px;
    height: 30px;
    margin-top: 3px;
    margin-right: 10px;
      img {
        width: 22px;
        height: 22px;
        position: relative;
        top: 3px;
      }

      span {
        font-size: 12px;
        font-weight: 500;
        font-family: "inter";
        line-height: 29px;
        color: white;
      }
    }`]
})
export class TestCaseResultDetailsComponent extends BaseComponent implements OnInit {

  public testCaseResultId: Number;
  public testCaseResult: TestCaseResult;
  testCaseResultUpdate: any
  public testCase: TestCase;
  public preReqDryTestCaseResult: TestCaseResult;
  public testStepDetailsOpen = false;
  @ViewChild('detailsRef') overlayDir: CdkConnectedOverlay;

  public activeTab: string;
  public autoRefreshSubscription: Subscription;
  public autoRefreshInterval: number = 10000;
  public isDisabledAutoRefresh: boolean = false;
  public isTestCaseFetchingCompleted: boolean = false;
  public isEditStep: boolean = false;
  public failTestStepResult: TestStepResult;
  public isFetching: boolean;
  public isCaseLevelExecution: boolean = false;
  public isParallelExecution: boolean = false;
  public startSync: boolean = false;
  public queueSizeErrorMessage: string;
  public hasSteps: boolean = true;
  public userPreference: UserPreference;
  traceViewerUrl: any;
  apiTraceViewerUrl: any;
  mobileTraceViewerUrl: any;
  traceViewer: any;
  selectedTestCase: any;
  lockData: any;

  @Input() is_Model: boolean = false;
  @Input() is_Dashboard_Model = false;
  @Input() selectedCaseId: any;
  @Input() selectedResultId: any;
  @Input() added_testCaseResultId: any;
  @Output() statusChange = new EventEmitter<any>();
  @ViewChild(RouterOutlet) outlet: RouterOutlet;
  isRoutedThroughRunManually: boolean = false;
  defaultValue = 'NOT_EXECUTED'
  test_steps_executed!: any[];
  testcaseIsFeatching: boolean = true;
  testCaseId;
  constructor(public authGuard: AuthenticationGuard,
    public notificationsService: NotificationsService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    public route: ActivatedRoute,
    public router: Router,
    public environmentService: EnvironmentService,
    public testCaseService: TestCaseService,
    public testSuiteResultService: TestSuiteResultService,
    public environmentResultService: TestDeviceResultService,
    public testCaseResultService: TestCaseResultService,
    public dryTestPlanService: DryTestPlanService,
    public matModal: MatDialog,
    public testPlanResultService: TestPlanResultService,
    public testPlanService: TestPlanService,
    public userPreferenceService: UserPreferenceService,
    public testDeviceService: TestDeviceService,
    private testStepService: TestStepService,
    private testStepResults: TestStepResultService,
    private testStepResultService: TestStepResultService,
    private sanitizer: DomSanitizer,
    private elementService: ElementService,
    public LicenceSevices: LicenceSevices,

  ) {
    super(authGuard, notificationsService, translate, toastrService);
  }

  get canShowVideoScreenShort(): Boolean {
    return !((this.isRest ||
      this.testCaseResult?.testDeviceResult?.isExecuting ||
      this.testCaseResult?.isFailed ||
      this.testCaseResult?.isNotExecuted ||
      this.testCaseResult?.isStopped && this.testCaseResult?.stoppedPercentage == 100));
  }

  get isRest(): Boolean {
    return this.testCaseResult?.testDeviceResult?.testPlanResult?.testPlan?.workspaceVersion?.workspace?.isRest;
  }

  get canShowWhyThisFailed(): Boolean {
    return (this.testCaseResult?.isFailed || this.testCaseResult?.isAborted || this.testCaseResult?.isNotExecuted) &&
      !(this.isRest);
  }

  ngOnInit() {
    this.LicenceSevices.lockData$.subscribe(data => {
      if (data) {
        this.lockData = data.featureSupport;
      }
    });

    this.activeTab = 'execution-details';
    if (!this.is_Model) {
      this.route.params.subscribe((params: Params) => {
        this.pushToParent(this.route, params);
        this.testCaseResultId = params.resultId;
        this.fetchTestCaseResult();
        this.attachAutoRefreshEvents();
        this.fetchTestStepData();
      });
    } else {
      if (this.is_Dashboard_Model) {
        this.testCaseResultId = this.added_testCaseResultId;
        this.fetchTestCaseResult();
        this.attachAutoRefreshEvents();
      } else {
        this.activeTab = 'steps';
        this.testCaseResultId = this.selectedResultId;
        this.testCaseService.show(Number(this.selectedCaseId)).subscribe(res => {
          this.testCase = res;
          this.fetchTestCaseResult();
          this.attachAutoRefreshEvents();
          this.fetchTestStepData();
        });
      }
    }

    // this.testStepService.runManuallyClicked$.subscribe((clicked) => {
    //   this.isRoutedThroughRunManually = clicked;
    //   if (clicked) {
    //     this.activeTab = 'steps';
    //   }
    // });

    this.elementService.message$.subscribe(value => {
      this.fetchTestCaseResult();
    });
  }
  
  async openDialog(testStepData) {
    const stepId = testStepData.type === 'TS_TIMEOUT' ? testStepData.timeoutStep : testStepData.failed_step_id;

    // First, retrieve the matching step data
    const dialogRef = await this.testStepService.show(stepId).pipe(
      switchMap(matchingStep => {
        // Once the matching step is retrieved, open the modal dialog with the necessary data
        return of(this.matModal.open(errorFixTypepopupComponent, {
          height: '30%',
          width: '30%',
          data: { testStepData, matchingStepData: matchingStep },
          panelClass: ['mat-dialog', 'rds-none']
        }).afterClosed());
      })
    ).toPromise();

    // Handle dialog close response
    dialogRef.subscribe(() => {
      this.showNotification(NotificationType.Success, 'Value updated successfully');
    });
  }
  executionBtn() {
    if (chrome && chrome.runtime) {
      let data = {
        type: "test_case",
        id: this.testCaseResultId,
        result: this.testCaseResult,
        action: "isPortalExecution",
        origin: window.location.hostname.split('.')[0],
        userEmail: localStorage.getItem('useremail'),
        jwt: localStorage.getItem('_t')
      }
      chrome.runtime.sendMessage('pgadpooodaipbhbchnojijmlkhpamadh', { message: "isPortalExecutionStart", data: data }, (data: any) => {
        console.log(data, "Extension is installed.");
        if (data) {
          console.log(data);
        } else {

        }
      });
    }
  }

  refreshBtn() {
    window.location.reload();
  }

  setTraceView(url): any {
    this.traceViewerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://trace.playwright.dev/?trace=${url}`);
  }
  fetchTestStepData() {
    this.isFetching = true;
    let query;
    query = query ? query + "," : "";
    query += "groupResultId:null,testCaseResultId:" + this.testCaseResultId;
    this.testStepResultService.findAll(query, "id,asc").subscribe(res => {
      if (res.content.length) {
        this.isFetching = false;
        let testStepIds = res.content.map((data: any) => data.stepId);
        this.fetchTestSteps(testStepIds, res.pageable, res.content);
      } else {
        this.test_steps_executed = [];
        this.isFetching = false;
      }
    })
  }

  fetchTestSteps(testStepIds, pageable, teststepResult) {
    let queryString = "id@" + testStepIds.join("#")
    this.testStepService.findAll(queryString, 'position', pageable).subscribe(res => {
      this.test_steps_executed = res.content.map((data: any) => {
        return {
          ...data,
          teststepResult: teststepResult.find((res) => res.stepId == data.id)
        };
      });
      // Set isFetching to false after the process completes
      this.isFetching = false;
    });
  }

  ngOnDestroy(): void {
    this.removeAutoRefresh();
  }
  setSelectedTestCase(data) {
    this.selectedTestCase = data;
  }

  attachAutoRefreshEvents() {
    document.addEventListener("visibilitychange", () => {
      document.hidden ? this.removeAutoRefresh() : this.addAutoRefresh(true);
    });
  }

  addAutoRefresh(listenerChangeTrue?: boolean) {
    if (listenerChangeTrue && this.testCaseResult?.isExecuting && !this.isDisabledAutoRefresh) {
      this.fetchTestCaseResult();
    }
    if (this.testCaseResult?.isExecuting && !this.isDisabledAutoRefresh) {
      this.removeAutoRefresh()
      this.autoRefreshSubscription = interval(this.autoRefreshInterval).subscribe(() => {
        this.fetchTestCaseResult();
      });
    }
  }


  // downloadFile(apiTraceViewerUrl): void {
  //   // Replace 'your-file-url' with the actual URL of your file
  //   const fileUrl = apiTraceViewerUrl;
  //   // Create a temporary anchor element
  //   const link = document.createElement('a');
  //   link.href = fileUrl;
  //   link.download = 'API Trace Viewer';
  //   // Append the anchor element to the document body
  //   document.body.appendChild(link);
  //   // Trigger a click on the anchor element to start the download
  //   link.click();
  //   // Remove the anchor element from the document
  //   document.body.removeChild(link);
  // }

  removeAutoRefresh() {
    if (Boolean(this.autoRefreshSubscription)) {
      this.autoRefreshSubscription.unsubscribe();
    }
  }

  toggleAutoRefresh(isDisabledAutoRefresh: boolean) {
    this.isDisabledAutoRefresh = isDisabledAutoRefresh;
    if (this.isDisabledAutoRefresh) {
      this.removeAutoRefresh();
    } else {
      this.addAutoRefresh();
    }
  }

  changeAutoRefreshTime(event: number) {
    this.autoRefreshInterval = event;
    this.addAutoRefresh()
  }

  fetchTestCaseResult() {
    this.testCaseResultService.show(this.testCaseResultId).subscribe(res => {
      if (res.checkIfChildRunExists())
        if (!this.is_Model) {
          this.router.navigate(['/td/test_case_results', res.lastRun.id]);
        }
      if (res.isDataDriven) {
        let parentId = res.checkIfChildRunExists() ? res.lastRun.id : res.id;
        this.testCaseResultService.findAll("parentId:" + parentId).subscribe(res => {
          if (res.content[0])

            this.navigate(res.content[0]);

        });
      } else {
        this.testCaseResult = res;
        if (this.testCaseResult?.isManualExecution) {
          this.activeTab = 'steps';
        }
        this.defaultValue = this.testCaseResult.result;
        this.traceViewerUrl = this.testCaseResult?.traceViewerUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(`https://trace.playwright.dev/?trace=${this.testCaseResult?.traceViewerUrl}`) : null;
        this.apiTraceViewerUrl = this.testCaseResult?.apiTraceViewerUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(`https://trace.playwright.dev/?trace=${this.testCaseResult?.apiTraceViewerUrl}`) : null;
        // this.apiTraceViewerUrl = this.testCaseResult?.apiTraceViewerUrl
        this.mobileTraceViewerUrl = this.testCaseResult?.executedResult?.ltReportUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(`${this.testCaseResult?.executedResult?.ltReportUrl}`) : null;
        if (this.testCaseResult?.traceViewers && this.testCaseResult?.traceViewers != null) {
          this.traceViewer = this.testCaseResult?.traceViewers.map(item => item ? this.sanitizer.bypassSecurityTrustResourceUrl(`https://trace.playwright.dev/?trace=${item}`) : null);
        }

        if (this.testCaseResult?.message == 'Your Current Plan Reached Max Allowed Queue Size. Please upgrade for higher limits.') {
          this.queueSizeErrorMessage = "Your current plan reached max allowed queue size. Please <a class= 'text-link text-decoration-none px-2' href='javascript:fcWidget.open()' style='text-decoration: none;'>" +
            "contact support</a> to upgrade for higher limits"
        }
        this.isTestCaseFetchingCompleted = true;
        this.handleAutoRefresh();
        this.fetchEnvironment();
      }
      if (this.testCaseResult?.testCaseId) {
        this.postTestCaseFetch();
      }
    })
    this.testCaseResultService.showWithoutSerilize(this.testCaseResultId).subscribe(res => {
      this.testCaseResultUpdate = res;
    })
  }



  fetchEnvironment() {
    if (this.testCaseResult?.testDeviceResult?.testPlanResult?.environmentId)
      this.environmentService.show(this.testCaseResult.testDeviceResult.testPlanResult.environmentId).subscribe(res => {
        this.testCaseResult.testDeviceResult.testPlanResult.environment = res;
      });
  }

  handleAutoRefresh() {
    if (!(this.testCaseResult?.isExecuting || this.testCaseResult?.parentResult?.isExecuting)) {
      this.removeAutoRefresh();
      if (this.userPreference) {
        this.testPlanResultService.findAll("createdDate>" + this.userPreference.createdDate + ",result:SUCCESS").subscribe(res => {
          // if (res.content.length > 0 && this.userPreference.clickedSkipForNow == 1 && !this.userPreference.showedGitHubStar)
          //   this.GithubStarPopup();
        })
      } else {
        this.userPreferenceService.show().subscribe(userPreference => {
          this.userPreference = userPreference;
          this.testPlanResultService.findAll("createdDate>" + this.userPreference.createdDate + ",result:SUCCESS").subscribe(res => {
            // if (res.content.length > 0 && this.userPreference.clickedSkipForNow == 1 && !this.userPreference.showedGitHubStar)
            //   this.GithubStarPopup();
          })
        }
        )
      }

    }
    this.addAutoRefresh();
  }

  GithubStarPopup() {
    let dialogRef = this.matModal.open(TestsigmaGitHubStarLoveComponent, {
      position: { top: '10vh', right: '35vw' },
      panelClass: ['mat-dialog', 'rds-none'],
      data: {
        showTwitter: false,
        userPreference: this.userPreference
      }
    });

  }

  openSummary() {
    //TODO revert that fix compile build error resolved
    //let testCaseResult = this.getTestCaseResult();
    if (this.testCaseResult.parentResult)
      this.testCaseResult.isDataDriven = true;
    this.matModal.open(TestCaseResultSummaryComponent, {
      height: '550px',
      width: '60%',
      data: { testcaseResult: this.testCaseResult },
      panelClass: ['mat-dialog', 'rds-none']
    })
  }

  openScreenShort() {
    this.matModal.open(ScreenShortOverlayComponent, {
      width: '100vw',
      height: '100vh',
      position: { top: '0', left: '0', right: '0', bottom: '0' },
      data: { screenShortUrl: this.testCaseResult?.executedResult?.failed_test_steps[0]?.stepScreenshotUrl },
      panelClass: ['mat-dialog', 'full-width', 'rds-none']
    })
  }

  navigate(testCaseResult: TestCaseResult) {
    if (!this.is_Model) {
      if (testCaseResult)
        this.router.navigate(['/td/test_case_results', testCaseResult.id]);
    }
  }


  reportBug() {
    this.matModal.open(ReportBugComponent, {
      width: '80%',
      height: '100vh',
      position: { top: '0', right: '0', bottom: '0' },
      data: { testCaseResult: this.testCaseResult },
      panelClass: ['mat-overlay']
    })
  }

  onSelectChange(event: any) {
    this.defaultValue = event;
    let teststepResultdata = {
      content: [this.testCaseResultUpdate]
    }
    const dialogRef = this.matModal.open(PopupOpenComponent, {
      width: '1000px',
      height: '540px',
      data: { selectedvalue: event, id: this.testCaseResult.id, resultData: this.testCaseResultUpdate, isTestCaseResult: true, testStepResult: teststepResultdata },
    });
    dialogRef.afterClosed().subscribe((data) => {
      this.fetchTestCaseResult();
      this.statusChange.emit();
    });
    // const url = window.location.href;
    // const parts = url.split('/');
    // const resultId = parts[parts.length - 1];
    // const selectedValue = event.value;
    // this.resultData.result = selectedValue;
    // this.testStepResultService.updateNonexecute(resultId,this.resultData).subscribe(res=>{
    // })

  }

  openResultFailedHelp() {
    this.matModal.open(WhyTestCaseFailedHelpComponent, {
      width: '40%',
      data: { testStepResult: this.failTestStepResult },
      panelClass: ['mat-dialog', 'rds-none']
    })
  }

  stopExecution(testPlanResult: TestPlanResult) {
    testPlanResult.result = ResultConstant.STOPPED;
    testPlanResult.status = StatusConstant.STATUS_COMPLETED;
    this.testPlanResultService.update(testPlanResult).subscribe(() => {
      this.translate.get("execution.stopped.success").subscribe((res: string) => {
        this.showNotification(NotificationType.Success, res);
        this.fetchTestCaseResult();
      })
    })
  }

  startExecution(execution: DryTestPlan) {
    this.startSync = true;
    let dryTestPlan = new DryTestPlan().deserialize(execution.serialize());
    dryTestPlan.testCaseId = this.testCaseResult.testCaseId;
    this.testCaseId = this.testCaseResult.testCaseId
    delete dryTestPlan.id;
    this.dryTestPlanService.create(dryTestPlan).subscribe((res: TestPlanResult) => {
      this.startSync = false;
      this.translate.get("execution.initiate.success").subscribe((message: string) => {
        this.showNotification(NotificationType.Success, message);
        this.testCaseResultService.findAll("testPlanResultId:" + res.id + ",iteration:null", "id,desc").subscribe((res: Page<TestCaseResult>) => {
          if (!this.is_Model) {
            this.router.navigate(['/td', 'test_case_results', res?.content[0]?.id]);
          }
        });
      })
    }, error => {
      this.startSync = false;
      this.showAPIError(error, this.translate.instant("execution.initiate.failure"))
    })
  }

  openUpdateStatus() {
    this.matModal.open(TestCaseStatusFormComponent, {
      width: '40%',
      height: '100vh',
      position: { top: '0', right: '0', bottom: '0' },
      data: { testcase: this.testCaseResult.testCase },
      panelClass: ['mat-overlay']
    })
  }

  setEditToggle(isEdit: any) {
    this.isEditStep = isEdit;
  }

  triggerPopup() {
    this.testStepDetailsOpen = true;
    setTimeout(() => {
      this.overlayDir.overlayRef.backdropClick().subscribe(res => {
        this.overlayDir.overlayRef.detach();
        this.testStepDetailsOpen = false;
      });
    }, 200);
  }

  navigateToStepResult(stepResult: TestStepResult) {
    if (this.outlet && this.outlet.isActivated) {
      this.outlet.detach();
      this.outlet.deactivate();
    }
    if (stepResult.isFailed || stepResult.isAborted)
      this.failTestStepResult = stepResult;
    if (!this.is_Model) {
      this.router.navigate(['step_results', stepResult.id], { relativeTo: this.route });
    }
  }
  //Used For Dry Run
  postTestCaseFetch() {
    this.fetchTestCase(this.testCaseResult?.testCaseId);
  }

  fetchTestCase(testCaseId) {
    this.testCaseService.show(testCaseId).subscribe(res => {
      this.testCase = res;
      if (res.preRequisite)
        this.fetchPreReqDryTestCaseResult(res.preRequisite);
    });
  }

  fetchPreReqDryTestCaseResult(preReqId) {
    this.testCaseResultService.findAll('testCaseId:' + preReqId +
      ',environmentResultId:' + this.testCaseResult.environmentResultId).subscribe(res => {
        this.preReqDryTestCaseResult = res.content[0];
      }, error => console.log(error));
  }

  preReqNavigate() {
    if (!this.is_Model) {
      this.router.navigate(['/td/test_case_results', this.preReqDryTestCaseResult.id], { relativeTo: this.route });
    }
  }

  getTestCaseResult() {
    if (this.testCaseResult?.parentResult) {
      return this.testCaseResult?.parentResult;
    } else {
      return this.testCaseResult;
    }
  }

  setFirstFailedStep(testStepResult: TestStepResult) {
    if ((testStepResult.isFailed || testStepResult.isAborted ||
      testStepResult.isNotExecuted)
      && !testStepResult.isStepGroup)
      this.failTestStepResult = testStepResult;
  }

  get isDry() {
    return !!this.testCaseResult?.testDeviceResult?.testPlanResult?.dryTestPlan;
  }

  setStepsCount(hasSteps: boolean) {
    this.hasSteps = hasSteps;
  }

  hasDetailsData(): boolean {
    // Add your condition to check if there is any data
    return !!this.testCaseResult?.executedResult?.failed_test_steps ||
      !!this.testCaseResult?.executedResult?.autoHealedSteps ||
      !!this.testCaseResult?.screenRecordings;
  }

  /** Create Test Case */
  createTestCase() {
    this.testCaseResultService.createTestCase(this.testCaseResultId).subscribe(res => {
      if (res.id) {
        this.showNotification(NotificationType.Success, 'Test case created successfully');
        this.router.navigate(['/td/cases/' + res.id + '/steps']);
        // const url = this.router.serializeUrl(
        //   this.router.createUrlTree(['/td/cases/' + res.id + '/steps']));

        //    window.open('#' + url, '_blank');
      }
    }, error => console.log(error));
  }

}
