import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

export type ChatbotTab = 'navigate' | 'testcase' | 'chat';

@Injectable({
    providedIn: 'root'
})
export class ChatbotUiStateService {
    private isOpenedSubject = new BehaviorSubject<boolean>(false);
    private activeTabSubject = new BehaviorSubject<ChatbotTab>('navigate');
    private testCaseSuggestionSubject = new BehaviorSubject<any>(null);
    private testCaseDetailsSubject = new BehaviorSubject<any>(null);

    isOpened$ = this.isOpenedSubject.asObservable();
    activeTab$ = this.activeTabSubject.asObservable();
    testCaseSuggestion$ = this.testCaseSuggestionSubject.asObservable();
    testCaseDetails$ = this.testCaseDetailsSubject.asObservable();

    constructor(private router: Router) {
        // Listen to route changes
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            // Keep chatbot open after navigation if it was opened from within
            if (this.isOpenedSubject.value) {
                this.setOpened(true);
            }
        });
    }

    setOpened(isOpened: boolean) {
        this.isOpenedSubject.next(isOpened);
    }

    setActiveTab(tab: ChatbotTab) {
        this.activeTabSubject.next(tab);
    }

    setTestCaseSuggestion(suggestion: any) {
        this.testCaseSuggestionSubject.next(suggestion);
    }

    setTestCaseDetails(details: any) {
        this.testCaseDetailsSubject.next(details);
    }
} 