<div class="latest-run-card-run-history" xmlns="http://www.w3.org/1999/html">
  <!-- <h6 [translate]="'dashboard.run_history.title'"></h6> -->
  <div class="">
    <div class="">
      <cdk-virtual-scroll-viewport
        itemSize="43"
        class="test-run-viewport-history"
      >
        <div
          class="test-run-bg mb-10"
          *cdkVirtualFor="
            let dryTestCaseResult of dryTestCaseResults;
            let index = index
          "
        >
          <!-- First Row -->
          <div class="d-flex align-items-center flex-wrap">
            <div class="first-section">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                "
              >
                <div
                  class="bg-badge border-circle"
                  [matTooltip]="
                    (dryTestCaseResult.isRunning
                      ? 'execution.result.running'
                      : 'execution.result.' + dryTestCaseResult.result
                    ) | translate
                  "
                >
                  <div
                    class="bg-color border-circle"
                    [class.result-status-7]="dryTestCaseResult.isRunning"
                    [class.result-status-0]="dryTestCaseResult.isPassed"
                    [class.result-status-6]="dryTestCaseResult.isStopped"
                    [class.result-status-2]="dryTestCaseResult.isAborted"
                    [class.result-status-3]="dryTestCaseResult.isNotExecuted"
                    [class.result-status-5]="dryTestCaseResult.isQueued"
                    [class.result-status-1]="dryTestCaseResult.isFailed"
                  ></div>
                </div>
                <div class="test-run-title">
                  <a
                    style="
                      cursor: pointer;
                      color: #6366f1;
                      text-decoration: none;
                      background-color: transparent;
                      border-bottom: 1px solid #6366f1;
                    "
                    (click)="runResultsHistory(dryTestCaseResult)"
                  >
                    #{{ dryTestCaseResults.totalElements - index }}
                  </a>
                  <!-- [routerLink]="['/td', 'test_case_results', dryTestCaseResult?.id]" -->
                </div>
              </div>
              <!-- [routerLink]="['/td', 'test_case_results', dryTestCaseResult?.id]" -->
            </div>
            <div class="second-section w-auto">
              <a *ngIf="dryTestCaseResult?.isManualExecution">Manual</a>
              <a
                class="theme-text"
                *ngIf="!dryTestCaseResult?.isManualExecution"
                >Automated</a
              >

              <span
                class="text-t-secondary"
                [textContent]="
                  dryTestCaseResult?.startTime | date : 'hh:mm:ss a MMM d'
                "
              ></span>
              <span>
                <app-duration-format
                  [duration]="dryTestCaseResult.duration"
                ></app-duration-format>
              </span>

              <!-- </div> -->
              <!-- Second Row -->
              <!-- <div class="text-t-secondary new-line" style="clear: both;font-weight: bold;" [textContent]="dryTestCaseResult?.startTime |
            date:'hh:mm:ss a MMM d'"></div>
              <div class="text-t-secondary">
              </div> -->
            </div>
            <ng-container 
            *ngIf="
              lastFailedStepIndex === index &&
              dryTestCaseResult.isFailed &&
              (cqaOrigin === 'localhost' ||
                cqaOrigin === 'dtest01' ||
                cqaOrigin === 'test02' ||
                cqaOrigin === 'lululemonand' ||
                cqaOrigin === 'gmdemo' ||
                cqaOrigin === 'naehas')">
              <span *ngIf="lockData?.runResults"
                (click)=" openDialogSuggestion(dryTestCaseResult, dryTestCaseResults.totalElements - index )"
                class="run_suggestion button">
                  <ng-container *ngIf="isLoading; else reviewText">
                    <mat-spinner diameter="20" class="spinner"></mat-spinner>
                  </ng-container>
                <ng-template #reviewText>Review</ng-template>
              </span>
              <ng-container *ngIf="!lockData?.runResults">
                <app-common-button label="Review" customClass="review-button"></app-common-button>
              </ng-container>
            </ng-container>
          </div>
          <!-- *ngIf="cqaOrigin == 'gmdemo' &&  dryTestCaseResult.testCaseId == '247'" -->

          <span
            *ngIf="
              dryTestCaseResult?.executedResult?.failed_test_steps?.length >
                0 &&
              (dryTestCaseResult.executedResult.failed_test_steps[0]?.type ===
                'TS_TIMEOUT' ||
                dryTestCaseResult.executedResult.failed_test_steps[0]?.type ===
                  'LOCATOR_ND')
            "
            class="quick_fix"
            (click)="
              openQuickFix(
                dryTestCaseResult.executedResult?.failed_test_steps[0]
              )
            "
          >
            Quick Fix</span
          >
        </div>
        <app-placeholder-loader
          *ngIf="dryTestCaseResults.isFetching"
        ></app-placeholder-loader>
        <div
          *ngIf="dryTestCaseResults?.isEmpty && !dryTestCaseResults?.isFetching"
          class="h-100"
        >
          <div class="empty-full-container">
            <div
              class="empty-text"
              [translate]="'runs.list_view.empty.list'"
            ></div>
            <div></div>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
    <app-placeholder-loader
      *ngIf="testPlanResults?.isFetching"
    ></app-placeholder-loader>
  </div>
</div>

<!-- <div class="dashboard-section-title justify-content-center display-flex" style="margin-top: 10px; display: flex"
  [translate]="'dashboard.latest_runs.title'">
</div>
<div class="d-flex theme-border border-rds-5 h-100 flex-wrap" style="max-height: 290px !important;">
  <div class="ts-col-100 d-flex theme-border-t px-8 pb-8 overflow-hidden" style="height: calc(100% - 10px)">
    <div class="ts-col-90" [class.ts-col-100]="testPlanResults?.isEmpty">
      <app-placeholder-loader *ngIf="testPlanResults?.isFetching"></app-placeholder-loader>
      <cdk-virtual-scroll-viewport *ngIf="testPlanResults?.totalElements" itemSize="43"
        class="theme-section-container list-container virtual-scroll-viewport viewport-height"
        [class.ts-col-100]="testPlanResults?.isEmpty">
        <a class="list-view md-pm pointer green-highlight" (click)="setActiveResult(result)"
          [class.active]="result.id == activeExecutionResult?.id" *cdkVirtualFor='let result of testPlanResults'>
          <div class="ts-col-100 d-flex align-items-center">
            <span class="border-rds-12 d-inline-block p-4 mr-10 img-wh sm-wh"
              [matTooltip]="(result.isRunning ? 'execution.result.running': 'execution.result.'+result.result) | translate"
              [class.result-status-7]="result.isRunning" [class.result-status-0]="result.isPassed"
              [class.result-status-6]="result.isStopped" [class.result-status-2]="result.isAborted"
              [class.result-status-3]="result.isNotExecuted" [class.result-status-5]="result.isQueued"
              [class.result-status-1]="result.isFailed">&nbsp;</span>
            <span class="text-truncate" [textContent]="result.testPlan.name+' #'+result.id"></span>
          </div>
        </a>
        <app-placeholder-loader *ngIf="testPlanResults?.isFetching" [isChartWith]="true"></app-placeholder-loader>
      </cdk-virtual-scroll-viewport>

      <div *ngIf="testPlanResults?.isEmpty" class="empty-full-container-transparent-bg">
        <div class="empty-full-content">
          <div class="empty-run-xs"></div>
          <div class="text-t-secondary pt-30 pb-18" [translate]="'dashboard.latest_runs.'+period+'.empty'"></div>
        </div>
      </div>
    </div>
       <div class="ts-col-50" *ngIf="!testPlanResults?.isEmpty" >
         <div
           class="highChart ts-col-100 d-flex h-100 theme-gray-light border-rds-b-4"
           id="run-details-bar">
           <app-run-details-bar-chart
             class="ts-col-100 d-flex"
             [id]="'run-details-bar'"
             *ngIf="environmentResults"
             [results]="environmentResults"></app-run-details-bar-chart>
         </div>
       </div>
  </div>
</div> -->
