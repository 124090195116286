import { Component, OnInit } from '@angular/core';
import { Page } from "../../shared/models/page";
import { Pageable } from "../../shared/models/pageable";
import { AuthenticationGuard } from "../../shared/guards/authentication.guard";
import { BaseComponent } from "../../shared/components/base.component";
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TestPlanService } from "../../services/test-plan.service";
import { TestPlan } from "../../models/test-plan.model";
import { TestDeviceService } from "../../services/test-device.service";
import { TestPlanType } from "../../enums/execution-type.enum";
import { ToastrService } from "ngx-toastr";
import { FilterFormComponent } from "../plans/filter-form.component";
import { MatDialog } from "@angular/material/dialog";
import { WorkspaceVersionService } from "../../shared/services/workspace-version.service";
import { WorkspaceVersion } from "../../models/workspace-version.model";
import { TestRunservice } from 'app/services/test-runs.services';
import { AddRunComponent } from './add-run/add-run.component';
import * as moment from 'moment';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  host: { 'class': 'page-content-container' },
  styles: []
})
export class TestrunListComponent extends BaseComponent implements OnInit {
  public testPlans: Page<TestPlan>;
  public currentPage: Pageable = new Pageable();
  public versionId: number;
  public searchQuery = "";
  public isFiltered: boolean;
  public fetchingCompleted: Boolean = false;
  public isSearchEnable: boolean;
  public sortByColumns = ["id", "isManual", "createdDate", "updatedDate", "lastRun"];
  public sortedBy: string = 'createdDate';
  public direction: string = ",desc";
  public query: string;

  constructor(
    public authGuard: AuthenticationGuard,
    public notificationsService: NotificationsService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    public route: ActivatedRoute,
    private testPlanService: TestPlanService,
    private testRunService: TestRunservice,
    private router: Router,
    public matModal: MatDialog,
    private testDeviceService: TestDeviceService) {
    super(authGuard, notificationsService, translate, toastrService);
  }

  getPercentage(count: any, total: any): number {
    return (count / total) * 100;
  }

  getStatusColor(status: string): string {
    if (status === 'Failed') {
      return '#f04b69';
    } else if (status === 'Stopped') {
      return '#d4d4d4';
    } else if (status === 'Execution Initiated successfully') {
      return '#2ab95f';
    } else if (status === 'Aborted') {
      return '#fba60d';
    } else if (status === 'Not Executed') {
      return '#ddb608';
    } else if (status === 'Queued') {
      return '#107eef';
    } else {
      return 'gray'; // Default color
    }
  }

  ngOnInit(): void {
    this.route.parent.parent.params.subscribe((params: Params) => {
      this.versionId = params.versionId;
      this.route.params.subscribe((params) => {
        const allParams = { ...params, ...{ versionId: this.versionId } };
        this.pushToParent(this.route, allParams);
        this.refreshListView(this.route.snapshot.queryParamMap?.['params']?.['q']);
        this.fetchRunPlans(this.route.snapshot.queryParamMap?.['params']?.['q']);
      });
      this.route.queryParams.subscribe((params) => {
        this.refreshListView(params?.['q']);
        this.fetchRunPlans(params?.['q']);
      });
    });

  }

  refreshListView(query) {
    this.query = query;
  }

  getCrossBrowser() {
    return TestPlanType.CROSS_BROWSER
  }

  durationAsString(ms, maxPrecission = 3) {
    const duration = moment.duration(ms)

    const items = []
    items.push({ timeUnit: 'd', value: Math.floor(duration.asDays()) })
    items.push({ timeUnit: 'h', value: duration.hours() })
    items.push({ timeUnit: 'm', value: duration.minutes() })
    items.push({ timeUnit: 's', value: duration.seconds() })

    const formattedItems = items.reduce((accumulator, { value, timeUnit }) => {
      if (accumulator.length >= maxPrecission || (accumulator.length === 0 && value === 0)) {
        return accumulator
      }

      accumulator.push(`${value}${timeUnit}`)
      return accumulator
    }, [])

    return formattedItems.length !== 0 ? formattedItems.join(' ') : '-'
  }

  fetchRunPlans(search?: string, pageable?: Pageable) {
    this.fetchingCompleted = false;
    let query = '' + (search ? search : '');
    this.testRunService.findAll(query, this.sortedBy + this.direction, pageable || this.currentPage)
      .subscribe(res => {
        this.testPlans = res;
        this.currentPage = res.pageable;
        if (this.testPlans?.content?.length)
          this.fetchExecutionEnvironments();
        else
          this.fetchingCompleted = true;
      });
  }

  sortBy(value, direction) {
    if (!(this.sortedBy != value || this.direction != direction))
      return;
    this.direction = direction;
    this.sortedBy = value;
    this.fetchRunPlans(this.searchQuery);
  }


  fetchExecutionEnvironments() {
    let pageable = new Pageable();
    pageable.pageSize = 200;
    let query = "testPlanId@" + this.testPlans.content.map((exe) => exe.id).join("#");
    this.testDeviceService.findAll(query, undefined, pageable).subscribe((environments) => {
      this.testPlans.content.forEach((exe) => {
        let filteredEnvs = environments.content.filter((exeEnv) => exeEnv.testPlanId === exe.id);
        if (filteredEnvs)
          exe.testDevices = filteredEnvs;
      });
      this.fetchingCompleted = true;
    })
  }


  search(term: string) {
    if (term) {
      this.isFiltered = true;
      this.searchQuery = ",name:*" + term + "*";
    } else {
      this.isFiltered = false;
      this.searchQuery = "";
    }
    this.fetchRunPlans(this.searchQuery)
  }
  openFilter() {

    let filterDialogRef = this.matModal.open(FilterFormComponent, {
      width: '25%',
      height: '100vh',
      position: { top: '0', right: '0', bottom: '0' },
      panelClass: ['mat-overlay'],
      data: { query: this.query }
    });
    filterDialogRef.componentInstance.filterEvent.subscribe(query => {
      if (query) {
        this.query = query;
        this.router.navigate(['/td', this.versionId, 'testruns'], { queryParams: { q: this.query } });
        this.fetchRunPlans(this.query);
      } else
        this.discard();
    });
  }

  addmodelOpen() {
    this.matModal.open(AddRunComponent, {
      width: '1000px',
      height: '570px',
      data: {}
    });

  }
  humanizedDate(date) {
    return moment.duration(moment().diff(date)).humanize() + ' ago';
  }
  discard() {
    this.query = undefined;
    this.router.navigate(['/td', this.versionId, 'plans']);
    this.fetchRunPlans(this.query);
  }
}
